import React from 'react';

import './RadioButtonProv.scss';

interface RadioButtonProvProps {
	onChange:(e: React.ChangeEvent<HTMLInputElement>) => void;
	value: string;
	radioName: string;
	checked: boolean;
	label: string
}
function  RadioButtonProv({onChange, value, radioName, checked, label}: RadioButtonProvProps) {
	return(
		<label className="filterBlock__provBlock">
			<input type="radio" value={value} name={radioName} checked={checked} onChange={onChange} className="filterBlock__provFilter" />
			<div className="filterBlock__provSquare">
				<p className="filterBlock__provNumber">{label}</p>
			</div>
		</label>
	);
}

export default RadioButtonProv;