import React, { useState, ForwardRefRenderFunction } from "react";
import "../styles/base/Card.css";

interface CardProps {
  width?: string;
  height?: string;
  children: React.ReactNode;
  blurColor?: string;
  onClick?: () => void;
}

const Card: ForwardRefRenderFunction<HTMLDivElement, CardProps> = (
  {
    width = "300px",
    height = "385px",
    children,
    blurColor = "rgba(48,118,234,0.2)",
    onClick,
  },
  ref
) => (
  <div
    className="explorecard card"
    style={{
      width: `${width}`,
      height: `${height}`,
      background: `radial-gradient(
              circle,
              rgba(255, 255, 255, 0.05) 0%,
              ${blurColor} 0%,
              rgba(255, 255, 255, 0.05) 70%
            )`,
    }}
    onClick={onClick}
    ref={ref}
  >
    {children}
  </div>
);
export default React.forwardRef(Card);
