import React, { useEffect } from "react";
import TextFilter from "./TextFilter/TextFilter";
import RadioButtonProvStart from "./RadioButtonProvStart/RadioButtonProvStart";
import ProvisionFilter from "./ProvisionFilter/ProvisionFilter";
import "./TopFilter.scss";
import { metaDataFilterProps } from "../Constructor";

interface TopFilterProps {
  filterText: string;
  filters: metaDataFilterProps;
  onSelectMenu: () => void;
  onSelectMenuClass: string;
  onFilterTextChange: (value: string) => void;
  onFiltersChange: (metaDataFilterProps: metaDataFilterProps) => void;
  metaDataFilters: string[];
}

const TopFilter = ({
  filterText,
  filters,
  onSelectMenu,
  onSelectMenuClass,
  onFilterTextChange,
  onFiltersChange: onFiltersChange,
  metaDataFilters,
}: TopFilterProps) => {
  const handleFilterTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onFilterTextChange(e.target.value);
  };

  const handleFiltersChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onFiltersChange({
      key: e.target.value,
      value: filterText,
    });
  };

  useEffect(() => {
    onFiltersChange({
      key: filters.key,
      value: filterText,
    });
  }, [filterText]);

  console.log("keyFilter:", filters.key);

  return (
    <div className="cardListWrap__filterBlock">
      <div
        onClick={onSelectMenu}
        className={`filterBlock__popup ${onSelectMenuClass}`}
      >
        <div className="hamburger">
        <span></span>
        <span></span>
        <span></span>
        </div>
      </div>
      <div className="filterBlock">
        <TextFilter
          value={filterText}
          onChange={handleFilterTextChange}
          onReset={handleFiltersChange}
          filters={filters}
        />
        {/* this below needs to pas the provisional filters to the Text filter {x} */}
        <ProvisionFilter
          filters={metaDataFilters}
          checked={filters.key}
          onChange={handleFiltersChange}
        />
      </div>
    </div>
  );
};

export default TopFilter;
