// import '../../styles/nav.css';
import { useEffect, useMemo, useState } from "react";
import Box from "@mui/material/Box";
import {
  DataGrid,
  GridColDef,
  GridToolbar,
  gridSortedRowIdsSelector,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
  useGridApiRef,
} from "@mui/x-data-grid";

import { mapColumnz } from "Helpers/DataGridHelper";

type DataTable<T extends unknown> = {
  data: T[];
  columns?: GridColDef<T>[];
  hideTable?: boolean;
  userTokens?: boolean;
  onUserTokensChange?: () => void;
  onHideTableChange?: () => void;
  onDataChange?: () => void;
};

const NFTDataGrid = <T extends unknown>({
  data,
  columns,
  hideTable,
  userTokens,
  onHideTableChange,
  onUserTokensChange,
  onDataChange,
}: DataTable<T>) => {
  //not working Data on render is null
  //method is run once
  const [defaultColumns, setDeafaultColumns] = useState<GridColDef<T>[]>(
    useMemo<GridColDef<T>[]>(
      () => mapColumnz(data != null ? data[0] : null),
      []
    )
  );

  const [dataTable, setDataTable] = useState<DataTable<T>>({
    data: data,
    columns: [],
  });

  const [hideGrid, setHideGrid] = useState<boolean>(hideTable);
  const [showMyTokens, setShowMyTokens] = useState<boolean>(userTokens);

  //override Export??
  const handleExportClick = () => {
    // const filteredData = apiRef.current.getRowModels();
    // console.log("filtered:", filteredData)
    // Do something with the filtered data
  };

  const CustomToolbar = () => {
    const toggleGridButton =
      hideTable != null ? (
        <>
          <p style={{ color: "cornflowerblue" }}>
            {hideGrid ? "Show Grid" : "Hide Grid"}
          </p>
          <label className="switch">
            <input
              type="checkbox"
              onClick={HandleScreen}
              onChange={() => setHideGrid(!hideGrid)}
              checked={hideGrid}
            />
            <span className="slider round"></span>
          </label>
        </>
      ) : null;

    const showUserTokensButton =
      hideTable != null ? (
        <>
          <p style={{ color: "cornflowerblue" }}>
            {showMyTokens ? "All Tokens" : "My Tokens"}
          </p>
          <label className="switch">
            <input
              type="checkbox"
              onClick={onUserTokensChange}
              onChange={() => setShowMyTokens(!showMyTokens)}
              checked={showMyTokens}
            />
            <span className="slider round"></span>
          </label>
        </>
      ) : null;

    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport />
        {toggleGridButton}
        {/* {showUserTokensButton} */}
      </GridToolbarContainer>
    );
  };

  const HandleScreen = () => {
    var muiBox = document.querySelector(".MuiBox-root");

    if (hideGrid) {
      //css stuff
      //toggle flag as appropriate
    } else {
    }

    if (muiBox) {
      // console.log("mui:", muiBox)
      muiBox.classList.toggle("hide");
    }
    setHideGrid(!!hideGrid);

    onHideTableChange();
  };

  useEffect(() => {
    // console.log("data:", data);
    // console.log("custom columns:", columns);

    if (data == null) {
      return;
    }

    if (columns == null) {
      //set default colums
      // columns = defaultColumns

      return; // for now
    }

    setDataTable({
      data: data,
      columns: columns,
    });
  }, [data]);

  useEffect(() => {
    onDataChange();
  }, [hideGrid, showMyTokens]);

  return (
    <div className="">
      {/* <!--SECTION Table--> */}
      {dataTable.data && dataTable.columns ? (
        <Box sx={{ height: 400, width: "100%" }}>
          <DataGrid<T>
            rows={dataTable.data}
            columns={dataTable.columns}
            slots={{ toolbar: CustomToolbar }}
            onStateChange={(state) => {
              if (onDataChange) {
                onDataChange();
              }
            }}
          />
        </Box>
      ) : null}
    </div>
  );
};

export default NFTDataGrid;
