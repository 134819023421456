import styled, { keyframes } from "styled-components"

export const Section = styled.section`
min-height: 100vh;
width: 100vw;
// background-color: ${props => props.theme.text};
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
position: relative;
overflow: hidden;

&>*:first-child{
  animation-duration: 20s;

  @media (max-width: 30em){
    animation-duration: 15s;

  }
}
&>*:nth-child(2){
  animation-duration: 20s;

  @media (max-width: 30em){
    animation-duration: 15s;

  }
}
&>*:nth-child(3){
  animation-duration: 20s;

  @media (max-width: 30em){
    animation-duration: 15s;

  }
}
&>*:last-child{
  animation-duration: 15s;
  @media (max-width: 30em){
    animation-duration: 10s;

  }
}
opacity: 0.25;
// z-index: 0.1;
visibility: visible;


`
export const LottoBody = styled.div`

`
export const Container = styled.span`
  display: flex;
  justify-content: center;
  height: 200px
  padding: 68%

  .tagcloud{
    width: 100px !important;
    height: 500px;

    background-color: aquamarine;
    border-radius: 50%;
  }

`