import React, { useState, useEffect } from "react";
import "./CardSelection.css"; // Import the CSS if it's in a separate file
import { SmartContractHelper } from "Helpers/BaseContractHelper";
import { NFT } from "types/NFT/BaseContract";
import { GameModeEnum } from "../GameHome";

interface ICardSelection {
  nft: NFT;
  className: string;
}

interface ICardSelectionProps {
  startGame: (nfts: NFT[]) => void;
  gameMode: GameModeEnum;
  cardsPerPage?: number;
  NFTs: NFT[];
  // setCards: React.Dispatch<React.SetStateAction<ExploreNFT[]>>;
}

interface Hero {
  name: string;
  consecutiveWins: number;
  winLossRatio: number;
  championships: number;
  pic: string;
  tokenId: number;
}

enum SelectedStatEnum {
  championships = "championships",
  consecutiveWins = "consecutiveWins",
  winLossRatio = "winLossRatio",
}

type SelectedStat = {
  stat: SelectedStatEnum;
  tokenId: number;
};

const parseNFT = (nft: NFT): Hero => {
  return {
    name: nft.ipfs.name,
    championships: nft.championships,
    consecutiveWins: nft.consecutiveWins,
    winLossRatio: nft.winLossRatio,
    pic: nft.ipfs.image,
    tokenId: nft.tokenId,
  };
};

const CardSelection = ({ startGame, gameMode, NFTs }: ICardSelectionProps) => {
  const cardsPerPage = 5;
  const [currentPage, setCurrentPage] = useState(0);
  // Calculate start and end indices of the slice
  const startIndex = currentPage * cardsPerPage;
  const endIndex = startIndex + cardsPerPage;

  const [deck, setDeck] = useState<ICardSelection[]>([]);
  const [gameCards, setGameCards] = useState<NFT[]>(NFTs);

  const [selected, setSelected] = useState<NFT[]>([gameCards[0]]);
  const [selectedStats, setSelectedStats] = useState<SelectedStat[]>([]);

  const [play, setPlay] = useState<boolean>(false);
  const [canStat, setCanStat] = useState<boolean>(false);

  const [cardsRequred, setCardsRequired] = useState<number>(0);

  const [selectedHeros, setSelectedHeros] = useState<Hero[]>([
    parseNFT(gameCards[0]),
  ]);

  const [currentHeroIndex, setCurrentHeroIndex] = useState(0);
  const currentHero = selectedHeros[currentHeroIndex];

  const navigateToSelectedHero = (nft: NFT) => {
    if (selected.find((x) => x.tokenId === nft.tokenId)) return;
    const index = gameCards.findIndex((item) => item.tokenId === nft.tokenId);
    if (index !== -1) {
      setCurrentHeroIndex(index);
    }
  };

  const HandleUserCards = async () => {
    const smartContract = new SmartContractHelper();

    const tokens: NFT[] = await smartContract.getMyTokens();
    setGameCards(tokens);
  };

  // const handleSelectedStat = (nft: NFT, stat: SelectedStatEnum) => {
  //   if (!canStat || !selected.includes(nft)) return;

  //   setSelectedStats((stats) => {
  //     const statIndex = stats.findIndex((item) => item.tokenId === nft.tokenId);

  //     if (statIndex !== -1) {
  //       // If the stat is already selected, remove it
  //       return [...stats.slice(0, statIndex), ...stats.slice(statIndex + 1)];
  //     } else {
  //       // If the stat is not selected, add it
  //       return stats.length >= cardsRequred
  //         ? [...stats.slice(1), { stat, tokenId: nft.tokenId }]
  //         : [...stats, { stat, tokenId: nft.tokenId }];
  //     }
  //   });
  // };

  const handleSelectedStat = (nft: NFT, stat: SelectedStatEnum) => {
    if (!canStat || !selected.includes(nft)) return;
    setSelectedStats((stats) => {
      const statIndex = stats.findIndex(
        (item) => item.tokenId === nft.tokenId
      );

      if (statIndex !== -1) {
        // If a stat for this NFT is already selected, replace it
        let newStats = [...stats];
        newStats[statIndex] = { stat, tokenId: nft.tokenId };
        return newStats;
      } else {
        // If a stat for this NFT is not selected and total stats is less than cardsRequired, add it
        return stats.length >= cardsRequred
          ? stats
          : [...stats, { stat, tokenId: nft.tokenId }];
      }
    });
};

  const handleSelected = (nft: NFT) => {
    setSelected((nfts) => {
      const updatedSelected = nfts.find((item) => item.tokenId === nft.tokenId)
        ? nfts.filter((item) => item.tokenId !== nft.tokenId)
        : nfts.length >= cardsRequred
        ? [...nfts.slice(1), nft]
        : [...nfts, nft];

      const updatedHeros = updatedSelected.map((selectedNFT) =>
        parseNFT(gameCards.find((nft) => nft.tokenId === selectedNFT.tokenId))
      );

      setSelectedHeros(updatedHeros);

      return updatedSelected;
    });

    navigateToSelectedHero(nft);
  };

  const isSelected = (nft: NFT) => {
    return selected.some((item) => item.tokenId === nft.tokenId);
  };

  const isSelectedStat = (nft: NFT, stat: SelectedStatEnum) => {
    // console.log("lets see selectedStats:", selectedStats);
    // console.log("current nft to check:", nft);
    // console.log("current stat:", stat);
    return selectedStats.some(
      (item) => item.tokenId === nft.tokenId && item.stat === stat
    );
  };

  const handleSpread = () => {
    setDeck(
      deck.map((_, index) => ({
        nft: _.nft,
        className: (_.className = `card card-selection ${
          isSelected(_.nft) ? "selected-mark" : null
        } ani${index}`),
      }))
    );
  };

  const handleNextHero = () => {
    if (currentHeroIndex < selectedHeros.length - 1) {
      setCurrentHeroIndex(currentHeroIndex + 1);
    }
  };

  const handlePrevHero = () => {
    if (currentHeroIndex > 0) {
      setCurrentHeroIndex(currentHeroIndex - 1);
    }
  };

  const handleNextPage = () => {
    if (endIndex < gameCards.length) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  useEffect(() => {
    if (gameCards == null || gameCards.length === 0) {
      HandleUserCards();
    }
  }, [gameCards]);

  useEffect(() => {
    switch (gameMode) {
      case GameModeEnum.CardWar:
        console.log("card war");
        setCardsRequired(1);
        break;
      case GameModeEnum.StatAttack:
        setCardsRequired(3);
        setCanStat(true);
        break;
      case GameModeEnum.PunchersChance:
        setCardsRequired(3);
        break;
      case GameModeEnum.Tournament:
        ///dosomethimng
        break;
    }
  }, [cardsRequred]);

  useEffect(() => {
    if (gameCards != null) {
      console.log("my cards:", gameCards);
      console.log("selected.length:", selected.length);
      console.log("requiredCards:", cardsRequred);
      //check selected is enough to commence Game
      if (selected.length === cardsRequred) {
        if (canStat && selectedStats.length === cardsRequred && play) {
          startGame(selected);
        } else if (!canStat && play) {
          startGame(selected);
        }
      }

      handleSpread();
    }
  }, [selected, selectedStats, play]);

  useEffect(() => {}, [deck, currentPage, currentHeroIndex]);

  return (
    <div className="grid" id="card-selection">
      <div id="info-container">
        {/* Heoros Paginator */}
        <div className="heros-pagi">
          <button onClick={handlePrevHero} disabled={currentHeroIndex === 0}>
            Previous Page
          </button>
          <button
            onClick={handleNextHero}
            disabled={currentHeroIndex === selectedHeros.length - 1}
          >
            Next Page
          </button>
        </div>

        <div className="container">
          {/* Display the container even when there are no selected heroes */}
          {selectedHeros.length === 0 && (
            <div className="player-selected">
              <div className="player-picture col-md-6"></div>
              <div className="col-md-6">
                <div className="player-info">
                  <h1 className="player-name">No Hero Selected</h1>
                </div>
              </div>
            </div>
          )}
          {/* Previous Heros paginator */}
          {/* <button
            className="heros-prev"
            onClick={handlePrevHero}
            disabled={currentHeroIndex === 0}
          >
            Previous Page
          </button> */}

          {currentHero && (
            <div className="player-selected">
              <div className="player-picture col-md-6">
                <img
                  src={currentHero.pic}
                  className="img-responsive aligncenter"
                  alt={currentHero.name}
                />
              </div>
              <div className="col-md-6">
                <div className="player-info">
                  <h1 className="player-name">{currentHero.name}</h1>
                  <span
                    onClick={() =>
                      handleSelectedStat(
                        gameCards.find(
                          (nft) => nft.tokenId === currentHero.tokenId
                        ),
                        SelectedStatEnum.consecutiveWins
                      )
                    }
                    className={`choose-stat ${
                      isSelectedStat(
                        gameCards.find(
                          (nft) => nft.tokenId === currentHero.tokenId
                        ),
                        SelectedStatEnum.consecutiveWins
                      )
                        ? "picked"
                        : ""
                    }`}
                    // className={`choose-stat ${isSelectedStat(gameCards.find((nft) => nft.tokenId === currentHero.tokenId), SelectedStatEnum.consecutiveWins) ? "picked" : ""}`}
                  >
                    C W: {currentHero.consecutiveWins}
                  </span>
                  <span
                    onClick={() =>
                      handleSelectedStat(
                        gameCards.find(
                          (nft) => nft.tokenId === currentHero.tokenId
                        ),
                        SelectedStatEnum.winLossRatio
                      )
                    }
                    className={`choose-stat ${
                      isSelectedStat(
                        gameCards.find(
                          (nft) => nft.tokenId === currentHero.tokenId
                        ),
                        SelectedStatEnum.winLossRatio
                      )
                        ? "picked"
                        : ""
                    }`}
                  >
                    W/L%: {currentHero.winLossRatio}
                  </span>
                  <span
                    onClick={() =>
                      handleSelectedStat(
                        gameCards.find(
                          (nft) => nft.tokenId === currentHero.tokenId
                        ),
                        SelectedStatEnum.championships
                      )
                    }
                    className={`choose-stat ${
                      isSelectedStat(
                        gameCards.find(
                          (nft) => nft.tokenId === currentHero.tokenId
                        ),
                        SelectedStatEnum.championships
                      )
                        ? "picked"
                        : ""
                    }`}
                  >
                    C: {currentHero.championships}
                  </span>
                </div>
              </div>
            </div>
          )}

          {/* Next Heros paginator */}
          {/* <button
            className="heros-next"
            onClick={handleNextHero}
            disabled={currentHeroIndex === selectedHeros.length - 1}
          >
            Next Page
          </button> */}
        </div>
        <div className="clearfix" />

        <div id="contenedor" className="col-md-6 col-md-offset-3">
          <div id="posts">
            {gameCards.slice(startIndex, endIndex).map((token, index) => (
              <div
                className={
                  isSelected(token)
                    ? "post col-md-2 selected-mark"
                    : "post col-md-2"
                }
                style={{
                  visibility: "inherit",
                  opacity: "1",
                  transform: "matrix(1, 0, 0, 1, 0, 0)",
                }}
                key={token.ipfs.name}
                onClick={() => {
                  handleSelected(token);
                }}
              >
                <div key={token.ipfs.name} className="enlace">
                  <img
                    key={token.ipfs.name}
                    height={"100%"}
                    width={"100%"}
                    src={token.ipfs.image}
                    alt={token.ipfs.name}
                  />
                </div>
              </div>
            ))}
          </div>

          {/* Paginator */}
          <div className="heros-pagi">
            <button onClick={handlePrevPage} disabled={currentPage === 0}>
              Previous Page
            </button>
            <button
              onClick={handleNextPage}
              disabled={endIndex >= gameCards.length}
            >
              Next Page
            </button>
            <button
              disabled={
                selected.length < cardsRequred ||
                (canStat &&
                selectedStats.length < cardsRequred)
              }
              onClick={() => setPlay(true)}
            >
              Play
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardSelection;
