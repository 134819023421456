import React, { useState } from "react";
import Card from "./Card/Card";

import "./CardWrap.scss";
import { metaDataFilterProps } from "../Constructor";
import { extractValueFromProduct } from "Helpers/GetNFTS";
import { MetaDataInfo, NFT } from "types/NFT/BaseContract";

interface CardWrapProps {
  filterText: string;
  inFaction: string;
  inBorder: string;
  inType: string;
  metaDataFilters: metaDataFilterProps;
  cardsInfo: NFT[]; // You may need to specify a proper type for this array
}

const CardWrap = (props: CardWrapProps) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [selectedCard, setSelectedCard] = React.useState<NFT|null>(
    null
  );

  const handleCardClick = (card: NFT) => {
    setSelectedCard(card);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedCard(null);
  };



  const items: JSX.Element[] = [];
  // const processCardInfoArray = (cardInfoArray: any[]): MetaDataInfo[] => {
  //   return cardInfoArray.map((card) => ({
  //     name: card.name,
  //     image: card.image,
  //     attributes: [
  //       {
  //         trait_type: "championships",
  //         value: Number(card.championships) || null,
  //       },
  //       {
  //         trait_type: "consecutiveWins",
  //         value: Number(card.consequtiveWins) || null,
  //       },
  //       {
  //         trait_type: "winLossRatio",
  //         value: Number(card.winLossRatio) || null,
  //       },
  //       { trait_type: "author", value: "Lawrence Lustig" },
  //       { trait_type: "tier", value: card.tier },
  //       { trait_type: "type", value: card.type },
  //       // ... add other attributes as needed
  //     ],
  //     description: `${card.name} _ ${card.type}`,
  //   }));
  // };

  // const processedData = processCardInfoArray(props.cardsInfo);

  // console.log("formatted:", JSON.stringify(processedData));

  // const nfts = props.cardsInfo.map((item) => {
  //   const { border, ...rest } = item;
  //   return rest;
  // });

  // console.log("cards:", JSON.stringify(nfts, null, 2));

  props.cardsInfo.forEach((product) => {
    //check we have a filter
    if (props.metaDataFilters.value && props.metaDataFilters.value !== "") {
      const productValue = extractValueFromProduct(
        product.ipfs,
        props.metaDataFilters.key
      );

      // console.log("productValue:", productValue);

      switch (typeof productValue) {
        case typeof "":
          console.log("key:", props.metaDataFilters);
          //try and parse VALUE to an INT
          if (!isNaN(parseFloat(props.metaDataFilters.value))) {
            console.log("were a 01");
            if (
              (extractValueFromProduct(
                product.ipfs,
                props.metaDataFilters.key
              ) as number) < parseFloat(props.metaDataFilters.value)
            ) {
              return;
            }
          } else if (
            //filter VALUE on strings
            // (
            //   extractValueFromProduct(
            //     product,
            //     props.metaDataFilters.key
            //   ) as string
            // ).indexOf(props.metaDataFilters.value) === -1
            !(
              extractValueFromProduct(
                product.ipfs,
                props.metaDataFilters.key
              ) as string
            ).toLocaleLowerCase().includes(props.metaDataFilters.value.toLowerCase())
          ) {
            return;
          }
          break;
        case typeof true:
          console.log("Metadata:", props.metaDataFilters);
          if (
            (extractValueFromProduct(
              product.ipfs,
              props.metaDataFilters.key
            ) as boolean) !== JSON.parse(props.metaDataFilters.value)
          ) {
            return;
          }
          break;
        case typeof 0:
          console.log("int:");
          if (
            extractValueFromProduct(product.ipfs, props.metaDataFilters.key) &&
            extractValueFromProduct(product.ipfs, props.metaDataFilters.key) as number < Number(props.metaDataFilters.value)
          ) {
            return;
          }
          break;
      }
    }
    items.push(
      <Card product={product} key={product.tokenId ?? product.ipfs.name} onClick={handleCardClick} />
    );
  });


  return (
    <div className="cardWrap clearfix">
      {items}
      {isModalOpen && selectedCard && (
        <div className="modal" onClick={handleCloseModal}>
          <div
            className="modal-content"
            onClick={(e) => e.stopPropagation()} // Prevent the event from bubbling up to the modal background
          >
            <span className="close" onClick={handleCloseModal}>
              &times;
            </span>
            <Card product={selectedCard} />
          </div>
        </div>
      )}
    </div>
  );
};

export default CardWrap;
