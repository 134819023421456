import React from "react";

import "./DeckItem.scss";
interface DeckCollection {
  bgName: string;
  iconName: string;
  collectionName: string;
  symbol: string;
}
const DeckCollection = ({
  bgName,
  iconName,
  collectionName,
  symbol,
}: DeckCollection) => {
  return (
    <div className={`deckSlot _${bgName}`}>
      {/* can keep this to relay what type of a collection this is */}
      {/*  playable || tradable || redeemable || etc*/}
      <div className={`ability _${iconName}`}></div>
      <div className="deckInfo">
        <h1 className="deckInfo__name">{collectionName}</h1>
        <p className="deckInfo__ability">{symbol}</p>
      </div>
    </div>
  );
};

export default DeckCollection;
