import { ethers } from "ethers";
import { useInterval } from "Helpers/UseInterval";
import { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { StandardToken } from "types/StandardToken.sol/StandardToken";
import StandardTokenArtifacts from "../../contracts/SmartContract721.json";
import { NFTComponent1 } from "./Components/NFTCard1";
import { NFTContainer, Section } from "./styledTags";
import WordShadow, { IWordShadowProps } from "components/WordShadow/WordShadow";
import { SmartContractHelper, ipfsLink } from "Helpers/BaseContractHelper";
import Showcase from "components/ShowCase/Showcase";
import { API } from "aws-amplify";
import NFTInfo from "Entites/NFT";
import axios from "axios";
import { requestAccount } from "Helpers/MetaMaskHelper";

declare global {
  interface Window {
    ethereum: ethers.providers.ExternalProvider;
  }
}

function Dapp() {
  // store greeting in local state
  const [tokenBalance, setTokenBalance] = useState<{
    t1: number;
    t2: number;
    t3: number;
    t4: number;
  }>({
    t1: 0,
    t2: 0,
    t3: 0,
    t4: 0,
  });

  const [tokenFees, setTokenFees] = useState<{
    t1: string;
    t2: string;
    t3: string;
    t4: string;
  }>({
    t1: "0",
    t2: "0",
    t3: "0",
    t4: "0",
  });
  const [tokenCosts, setTokenCosts] = useState<{
    t1: number;
    t2: number;
    t3: number;
    t4: number;
  }>({
    t1: 0,
    t2: 0,
    t3: 0,
    t4: 0,
  });

  // store amount in local state
  const [quantity, setQuantity] = useState<number>(1);

  const getAvailability = async (tier: number): Promise<number> => {
    if (typeof window.ethereum !== "undefined") {
      const contract = new SmartContractHelper();

      // console.log("Contract:", contract);

      // request account from metamask
      const [account] = await requestAccount();

      //we have the current logged in account at this point FYI
      // console.log("Account:", account);

      const balance = await contract.getAvailableTokens(tier);

      // console.log("TokenInfo:", balance);

      toast.success(`Tier ${tier} balance: ${balance.toString()}`);

      return balance;
    }

    throw new Error(
      "Missing install Metamask. Please access https://metamask.io/ to install extension on your browser"
    );
  };

   const buyPack = async (packType: number) => {
    try {
      if (packType || quantity) {
        if (typeof window.ethereum !== "undefined") {
          const contract = new SmartContractHelper();

          console.log("Contract:", contract);
          const transaction = await contract.buyPack(packType, quantity);

          console.log("transaction:", transaction);

          await transaction.wait();

          console.log("BuyRandom:", transaction);

          toast.success(`balance: ${transaction.confirmations}`);

          // Here's an example how you can call your API
          const init = {
            body: {
              // replace with the request body
              packType: packType,
              quantity: quantity,
            },
            headers: {
              // Optional - replace or remove as necessary
              "Content-Type": "application/json",
              // ,
              // 'Authorization': `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
            },
          };

          API.post("sudorandomnft", "/buy", init)
            .then(async (response) => {
              console.log("my API call", response);

              // Map response data to NFTInfo instances
              const nftInfos = response.data.map(
                (item) =>
                  new NFTInfo(
                    item.uriHash,
                    item.tier,
                    item.collection,
                    item.consecutiveWins,
                    item.championships,
                    item.winLossRatio,
                    item.available
                  )
              );

              console.log("Contract:", contract);
              const transaction = await contract.buyPack(packType, nftInfos);

              console.log("transaction:", transaction);

              await transaction.wait();

              console.log("BuyRandom:", transaction);

              toast.success(`balance: ${transaction.confirmations}`);
            })
            .catch((error) => {
              // Check if the error response property exists
              if (error.response) {
                // console.log("Error status:", error.response.status); // Logs the HTTP status code
                // console.log("Error data:", error.response.data); // Logs the data sent back from the server

                // If the data property is an object that contains an 'error' property, log that
                if (
                  typeof error.response.data === "object" &&
                  error.response.data.error
                ) {
                  console.log(
                    "Server Error message:",
                    error.response.data.error
                  );
                  toast.error(error.response.data.error);
                }
              }
              console.log("Error fetching data", error);
            });
        }
      } else {
        toast.error("Choose Tier");
      }
    } catch (e: any) {
      console.log("error:", e);
      if (e && e.data) toast.error(e.data.message);
    }
  }

  async function handleFees() {
    try {
      if (typeof window.ethereum !== "undefined") {
        const contract = new SmartContractHelper();
        const fees = await contract.getAllTokenFees();

        console.log(`fees: ${fees}`);
        setTokenFees({
          ...tokenFees,
          t1: ethers.utils.formatUnits(fees[0], "ether"),
          t2: ethers.utils.formatUnits(fees[1], "ether"),
          t3: ethers.utils.formatUnits(fees[2], "ether"),
          t4: ethers.utils.formatEther(fees[3]),
        });

        // console.log(
        //   "Parsed Fee => Gwei:",
        //   ethers.utils.formatUnits(fees[0], "ether")
        // );

        if (
          Number(tokenFees.t1) > 0 &&
          Number(tokenFees.t2) > 0 &&
          Number(tokenFees.t3) > 0
        )
          await convertGweiToPounds();
      } else {
        //send message to user need a wallet
      }
    } catch (e: any) {
      console.log("error:", e);
      if (e && e.data) toast.error(e.data.message);
    }
  }

  const handleTierQuantity = async () => {
    setTokenBalance({
      ...tokenBalance,
      t1: await getAvailability(1),
      t2: await getAvailability(2),
      t3: await getAvailability(3),
      t4: await getAvailability(4),
    });
  };

  const convertGweiToPounds = async () => {
    // Get the current price of ETH in £
    const response = await axios.get(
      "https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=gbp"
    );
    const currentPriceOfETHinPounds = response.data.ethereum.gbp;

    // Convert the fee from ETH to £
    const t1feeInPounds = Number(tokenFees.t1) * currentPriceOfETHinPounds;
    const t2feeInPounds = Number(tokenFees.t2) * currentPriceOfETHinPounds;
    const t3feeInPounds = Number(tokenFees.t3) * currentPriceOfETHinPounds;
    const t4feeInPounds = Number(tokenFees.t4) * currentPriceOfETHinPounds;

    // console.log("fee t1:", t1feeInPounds.toFixed(2));
    // console.log("fee t1:", t2feeInPounds.toFixed(2));
    // console.log("fee t1:", t3feeInPounds.toFixed(2));

    setTokenCosts({
      ...tokenCosts,
      t1: Number(t1feeInPounds.toFixed(2)),
      t2: Number(t2feeInPounds.toFixed(2)),
      t3: Number(t3feeInPounds.toFixed(2)),
      t4: Number(t4feeInPounds.toFixed(2)),
    });
  };

  useEffect(() => {
    (async () => {
      await handleFees();
      await handleTierQuantity();
    })();
  }, []);

  useInterval(() => {
    (async () => {
      await handleTierQuantity();
      await handleFees();
    })();
  }, 5000);

  const wordShadows: IWordShadowProps[] = [
    {
      src: "https://cdn.discordapp.com/attachments/852529597303488553/1096889832875237476/L1-PhotoRoom.png-PhotoRoom.png",
      className: "l1",
    },
    {
      src: "https://cdn.discordapp.com/attachments/852529597303488553/1096889833160454214/L2-PhotoRoom.png-PhotoRoom.png",
      className: "l2",
    },
    {
      src: "https://cdn.discordapp.com/attachments/852529597303488553/1096889833919623219/x-PhotoRoom.png-PhotoRoom.png",
      className: "x",
    },
    {
      src: "https://cdn.discordapp.com/attachments/852529597303488553/1096889833395343580/P1-PhotoRoom.png-PhotoRoom.png",
      className: "p1",
    },
    {
      src: "https://cdn.discordapp.com/attachments/852529597303488553/1096889832598425720/2-PhotoRoom.png-PhotoRoom.png",
      className: "2",
    },
    {
      src: "https://cdn.discordapp.com/attachments/852529597303488553/1096889833659572234/P2-PhotoRoom.png-PhotoRoom.png",
      className: "p2",
    },
  ];

  return (
    <>
      <Section id="dapp">
        <WordShadow words={wordShadows} />
        {
          //check avaialability
          tokenBalance.t1 &&
          tokenBalance.t2 &&
          tokenBalance.t3 &&
          tokenBalance.t4 &&
          tokenCosts.t1 > 0 &&
          tokenCosts.t2 > 0 &&
          tokenCosts.t3 > 0 &&
          tokenCosts.t4 > 0 && (
            <NFTContainer>
              <NFTComponent1
                className="card1"
                price={Number(tokenFees.t1)}
                fee={tokenCosts.t1}
                leftAvater="1"
                leftHeader={""}
                leftValue={""}
                rightAvater={
                  tokenBalance.t1 > 0 ? tokenBalance.t1.toString() : "N/A"
                }
                rightHeader={""}
                rightValue={"Available"}
                img={`${ipfsLink}/QmSh5kbfFnW2LSs5M1GoSgBqFjEm1J13gT5otYgFD1rnFU`}
                name="MEGNUM"
                footer="Lawrence Lustig X"
                setQuantity={(e) => setQuantity(e)}
                handlePurchase={async () => {
                  await buyPack(1);
                }}
              />
              <NFTComponent1
                className="card2"
                price={Number(tokenFees.t2)}
                fee={tokenCosts.t2}
                leftAvater="2"
                leftHeader={""}
                leftValue={""}
                rightAvater={
                  tokenBalance.t2 > 0 ? tokenBalance.t2.toString() : "N/A"
                }
                rightHeader={""}
                rightValue={"Available"}
                img={`${ipfsLink}/QmRgjcVfV362MUtRpJVC5L721TJ5JrCHoa68onEEKzfH2H`}
                name="SKYBLAZE"
                footer="Lawrence Lustig X"
                setQuantity={(e) => setQuantity(e)}
                handlePurchase={async () => {
                  await buyPack(2);
                }}
              />
              <NFTComponent1
                className="card3"
                price={Number(tokenFees.t3)}
                fee={tokenCosts.t3}
                leftAvater="3"
                leftHeader={""}
                leftValue={""}
                rightAvater={
                  tokenBalance.t3 > 0 ? tokenBalance.t3.toString() : "N/A"
                }
                rightHeader={""}
                rightValue={"Available"}
                img={
                  "https://p2part.infura-ipfs.io/ipfs/QmRC9kGJPEoJtoPa8qTj4JTGmqJAAB3jQsmQ5UMgEDoV8L"
                }
                name="MONKEY KING"
                footer="Lawrence Lustig X"
                setQuantity={(e) => setQuantity(e)}
                handlePurchase={async () => {
                  await buyPack(3);
                }}
              />
              <NFTComponent1
                className="card4"
                price={Number(tokenFees.t4)}
                fee={tokenCosts.t4}
                leftAvater="4"
                leftHeader={""}
                leftValue={""}
                rightAvater={
                  tokenBalance.t3 > 0 ? tokenBalance.t3.toString() : "N/A"
                }
                rightHeader={""}
                rightValue={"Available"}
                img={`${ipfsLink}/QmbWvJTFZXJ53oZDChVUspPz8qjR5VwdFtrq8wgXvJduJe`}
                name="MONKEY KING"
                footer="Lawrence Lustig X"
                setQuantity={(e) => setQuantity(e)}
                handlePurchase={async () => {
                  await buyPack(4);
                }}
              />
            </NFTContainer>
          )
        }
        <Toaster position="top-right" reverseOrder={false} />
      </Section>
      <Showcase />
    </>
  );
}

export default Dapp;
