import {
  ContractAddress,
  MetaDataInfo,
  NFT,
  SmartContract,
} from "types/NFT/BaseContract";
import base from "contracts/SmartContract721.json";
import { ethers } from "ethers";
import axios from "axios";
import { ExploreNFT } from "types/react-app-env";
import NFTInfo, { MintParams } from "Entites/NFT";
import { getAttrValue } from "./GetNFTS";

export const ipfsLink = "https://p2part.infura-ipfs.io/ipfs";

const parseToken = async (token: NFTInfo): Promise<NFT> => {
  const ipfs = token.uriHash;
  try {
    const metadata = await fetchMetaDataFromIPFS(`${ipfsLink}/${ipfs}`);

    if (getAttrValue(metadata, "tier")) {
      console.log("metaDATA:", metadata);
    }

    return {
      tokenId: Number(token.tokenId.toString()),
      ipfs: metadata,
      tier: token.tier,
      collection: token.collection,
      consecutiveWins: token.consecutiveWins,
      championships: token.championships,
      winLossRatio: token.winLossRatio,
    };
  } catch (error) {
    console.log("Error:", error);
    return {
      tokenId: null,
      ipfs: null,
      tier: null,
      collection: null,
      consecutiveWins: null,
      championships: null,
      winLossRatio: null,
    };
  }
};

async function fetchMetaDataFromIPFS(ipfsUrl: string): Promise<MetaDataInfo> {
  try {
    const response = await axios.get(ipfsUrl);
    const metadata = response.data;
    return metadata;
  } catch (error) {
    console.error("Error fetching image from IPFS:", error);
    throw error;
  }
}

export class SmartContractHelper {
  private contract: SmartContract;

  constructor() {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();

    const contract = new ethers.Contract(
      ContractAddress,
      base.abi,
      signer
    ) as SmartContract;
    this.contract = contract;
  }

  async owner(): Promise<boolean> {
    const ownerAddress = await this.contract.owner();
    const currentAddress = await this.contract.signer.getAddress();

    console.log("OwnerAddr:", ownerAddress);
    console.log("userAddr:", currentAddress);
    return ownerAddress === currentAddress;
  }

  async getAllTokens(): Promise<NFT[]> {
    const tokens = await this.contract.getAllTokens();

    return await Promise.all(
      tokens.map(async (token: any) => await parseToken(token))
    );
  }

  contractAddress(): string {
    return ContractAddress;
  }

  async getMyTokens(): Promise<NFT[]> {
    const tokens = await this.contract.getMyTokens();

    console.log("tokens:", tokens);

    return await Promise.all(
      tokens.map(async (token: NFTInfo) => await parseToken(token))
    );
  }

  async getAllTokenFees(): Promise<number[]> {
    const tokenfees = [];

    for (let i = 0; i < 4; i++) {
      tokenfees[i] = await this.contract.fee(i + 1);
    }

    return tokenfees;
  }

  async getToken(tokenId: number): Promise<NFT> {
    const token = await this.contract.getToken(tokenId);
    return await parseToken(token);
  }

  async getNFTInfo(tier: number, collection: number): Promise<NFT[]> {
    const tokens = await this.contract.getNFTInfo(tier, collection);

    return await Promise.all(
      tokens.map(async (token: any) => await parseToken(token))
    );
  }

  async randomNum(tier: number): Promise<number> {
    return await this.contract.randomNum(tier);
  }

  async getAvailableTokens(tier: number): Promise<number> {
    return await this.contract.getAvailableTokens(tier);
  }

  async checkTokenIsApproved(tokenId: number): Promise<boolean> {
    return await this.contract.checkTokenIsApproved(tokenId);
  }

  async claimNFT(claimer: string): Promise<void> {
    await this.contract.claimNFT(claimer);
  }

  async lockNFT(player: string, id: number): Promise<void> {
    await this.contract.lockNFT(player, id);
  }

  async safeTransferFrom(
    from: string,
    to: string,
    tokenId: number,
    data?: any
  ): Promise<void> {
    if (data) {
      await this.contract.safeTransferFrom(from, to, tokenId, data);
    } else {
      await this.contract.safeTransferFrom(from, to, tokenId);
    }
  }

  async approve(to: string, tokenId: number): Promise<void> {
    await this.contract.approve(to, tokenId);
  }

  async setApprovalForAll(operator: string, approved: boolean): Promise<void> {
    await this.contract.setApprovalForAll(operator, approved);
  }

  async transferFrom(from: string, to: string, tokenId: number): Promise<void> {
    await this.contract.transferFrom(from, to, tokenId);
  }

  async getApproved(tokenId: number): Promise<string> {
    return await this.contract.getApproved(tokenId);
  }

  async isApprovedForAll(owner: string, operator: string): Promise<boolean> {
    return await this.contract.isApprovedForAll(owner, operator);
  }

  async getCurrentTokenId(): Promise<number> {
    return await this.contract.getCurrentTokenId();
  }

  async incrementTokenId(tier: number): Promise<void> {
    await this.contract.incrementTokenId(tier);
  }

  async buyPack(
    packType: number,
    quantity: number
  ): Promise<ethers.ContractTransaction> {
    try {
      const gasPrice = ethers.utils.parseUnits("20", "gwei"); // or any gas price you want to set

      const fee = await this.contract.fee(packType);

      // console.log("fee:", fee);
      // console.log("Parsed Fee => Num:", Number(fee));
      // console.log("Parsed Fee => Eth:", ethers.utils.formatEther(fee));
      // console.log("Parsed Fee => Gwei:", ethers.utils.formatUnits(fee, "gwei"));

      // console.log("fee * amount", Number(fee) * data.length);

      const overrides = {
        value: ethers.utils.parseUnits((fee * quantity).toString(), "wei"),
        gasPrice: gasPrice,
      };
      console.log("override fees:", overrides);

      const transaction = await this.contract.BuyPack(
        quantity,
        packType,
        overrides
      );

      console.log("transaction", transaction);

      // Event listener to listen for the NFTBought event
      const receipt = await transaction.wait();
      console.log("receipt", receipt);

      const events = receipt.events;
      const nftBoughtEvent = events.find(
        (event: any) => event.event === "NFTBought"
      );
      if (nftBoughtEvent) {
        const tokenId = nftBoughtEvent.args.tokenId;
        console.log("NFT bought:", tokenId);
      }

      return transaction;
    } catch (error) {
      console.error("Error buying NFT:", error);
      throw error;
    }
  }

  async mint(params: MintParams): Promise<void> {
    try {
      await this.contract.mint(params.tierQuantities, params.amount);
    } catch (error) {
      console.log("Failed to Set Availability:", error);
    }
  }
}
