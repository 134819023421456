import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import SelectionPage from "components/Game/SelectionPage";
import { GameModeSelection } from "./gameSelections/GameModeSelection";
import GameArena from "./gameArena/GameArenaView";
import { SmartContractHelper } from "Helpers/BaseContractHelper";
import { NFT } from "types/NFT/BaseContract";
import ArcadeMachine from "./GameLoader";
import { SelectionEnum, GameTypeEnum, GameModeEnum } from "./GameHome";
import { Section } from "../styledTags";
import LockerRoom from "assets/Game/Backgrounds/LockerRoom.gif";
import Arena from "assets/Game/Backgrounds/RingPane.gif";

import Vangouh from "../components/gameCards/vangough.gif";

import V from "../components/gameCards/vangough.gif";

type GameHomeProps = {
  onEnumChange: (
    selection: SelectionEnum,
    gameType: GameTypeEnum,
    gameMode: GameModeEnum
  ) => void;
  _selection: SelectionEnum;
  _gameType: GameTypeEnum;
  _gameMode: GameModeEnum;
};

const GameMode = ({}) => {
  // const [gameMode, setGameMode] = useState<GameModeEnum>(_gameMode);
  const [gameMode, setGameMode] = useState<GameModeEnum>(GameModeEnum.None);
  // const [gameType, setGameType] = useState<GameTypeEnum>(_gameType);
  const [gameType, setGameType] = useState<GameTypeEnum>(GameTypeEnum.PVP);
  // const [selection, setSelection] = useState<SelectionEnum>(_selection);
  // // const [gameCards, setGameCards] = useState<NFT[]>(null);
  const [gameCards, setGameCards] = useState<NFT[]>([
    {
      championships: 34,
      collection: 1,
      consecutiveWins: 19,
      ipfs: {
        description: "gif ",
        image: Vangouh,
        name: "Carl Froch",
        attributes:[
          {
            trait_type: "author",
            value: "Lawrence Lustig"         
          },
          {
            trait_type: "consecutiveWins",
            value: 19         
          },
          {
            trait_type: "winLossRatio",
            value: 40         
          },
          {
            trait_type: "championships",
            value: 34         
          },
          {
            trait_type: "type",
            value: "raw"         
          },
          {
            trait_type: "tier",
            value: "3"         
          },
        ]
      },
      tier: 3,
      winLossRatio: 40,
      tokenId: 0,
    },
    {
      championships: 22,
      collection: 1,
      consecutiveWins: 15,
      ipfs: {
        description: "gif ",
        image: V,
        name: "Eubank",
        attributes:[
          {
            trait_type: "author",
            value: "Lawrence Lustig"         
          },
          {
            trait_type: "consecutiveWins",
            value: 15         
          },
          {
            trait_type: "winLossRatio",
            value: 31         
          },
          {
            trait_type: "championships",
            value: 22         
          },
          {
            trait_type: "type",
            value: "raw"         
          },
          {
            trait_type: "tier",
            value: "2"         
          },
        ]
      },
      tier: 2,
      winLossRatio: 31,
      tokenId: 1,
    },
    {
      championships: 1,
      collection: 1,
      consecutiveWins: 2,
      ipfs: {
        description: "gif ",
        image:
          "https://p2part.infura-ipfs.io/ipfs/QmcLUQuwP8VmjP29AaeZu7cg92vnQJkov3B7AhLaDYQrUi",
        name: "AJ vs Brezeale",
        attributes:[
          {
            trait_type: "author",
            value: "Lawrence Lustig"         
          },
          {
            trait_type: "consecutiveWins",
            value: 2         
          },
          {
            trait_type: "winLossRatio",
            value: 40         
          },
          {
            trait_type: "championships",
            value: 1         
          },
          {
            trait_type: "type",
            value: "raw"         
          },
          {
            trait_type: "tier",
            value: "4"         
          },
        ]
      },
      tier: 4,
      winLossRatio: 40,
      tokenId: 2,
    },
    {
      championships: 44,
      collection: 1,
      consecutiveWins: 18,
      ipfs: {
        description: "gif ",
        image:
          "https://cdn.discordapp.com/attachments/852529597303488553/1126520938964451439/BROOK-HATTON1.jpg",
        name: "Ebuank duel belts",
        attributes:[
          {
            trait_type: "author",
            value: "Lawrence Lustig"         
          },
          {
            trait_type: "consecutiveWins",
            value: 18         
          },
          {
            trait_type: "winLossRatio",
            value: 31         
          },
          {
            trait_type: "championships",
            value: 44         
          },
          {
            trait_type: "type",
            value: "raw"         
          },
          {
            trait_type: "tier",
            value: "1"         
          },
        ]
      },
      tier: 1,
      winLossRatio: 31,
      tokenId: 3,
    },
  ]);

  const [backgroundImage, setBackgroundImage] = useState(LockerRoom);
  const [isPlaying, setIsPlaying] = useState(false);

  const handlePlayChange = (play: boolean) => {
    setIsPlaying(play);
  };
  const navigate = useNavigate();

  const HandleUserCards = async () => {
    try {
      const smartContract = new SmartContractHelper();
      console.log("fetching:");

      const tokens: NFT[] = await smartContract.getMyTokens();
      console.log(
        "Tokens:",
        tokens.filter((token) => token.ipfs && token.ipfs.image)
      );
      setGameCards(tokens.filter((token) => token.ipfs && token.ipfs.image));
      return tokens;
    } catch (error) {
      console.log("failed to get userOwned Tokens EX:", error);
    }
  };

  // useEffect(() => {
  //   console.log("where are my cards");
  //   (async () => {
  //     await HandleUserCards();
  //   })();
  // }, []);

  useEffect(() => {
    // console.log("Game Home Selection:", selection);
    // console.log("Game Home Game Type:", gameType);
    // console.log("Game Home Game Mode:", gameMode);
    // onEnumChange(selection, gameType, gameMode);
    console.log("gameMode");

    if (isPlaying) {
      setBackgroundImage(Arena);
    }
  }, [gameType, gameMode, isPlaying]);

  // if (gameCards === null || !gameCards.every((x) => x.ipfs && x.ipfs.image)) {
  //   //users cards have not been set yet, show a loading page
  //   return <ArcadeMachine />;
  // }

  const handleToggle = () => {
    //refresh page
    if (gameMode != GameModeEnum.None) {
      setGameMode(GameModeEnum.None);
    }

    //navigate home
    if (gameMode == GameModeEnum.None) {
      navigate("../game/home");
    }
  };

  const handleSelection = () => {
    if (gameMode != GameModeEnum.None) {
      return (
        <GameArena
          gameMode={gameMode}
          NFTs={gameCards}
          onPlayChange={handlePlayChange}
        />
      );
    }

    return <SelectionPage {...GameModeSelection(setGameMode, gameCards)} />;
  };

  return (
    <Section
      id="gamemode"
      style={{
        background: `url(${backgroundImage})`,
      }}
    >
      {/* <Header /> */}
      <div style={{ height: `90vh` }}>
        <button onClick={handleToggle}>
          {gameMode != GameModeEnum.None ? "Return" : "Home"}
        </button>
        {handleSelection()}
      </div>
    </Section>
  );
};

export default GameMode;
