import React, { useState } from "react";
import "./styles/NFTCard.css";
import Card from "./base/Card";
import { ExploreNFT } from "types/react-app-env";

export type NFTCardProps = {
  nft: ExploreNFT;
};

const NFTCard = ({ nft }: NFTCardProps) => {
  const [isLike, setIsLike] = useState(false);
  const [colors, setColors] = useState([]);
  const like = () => setIsLike(!isLike);
  // console.log("props Card:", nft);
  const getColors = (colors) => {
    setColors((c) => [...c, ...colors]);
    //console.log(colors);
  };

  function truncateString(inputString: string, length: number): string {
    if (inputString.length > length) {
      return inputString.substring(0, length) + "...";
    } else {
      return inputString;
    }
  }

  return (
    <Card
      blurColor={colors[0]}
      children={
        <>
          <img className="nft-image" src={nft.NFT} alt="Missing NFT" />

          <div className="wrapper">
            <div className="info-container">
              <p className="owner">{nft.author}</p>
              <p className="name">{truncateString(nft.name, 15)}</p>
            </div>
            <div className="price-container">
              <p className="price-label">Tier</p>
              <p className="price"> {nft.tier}</p>
            </div>
          </div>
          {nft.tier < 4 && (
            <div className="buttons">
              <button className="explore-stats">{nft.championships}</button>
              <button className="explore-stats">{nft.consecutiveWins}</button>
              <button className="explore-stats">{nft.winLossRatio}%</button>
            </div>
          )}
        </>
      }
    ></Card>
  );
};

export default NFTCard;
