import React from 'react'
import { Account } from 'types/react-app-env'
import { Btn, GlowingAddrBullet, GlowingBalanceBullet, P } from './styledTags'



type Props = {
  onClick?: () => void,
  account: Account,
  link?: string
}
const Button = ({ account, link, onClick }: Props) => {
  return (

    <a href={link} aria-label={account.publicAddr} target="_blank" rel="noreferrer" >
      <Btn onClick={onClick}>
        <GlowingAddrBullet className='acc_blink_me'>.</GlowingAddrBullet>
        <P>
          {account.publicAddr}
        </P>

        {""}
        <GlowingBalanceBullet className='blink_me'>.</GlowingBalanceBullet>
        <P style={{width: '25px'}}>
          {account.ethBalance}
        </P>
      </Btn>
    </a>
  )
}

export default Button