import React, { useEffect, useRef, useState } from "react";
import "./slider.scss";
import TimeDisplay from "components/Clock/BackToTheFuture";
export type Facts = {
  header: string;
  topFact: string;
  bottomFact: string;
};
export type DataItem = {
  name: string;
  information: {};
  description: string;
  facts: Facts[];
  photo: string;
  slider: string;
  video?: string;
  renderFrag?: React.ComponentType<{ className?: string }>; // Assuming you want to pass className to it
};

interface SliderProps {
  data: DataItem[];
  startIndex?: number;
  sliderClass?: string;
  contentClass?: string;
}

const Slider = ({
  data,
  sliderClass = "",
  contentClass = "",
  startIndex,
}: SliderProps) => {
  const ObjectToString = ({ item }) => {
    // Function to convert object to string
    const convertObjectToString = (obj) => {
      return Object.entries(obj)
        .map(([key, value]) => `${key}: ${value}`)
        .join(", "); // Adjust the format as needed
    };

    // Convert the 'information' object to a string
    const informationString = convertObjectToString(item.information);

    return <p className="information">{informationString}</p>;
  };

  const [currentIndex, setCurrentIndex] = useState(0);
  const [view, setView] = useState(false);
  const isStartIndexSet = useRef(false); // This ref is to track if the startIndex has been set

  const handlePrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const handleNext = () => {
    if (currentIndex < data.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const currentData = data[currentIndex];

  const handleView = () => {
    setView(!view);
  };

  useEffect(() => {
    if (startIndex && !isStartIndexSet.current) {
      setCurrentIndex(startIndex);
      isStartIndexSet.current = true; // Mark that startIndex has been set
    }
  }, [startIndex]);

  return (
    <section id="slider">
      <div className="container">
        {data.length > 1 && (
          <ul className="navigation">
            {data.map((_, index) => (
              <li
                key={index}
                className={
                  index === currentIndex
                    ? "navigation-item active"
                    : "navigation-item"
                }
              >
                <span></span>
                <span onClick={() => setCurrentIndex(index)}>0{index + 1}</span>
              </li>
            ))}
          </ul>
        )}
        <div className={`content ${contentClass}`}>
          {data.map((item, index) => (
            <div
              key={index}
              className={
                index === currentIndex
                  ? "content-wrapper show"
                  : "content-wrapper"
              }
            >
              <h1 className="juice-flavor">{item.name} </h1>
              {/* Information Text when button/Pill is pressed */}
              {!view &&
                (!item.video ||
                  !item.renderFrag ||
                  item.video?.trim().length === 0) && (
                  <div className={`fade ${view ? "hidden" : "visible"}`}>
                    <div className="juice-describe">
                      <p className="description">{item.description}</p>
                      {/* <p className="information">{item.information}</p> */}
                      {/* {ObjectToString({item})} */}
                      {/* <ObjectToString item={item} /> */}
                    </div>
                    <div className="juice-nutrition">
                      {/* <h4>NFT Facts</h4> */}
                      <ul className="juice-nutrition-items">
                        {item.facts.map((fact, index) => {
                          return (
                            <li className="juice-nutrition-item" key={index}>
                              <span>{fact.header}</span>
                              <span>{fact.topFact}</span>
                              <span>{fact.bottomFact}</span>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                )}

              {/* Component pane when video is provided */}
              {!view && item.renderFrag && item.video?.trim().length > 0 && (
                // <div className={`fade ${view ? "hidden" : "visible"}`}>
                //   {/* {<item.renderFrag />} */}
                //   <TimeDisplay />
                // </div>
                // <TimeDisplay />
                <item.renderFrag className={"preview"} />
              )}

              {/* Image Pane when video is not set */}
              {view && (!item.video || item.video?.trim().length === 0) && (
                <img
                  className={`view fade ${view ? "visible" : "hidden"}`}
                  src={item.photo}
                  alt={item.name}
                />
              )}

              {/* Video pane when video is present*/}
              {view &&
                (item.video != null || item.video?.trim().length > 0) && (
                  <video
                    className={`view fade ${view ? "visible" : "hidden"}`}
                    src={item.video}
                    // autoPlay
                    // muted
                    controls
                  />
                )}
              <div className="add-to-cart">
                <div className="add-to-cart-btn" onClick={handleView}>
                  {/* <span>{view ? "Info" : "* Click to view *"}</span> */}
                  <button className="lButton4">
                    {view ? "*Click for Info*" : "* Click for Intro *"}
                  </button>
                  {/* <span className="cart-icon">
                    <i className="fa-solid fa-cart-plus"></i>
                  </span> */}
                </div>
                {/* <span className="heart">
                  <i className="fa-regular fa-heart"></i>
                </span> */}
              </div>
            </div>
          ))}
        </div>

        {/* --item-width: 700px; when timecomp is showing */}
        <div className={`slider ${sliderClass}`}>
          {data.length > 1 && (
            <>
              <button
                className="prev-btn"
                onClick={handlePrev}
                disabled={currentIndex === 0}
              >
                Prev
              </button>
              <button
                className="next-btn"
                onClick={handleNext}
                disabled={currentIndex === data.length - 1}
              >
                Next
              </button>
            </>
          )}
          <ul
            className="slider-wrapper"
            style={{ "--index": currentIndex } as React.CSSProperties}
          >
            {data.map((item, index) => (
              <li
                key={index}
                className={
                  index === currentIndex ? "slider-item active" : "slider-item"
                }
              >
                {item.video && item.video?.trim().length > 0 && (
                  <item.renderFrag className="preview-side" />
                )}
                {!item.video && (
                  <img
                    src={item.slider}
                    alt=""
                    onClick={() => {
                      setCurrentIndex(index);
                    }}
                  />
                )}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  );
};

export default Slider;
