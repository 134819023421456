import React from "react";
import "./resetLoader.css";

const ResetLoader = () => {
  return (
    <div id="reset_loader">
      {/* <div className="reloadDouble"></div> */}
      <div className="reloadSingle"></div>
    </div>
  );
};

export default ResetLoader;
