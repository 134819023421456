import React, { useEffect, useState } from "react";
import "./gameCards.css";
import { NFT } from "types/NFT/BaseContract";
import Sapphire from "./icons/Sapphire";
import logo from "assets/P2PLogo.png";
import FireEmoji from "./icons/FireEmoji";
import TrophyEmoji from "./icons/TrophyEmoji";
import Particles from "react-tsparticles";
import p2SpaceMan from "assets/Game/GamePlay/P2-Space-Win.gif";

export enum RecordTypes {
  Championships = "championships",
  ConsecutiveWins = "consecutiveWins",
  WinLossRatio = "winLossRatio",
}
export type GameCardProps = {
  nft: NFT;
  cardRef?: React.MutableRefObject<any>;
  punchStatus?: boolean;
  stat: RecordTypes;
};

const GameCard = ({ nft, cardRef, punchStatus, stat }: GameCardProps) => {

  const [showGiff, setShowGif] = useState(false);
  const [giffSrc, setGiffSrc] = useState(p2SpaceMan);

  const gifDuration = 10000; // Replace this with your GIF's duration in milliseconds
  const [showParticles, setShowParticles] = useState(false);

  useEffect(() => {
    console.log("im punched", punchStatus)
    if (punchStatus) {
      setGiffSrc(p2SpaceMan + '?a=' + Math.random()); // Forces the browser to "reload" the gif
      setTimeout(() => setShowGif(true), 500); // Delay showing the gif until the original image has faded

      //explode card after giff

    } else {
      setShowGif(false);
    }
  }, [punchStatus]);


  useEffect(() => {
    if (!nft) {
      //loader here
    }
    //testing only should feed from blockchain
    if (!stat) {
      console.log("Stat:", stat);
      // Get all the keys from the enum
      const recordTypeKeys = Object.keys(RecordTypes);

      // Get a random index
      const randomIndex = Math.floor(Math.random() * recordTypeKeys.length);

      // Get the random RecordType
      const randomRecordType: RecordTypes =
        RecordTypes[recordTypeKeys[randomIndex] as keyof typeof RecordTypes];

      console.log(randomRecordType);

      stat = randomRecordType;
    }

    // handleAnimation();
  }, [nft, stat]);

  useEffect(() => {
    if (showGiff) {
      setTimeout(() => setShowParticles(true), gifDuration);
    } else {
      setShowParticles(false);
    }
  }, [showGiff]);

  const explode = () => {
    return (
    <Particles 
      id="tsparticles"
      className="photo"
      options={
        {
          backgroundMode: {
            enable: false
          },
          fpsLimit: 60,
          particles: {
            number: {
              value: 0
            },
            collisions: {
              enable: false
            },
            color: {
              value: "#ffffff"
            },
            shape: {
              type: "circle"
            },
            opacity: {
              value: { min: 0.3, max: 0.8 }
            },
            size: {
              value: { min: 1, max: 10 }
            },
            move: {
              enable: true,
              size: true,
              speed: 5,
              direction: "none",
              outModes: {
                default: "destroy"
              },
              trail: {
                enable: true,
                fillColor: "#000000",
                length: 3
              }
            }
          },
          detectRetina: true,
          background: {
            color: "#000"
          },
          emitters: {
            direction: "none",
            rate: {
              delay: 0.25,
              quantity: 10
            },
            position: {
              x: 50,
              y: 50
            },
            size: {
              width: 0,
              height: 0
            },
            spawnColor: {
              value: "#ff0000",
              animation: {
                h: {
                  enable: true,
                  speed: 5
                },
                l: {
                  enable: true,
                  speed: 0,
                  offset: {
                    min: 20,
                    max: 80
                  }
                }
              }
            }
          }      }
    }  />
  
    )
  }

  const playableTier = (nft: NFT) => {
    return (
      <div
        className="cardRow topps75 container"
        ref={cardRef}

        //   style={{top: `${index * 20}px`, left: `${index * 20}px`
        // }}
      >
          <div
            className={`card tier${nft.tier}`}
            data-dis-type="simultaneous"
            // data-dis-type="self-contained"
            data-dis-particle-type="ExplodingParticle"
            // data-dis-particle-type="ExplodeToRightParticle"
            data-dis-reduction-factor="111"
          >
            <img
              className={`photo main ${nft.tier == 3 ? "giff" : ""} ${
                showGiff ? "hide" : ""
              }`}
              // ref={cardRef}

              src={`${nft.ipfs.image}`}
              style={{
                backgroundPosition: "center",
                /* background-size: contain; */
                // backgroundRepeat: "round",
              }}
              data-dis-type="simultaneous"
              // data-dis-type="self-contained"
              data-dis-particle-type="ExplodingParticle"
              // data-dis-particle-type="ExplodeToRightParticle"
              data-dis-reduction-factor="111"
            />

            {showGiff && !showParticles && (
              <img
                className={`photo sequence`}
                // ref={cardRef}

                src={giffSrc}
                style={{
                  backgroundPosition: "center",
                  /* background-size: contain; */
                  // backgroundRepeat: "round",
                }}
                data-dis-type="simultaneous"
                // data-dis-type="self-contained"
                data-dis-particle-type="ExplodingParticle"
                // data-dis-particle-type="ExplodeToRightParticle"
                data-dis-reduction-factor="111"
              />
            )}
            {showParticles && explode()}
            <div className="overlay">
              {stat && stat === RecordTypes.ConsecutiveWins && <FireEmoji />}
              {stat && stat === RecordTypes.Championships && <TrophyEmoji />}
              {stat && stat === RecordTypes.WinLossRatio && (
                <span id="win-loss">%</span>
              )}
              {/* <span className="fire html-1">🔥</span> */}

              {/* <div className="belt-shape"></div> */}
              {/* <div className="threads"></div> */}
              <div className="stat">{nft[stat]}</div>
            </div>
            <div className="name">{nft.ipfs.name}</div>
            <div className="righty">
              <div className="shine">
                <div className="star"></div>
              </div>
            </div>
            <div className="name3">{nft.ipfs.name}</div>
            <div className="header">
              <div className="gem">
                <Sapphire tier={nft.tier} />
              </div>
              <img className="logo" src={logo} alt="logo" />
            </div>
            <div className="shine"></div>
          </div>
      </div>
    );
  };

  const tier4 = (nft: NFT) => {
    return (
      <div 
      className="cardRow topps61 "
      >
        <div className={`card tier${nft.tier}`}>
        <img className={`photo`} src={`${nft.ipfs.image}`} />
          <div className="name">
            HANK AARON
            <br />
            <span
              style={{ color: "yellow", fontSize: "10px", lineHeight: "10px" }}
            >
              Right Field
            </span>
          </div>
          <div className="name2">
            Atlanta
            <br />
            Braves
          </div>
          <div className="shine"></div>
        </div>
      </div>
    );
  };

  const tier41 = (nft: NFT) => {
    return (
      <div className="cardRow dk82">
        <div className={`card tier${nft.tier}`}>
          <img className={`photo`} src={`${nft.ipfs.image}`} />
          <div className="overlay2"></div>
          <div className="overlay">
            <span className="taller">D</span>IAMOND KING
            <span className="taller">S</span>
          </div>
          <div className="donruss">DONRUSS</div>
          <div className="name">
            BABE RUTH
            <br />
            <span
              style={{ fontSize: "10px", float: "left", marginLeft: "25px" }}
            >
              OF
            </span>
            <span
              style={{ fontSize: "10px", float: "right", marginRight: "25px" }}
            >
              YANKEES
            </span>
          </div>
          <div className="shine"></div>
        </div>
        <span className="series">
          <br />
          <br />
        </span>
      </div>
    );
  };
  return (
    <>
      {/* {!stat && <div>loading ....</div>} */}
      {
      stat && 
      (nft.tier < 4 ? playableTier(nft) : tier4(nft))
      }
    </>
  );
};

export default GameCard;
