import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { MRT_ColumnDef } from "material-react-table";

//react-material-table
export const mapColumns = <T extends unknown>(obj: T) => {
    // console.log("helper:", obj)
    if (obj == null) return;

    const columns: MRT_ColumnDef<T>[] = [];

    for (const val of Object.getOwnPropertyNames(obj)) {
        // use val
        console.log("columns:", val)
        // const v: any = val;
        let filterVariant: any = 'text';//deafualt

        //check object value type 
        // if(typeof obj[val] == typeof 0){
        //     console.log("DataGrid obj.value:", obj[val])
        //     console.log("DataGrid typeof:", typeof obj[val])
        // }

        switch (typeof obj[val]) {
            case typeof 0:
                filterVariant = 'range'
                break;

            default:
                //leave at default
                filterVariant = 'text'
                break;
        }

        columns.push({
            accessorKey: val as any,
            header: val as any,
            filterVariant: filterVariant
        })
    }

    return columns;
}

//mui-datagrid
export const mapColumnz = <T extends unknown>(obj: T, apiRef?: React.MutableRefObject<any>) => {
    // console.log("helper:", obj)
    if (obj == null) return;

    const columns: GridColDef<T>[] = [];

    for (let val of Object.getOwnPropertyNames(obj)) {
        // use val
        // console.log("columns:", val)
        // const v: any = val;

        let filterVariant: any = 'text';//deafualt

        //check object value type 
        // if(typeof obj[val] == typeof 0){
        //     console.log("DataGrid obj.value:", obj[val])
        //     console.log("DataGrid typeof:", typeof obj[val])
        // }

        switch (typeof obj[val]) {
            case typeof 0:
                filterVariant = 'range'
                break;

            default:
                //leave at default
                filterVariant = 'text'
                break;
        }

        columns.push({
            field: val as any,
            headerName: val as any,
            // valueGetter: filterVariant
            editable: false,
            renderCell: (params: GridRenderCellParams<T>) => {
                apiRef.current = params.api;
                return val === "NFT" ?
                    <img
                        height="75px"
                        width={70}
                        style={{}}
                        src={params.value}
                    />
                    : params.value
            },
            filterable: val != "NFT",
            sortable: val != "NFT"
        })
    }
    return columns;
}

export const removeEmpty = <T extends unknown>(arr: Array<T>) => {
    return arr.filter(n => n);
}