import { MetaDataInfo } from "types/NFT/BaseContract";

const CardMetaData: MetaDataInfo[] = [
  {
    name: "The Great Oak",
    image: "/static/media/tier1-1.7766f08dbd7b4747597d.png",
    attributes: [
      { trait_type: "championships", value: 13 },
      { trait_type: "consecutiveWins", value: 26 },
      { trait_type: "winLossRatio", value: 80 },
      { trait_type: "author", value: "Lawrence Lustig" },
      { trait_type: "tier", value: 1 },
      { trait_type: "type", value: "raw" },
    ],
    description: "The Great Oak _ raw",
  },
  {
    name: "Professor",
    image: "/static/media/tier1-10.02df853c5ec11c0eed11.png",
    attributes: [
      { trait_type: "championships", value: 12 },
      { trait_type: "consecutiveWins", value: 57 },
      { trait_type: "winLossRatio", value: 13 },
      { trait_type: "author", value: "Lawrence Lustig" },
      { trait_type: "tier", value: 1 },
      { trait_type: "type", value: "augmentedCopy" },
    ],
    description: "Professor _ augmentedCopy",
  },
  {
    name: "Ruhin",
    image: "/static/media/tier1-2.277e3cc732d9f1e8fd2e.png",
    attributes: [
      { trait_type: "championships", value: 11 },
      { trait_type: "consecutiveWins", value: 37 },
      { trait_type: "winLossRatio", value: 95 },
      { trait_type: "author", value: "Lawrence Lustig" },
      { trait_type: "tier", value: 1 },
      { trait_type: "type", value: "ai" },
    ],
    description: "Ruhin _ ai",
  },
  {
    name: "Yghern",
    image: "/static/media/tier1-3.03d27d1b8cbd31022718.png",
    attributes: [
      { trait_type: "championships", value: 10 },
      { trait_type: "consecutiveWins", value: 11 },
      { trait_type: "winLossRatio", value: 60 },
      { trait_type: "author", value: "Lawrence Lustig" },
      { trait_type: "tier", value: 1 },
      { trait_type: "type", value: "ai" },
    ],
    description: "Yghern _ ai",
  },
  {
    name: "Aguara",
    image: "/static/media/tier1-4.81cc7f417fdfd55a3b50.png",
    attributes: [
      { trait_type: "championships", value: 9 },
      { trait_type: "consecutiveWins", value: 1 },
      { trait_type: "winLossRatio", value: 43 },
      { trait_type: "author", value: "Lawrence Lustig" },
      { trait_type: "tier", value: 1 },
      { trait_type: "type", value: "seed" },
    ],
    description: "Aguara _ seed",
  },
  {
    name: "Donimir of Troy",
    image: "/static/media/tier1-5.883a484301e62e1bdf21.png",
    attributes: [
      { trait_type: "championships", value: 9 },
      { trait_type: "consecutiveWins", value: 87 },
      { trait_type: "winLossRatio", value: 66 },
      { trait_type: "author", value: "Lawrence Lustig" },
      { trait_type: "tier", value: 1 },
      { trait_type: "type", value: "augmented" },
    ],
    description: "Donimir of Troy _ augmented",
  },
  {
    name: "Figgis Merluzzo",
    image: "/static/media/tier1-6.96fd53dd695278d07f35.png",
    attributes: [
      { trait_type: "championships", value: 9 },
      { trait_type: "consecutiveWins", value: 37 },
      { trait_type: "winLossRatio", value: 99 },
      { trait_type: "author", value: "Lawrence Lustig" },
      { trait_type: "tier", value: 1 },
      { trait_type: "type", value: "raw" },
    ],
    description: "Figgis Merluzzo _ raw",
  },
  {
    name: "Operator",
    image: "/static/media/tier1-7.ab5338b8abe43c17f1b9.png",
    attributes: [
      { trait_type: "championships", value: 8 },
      { trait_type: "consecutiveWins", value: 41 },
      { trait_type: "winLossRatio", value: 58 },
      { trait_type: "author", value: "Lawrence Lustig" },
      { trait_type: "tier", value: 1 },
      { trait_type: "type", value: "seed" },
    ],
    description: "Operator _ seed",
  },
  {
    name: "Toruviel",
    image: "/static/media/tier1-8.3b490e8352d12354e0b6.png",
    attributes: [
      { trait_type: "championships", value: 8 },
      { trait_type: "consecutiveWins", value: 49 },
      { trait_type: "winLossRatio", value: 99 },
      { trait_type: "author", value: "Lawrence Lustig" },
      { trait_type: "tier", value: 1 },
      { trait_type: "type", value: "raw" },
    ],
    description: "Toruviel _ raw",
  },
  {
    name: "Aeliren",
    image: "/static/media/tier1-9.bd5b9c00d9dec1d722d6.png",
    attributes: [
      { trait_type: "championships", value: 8 },
      { trait_type: "consecutiveWins", value: 69 },
      { trait_type: "winLossRatio", value: 59 },
      { trait_type: "author", value: "Lawrence Lustig" },
      { trait_type: "tier", value: 1 },
      { trait_type: "type", value: "raw" },
    ],
    description: "Aeliren _ raw",
  },
  {
    name: "Hefty Helge",
    image: "/static/media/tier1.19cf6ff6668e5825a833.png",
    attributes: [
      { trait_type: "championships", value: 8 },
      { trait_type: "consecutiveWins", value: 44 },
      { trait_type: "winLossRatio", value: 24 },
      { trait_type: "author", value: "Lawrence Lustig" },
      { trait_type: "tier", value: 1 },
      { trait_type: "type", value: "seedCopy" },
    ],
    description: "Hefty Helge _ seedCopy",
  },
  {
    name: "Keltullis",
    image: "/static/media/AJ.eb77532f2c5210360c6c.gif",
    attributes: [
      { trait_type: "championships", value: 13 },
      { trait_type: "consecutiveWins", value: 46 },
      { trait_type: "winLossRatio", value: 13 },
      { trait_type: "author", value: "Lawrence Lustig" },
      { trait_type: "tier", value: 2 },
      { trait_type: "type", value: "ai" },
    ],
    description: "Keltullis _ ai",
  },
  {
    name: "phoenix",
    image: "/static/media/DH.13afd9159b49628f27a1.gif",
    attributes: [
      { trait_type: "championships", value: 13 },
      { trait_type: "consecutiveWins", value: 34 },
      { trait_type: "winLossRatio", value: 95 },
      { trait_type: "author", value: "Lawrence Lustig" },
      { trait_type: "tier", value: 2 },
      { trait_type: "type", value: "seed" },
    ],
    description: "phoenix _ seed",
  },
  {
    name: "Olaf",
    image: "/static/media/Eubanks.fccf239666d340cf281d.gif",
    attributes: [
      { trait_type: "championships", value: 11 },
      { trait_type: "consecutiveWins", value: 90 },
      { trait_type: "winLossRatio", value: 38 },
      { trait_type: "author", value: "Lawrence Lustig" },
      { trait_type: "tier", value: 2 },
      { trait_type: "type", value: "aiCopy" },
    ],
    description: "Olaf _ aiCopy",
  },
  {
    name: "Damien de la Tour",
    image: "/static/media/GGG.7b3f50e26047dd23b0f7.gif",
    attributes: [
      { trait_type: "championships", value: 11 },
      { trait_type: "consecutiveWins", value: 70 },
      { trait_type: "winLossRatio", value: 6 },
      { trait_type: "author", value: "Lawrence Lustig" },
      { trait_type: "tier", value: 2 },
      { trait_type: "type", value: "seedCopy" },
    ],
    description: "Damien de la Tour _ seedCopy",
  },
  {
    name: "Ithlinne Aegli",
    image: "/static/media/NFT Moving 1 .74d51fb32146bbefb0ce.gif",
    attributes: [
      { trait_type: "championships", value: 11 },
      { trait_type: "consecutiveWins", value: 84 },
      { trait_type: "winLossRatio", value: 22 },
      { trait_type: "author", value: "Lawrence Lustig" },
      { trait_type: "tier", value: 2 },
      { trait_type: "type", value: "raw" },
    ],
    description: "Ithlinne Aegli _ raw",
  },
  {
    name: "Queen Adalia",
    image: "/static/media/NFT Moving 2.190abc87e0f8829582ca.gif",
    attributes: [
      { trait_type: "championships", value: 11 },
      { trait_type: "consecutiveWins", value: 7 },
      { trait_type: "winLossRatio", value: 72 },
      { trait_type: "author", value: "Lawrence Lustig" },
      { trait_type: "tier", value: 2 },
      { trait_type: "type", value: "augmented" },
    ],
    description: "Queen Adalia _ augmented",
  },
  {
    name: "Avallac'h: Sage",
    image: "/static/media/NFT Moving 3.21e1ef4393068cfc1589.gif",
    attributes: [
      { trait_type: "championships", value: 11 },
      { trait_type: "consecutiveWins", value: 89 },
      { trait_type: "winLossRatio", value: 27 },
      { trait_type: "author", value: "Lawrence Lustig" },
      { trait_type: "tier", value: 2 },
      { trait_type: "type", value: "seed" },
    ],
    description: "Avallac'h: Sage _ seed",
  },
  {
    name: "Ihuarraquax",
    image: "/static/media/gif1.06069a3c61f4f17b87bd.gif",
    attributes: [
      { trait_type: "championships", value: 10 },
      { trait_type: "consecutiveWins", value: 5 },
      { trait_type: "winLossRatio", value: 33 },
      { trait_type: "author", value: "Lawrence Lustig" },
      { trait_type: "tier", value: 2 },
      { trait_type: "type", value: "seed" },
    ],
    description: "Ihuarraquax _ seed",
  },
  {
    name: "Madame Luiza",
    image: "/static/media/gifx.06069a3c61f4f17b87bd.gif",
    attributes: [
      { trait_type: "championships", value: 9 },
      { trait_type: "consecutiveWins", value: 31 },
      { trait_type: "winLossRatio", value: 55 },
      { trait_type: "author", value: "Lawrence Lustig" },
      { trait_type: "tier", value: 2 },
      { trait_type: "type", value: "augmentedCopy" },
    ],
    description: "Madame Luiza _ augmentedCopy",
  },
  {
    name: "Seltkirk of Gulet",
    image: "/static/media/glassstain_photospread.0cb759fc0ae7e91959c9.gif",
    attributes: [
      { trait_type: "championships", value: 9 },
      { trait_type: "consecutiveWins", value: 99 },
      { trait_type: "winLossRatio", value: 82 },
      { trait_type: "author", value: "Lawrence Lustig" },
      { trait_type: "tier", value: 2 },
      { trait_type: "type", value: "augmented" },
    ],
    description: "Seltkirk of Gulet _ augmented",
  },
  {
    name: "Artis",
    image: "/static/media/mcgregor.7781c64bdbe0e93ca96c.gif",
    attributes: [
      { trait_type: "championships", value: 9 },
      { trait_type: "consecutiveWins", value: 84 },
      { trait_type: "winLossRatio", value: 95 },
      { trait_type: "author", value: "Lawrence Lustig" },
      { trait_type: "tier", value: 2 },
      { trait_type: "type", value: "aiCopy" },
    ],
    description: "Artis _ aiCopy",
  },
  {
    name: "Vildkaarl",
    image:
      "/static/media/night_dark_Kaleidoscoper_spin-blur.57358c2c653b5e39cb92.gif",
    attributes: [
      { trait_type: "championships", value: 9 },
      { trait_type: "consecutiveWins", value: 74 },
      { trait_type: "winLossRatio", value: 15 },
      { trait_type: "author", value: "Lawrence Lustig" },
      { trait_type: "tier", value: 2 },
      { trait_type: "type", value: "aiCopy" },
    ],
    description: "Vildkaarl _ aiCopy",
  },
  {
    name: "Moreelse",
    image:
      "/static/media/night_dark_shake_Kaleidoscoper.4c9970504dabde822777.gif",
    attributes: [
      { trait_type: "championships", value: 9 },
      { trait_type: "consecutiveWins", value: 79 },
      { trait_type: "winLossRatio", value: 60 },
      { trait_type: "author", value: "Lawrence Lustig" },
      { trait_type: "tier", value: 2 },
      { trait_type: "type", value: "augmentedCopy" },
    ],
    description: "Moreelse _ augmentedCopy",
  },
  {
    name: "Vrygheff",
    image:
      "/static/media/stainGlass_PhotoSpread_Kaleidoscoper.8062d2d0aafc5ae88685.gif",
    attributes: [
      { trait_type: "championships", value: 9 },
      { trait_type: "consecutiveWins", value: 6 },
      { trait_type: "winLossRatio", value: 73 },
      { trait_type: "author", value: "Lawrence Lustig" },
      { trait_type: "tier", value: 2 },
      { trait_type: "type", value: "seedCopy" },
    ],
    description: "Vrygheff _ seedCopy",
  },
  {
    name: "Jutta an Dimun",
    image:
      "/static/media/stainGlass_PhotoSpread_Kaleidoscoper_Kaleidoscoper.602e2281f94fee2f344d.gif",
    attributes: [
      { trait_type: "championships", value: 8 },
      { trait_type: "consecutiveWins", value: 6 },
      { trait_type: "winLossRatio", value: 54 },
      { trait_type: "author", value: "Lawrence Lustig" },
      { trait_type: "tier", value: 2 },
      { trait_type: "type", value: "aiCopy" },
    ],
    description: "Jutta an Dimun _ aiCopy",
  },
  {
    name: "Vandergrift",
    image:
      "/static/media/stainGlass_PhotoSpread_Kaleidoscoper_Kaleidoscoper_groovy_Rainbow.58de137e56624d2fe23d.gif",
    attributes: [
      { trait_type: "championships", value: 8 },
      { trait_type: "consecutiveWins", value: 63 },
      { trait_type: "winLossRatio", value: 63 },
      { trait_type: "author", value: "Lawrence Lustig" },
      { trait_type: "tier", value: 2 },
      { trait_type: "type", value: "augmented" },
    ],
    description: "Vandergrift _ augmented",
  },
  {
    name: "Dennis Helge",
    image:
      "/static/media/stainGlass_PhotoSpread_grooveyRainbow.41361789274127620aa0.gif",
    attributes: [
      { trait_type: "championships", value: 8 },
      { trait_type: "consecutiveWins", value: 43 },
      { trait_type: "winLossRatio", value: 82 },
      { trait_type: "author", value: "Lawrence Lustig" },
      { trait_type: "tier", value: 2 },
      { trait_type: "type", value: "raw" },
    ],
    description: "Dennis Helge _ raw",
  },
  {
    name: "Boris",
    image:
      "/static/media/stainglass_photoSpread_Kaleidoscoper_reflection.8c134413294226f36a55.gif",
    attributes: [
      { trait_type: "championships", value: 8 },
      { trait_type: "consecutiveWins", value: 36 },
      { trait_type: "winLossRatio", value: 27 },
      { trait_type: "author", value: "Lawrence Lustig" },
      { trait_type: "tier", value: 2 },
      { trait_type: "type", value: "augmentedCopy" },
    ],
    description: "Boris _ augmentedCopy",
  },
  {
    name: "Menno Coehoorn",
    image:
      "/static/media/stainglass_photoSpread_reflectingWater.c3677c3c23cfa604bbad.gif",
    attributes: [
      { trait_type: "championships", value: 8 },
      { trait_type: "consecutiveWins", value: 23 },
      { trait_type: "winLossRatio", value: 91 },
      { trait_type: "author", value: "Lawrence Lustig" },
      { trait_type: "tier", value: 2 },
      { trait_type: "type", value: "seedCopy" },
    ],
    description: "Menno Coehoorn _ seedCopy",
  },
  {
    name: "Draug",
    image: "/static/media/DARK.a7d3cf68d1f0c991fdff.jpg",
    attributes: [
      { trait_type: "championships", value: 13 },
      { trait_type: "consecutiveWins", value: 28 },
      { trait_type: "winLossRatio", value: 40 },
      { trait_type: "author", value: "Lawrence Lustig" },
      { trait_type: "tier", value: 3 },
      { trait_type: "type", value: "augmented" },
    ],
    description: "Draug _ augmented",
  },
  {
    name: "Tinboy",
    image: "/static/media/ESCHER_DARK.fa56dcbe8a5b0e34c49b.jpg",
    attributes: [
      { trait_type: "championships", value: 12 },
      { trait_type: "consecutiveWins", value: 92 },
      { trait_type: "winLossRatio", value: 57 },
      { trait_type: "author", value: "Lawrence Lustig" },
      { trait_type: "tier", value: 3 },
      { trait_type: "type", value: "augmentedCopy" },
    ],
    description: "Tinboy _ augmentedCopy",
  },
  {
    name: "Dettlaff: Higher Vampire",
    image: "/static/media/glassstain.bba7a5da155870223479.jpg",
    attributes: [
      { trait_type: "championships", value: 10 },
      { trait_type: "consecutiveWins", value: 70 },
      { trait_type: "winLossRatio", value: 83 },
      { trait_type: "author", value: "Lawrence Lustig" },
      { trait_type: "tier", value: 3 },
      { trait_type: "type", value: "ai" },
    ],
    description: "Dettlaff: Higher Vampire _ ai",
  },
  {
    name: "Letho",
    image: "/static/media/neon.051e837461ca6a64dbfd.jpg",
    attributes: [
      { trait_type: "championships", value: 10 },
      { trait_type: "consecutiveWins", value: 94 },
      { trait_type: "winLossRatio", value: 88 },
      { trait_type: "author", value: "Lawrence Lustig" },
      { trait_type: "tier", value: 3 },
      { trait_type: "type", value: "seedCopy" },
    ],
    description: "Letho _ seedCopy",
  },
  {
    name: "Hjalmar an Craite",
    image: "/static/media/night_dark.2570e654079a7dff47d9.jpg",
    attributes: [
      { trait_type: "championships", value: 10 },
      { trait_type: "consecutiveWins", value: 36 },
      { trait_type: "winLossRatio", value: 54 },
      { trait_type: "author", value: "Lawrence Lustig" },
      { trait_type: "tier", value: 3 },
      { trait_type: "type", value: "aiCopy" },
    ],
    description: "Hjalmar an Craite _ aiCopy",
  },
  {
    name: "Savolla",
    image: "/static/media/rbg.dc0c00414870bde49e6c.jpg",
    attributes: [
      { trait_type: "championships", value: 9 },
      { trait_type: "consecutiveWins", value: 14 },
      { trait_type: "winLossRatio", value: 38 },
      { trait_type: "author", value: "Lawrence Lustig" },
      { trait_type: "tier", value: 3 },
      { trait_type: "type", value: "augmentedCopy" },
    ],
    description: "Savolla _ augmentedCopy",
  },
  {
    name: "Golyat",
    image: "/static/media/vangough_night.558f3b674d949a0850b7.jpg",
    attributes: [
      { trait_type: "championships", value: 8 },
      { trait_type: "consecutiveWins", value: 52 },
      { trait_type: "winLossRatio", value: 86 },
      { trait_type: "author", value: "Lawrence Lustig" },
      { trait_type: "tier", value: 3 },
      { trait_type: "type", value: "ai" },
    ],
    description: "Golyat _ ai",
  },
  {
    name: "Regis",
    image: "/static/media/collacatable.b4569eac9872bb1c3c30.png",
    attributes: [
      { trait_type: "championships", value: 13 },
      { trait_type: "consecutiveWins", value: 3 },
      { trait_type: "winLossRatio", value: 82 },
      { trait_type: "author", value: "Lawrence Lustig" },
      { trait_type: "tier", value: 4 },
      { trait_type: "type", value: "seed" },
    ],
    description: "Regis _ seed",
  },
  {
    name: "Hemdall",
    image: "/static/media/collactable-1.fae729ce5c0859a4aedf.png",
    attributes: [
      { trait_type: "championships", value: 12 },
      { trait_type: "consecutiveWins", value: 88 },
      { trait_type: "winLossRatio", value: 10 },
      { trait_type: "author", value: "Lawrence Lustig" },
      { trait_type: "tier", value: 4 },
      { trait_type: "type", value: "aiCopy" },
    ],
    description: "Hemdall _ aiCopy",
  },
  {
    name: "Imlerith",
    image: "/static/media/collactable-2.5ca0da97c16dd8e2b677.png",
    attributes: [
      { trait_type: "championships", value: 12 },
      { trait_type: "consecutiveWins", value: 61 },
      { trait_type: "winLossRatio", value: 5 },
      { trait_type: "author", value: "Lawrence Lustig" },
      { trait_type: "tier", value: 4 },
      { trait_type: "type", value: "ai" },
    ],
    description: "Imlerith _ ai",
  },
  {
    name: "Plague Maiden",
    image: "/static/media/collactable-3.396fa830f5485d111678.png",
    attributes: [
      { trait_type: "championships", value: 12 },
      { trait_type: "consecutiveWins", value: 67 },
      { trait_type: "winLossRatio", value: 36 },
      { trait_type: "author", value: "Lawrence Lustig" },
      { trait_type: "tier", value: 4 },
      { trait_type: "type", value: "ai" },
    ],
    description: "Plague Maiden _ ai",
  },
  {
    name: "Vernossiel",
    image: "/static/media/collactable-4.912781c11e9a84f00793.png",
    attributes: [
      { trait_type: "championships", value: 12 },
      { trait_type: "consecutiveWins", value: 66 },
      { trait_type: "winLossRatio", value: 100 },
      { trait_type: "author", value: "Lawrence Lustig" },
      { trait_type: "tier", value: 4 },
      { trait_type: "type", value: "raw" },
    ],
    description: "Vernossiel _ raw",
  },
  {
    name: "Roche: Merciless",
    image: "/static/media/collactable-5.988f5db192974d2b26f0.png",
    attributes: [
      { trait_type: "championships", value: 12 },
      { trait_type: "consecutiveWins", value: 74 },
      { trait_type: "winLossRatio", value: 68 },
      { trait_type: "author", value: "Lawrence Lustig" },
      { trait_type: "tier", value: 4 },
      { trait_type: "type", value: "augmented" },
    ],
    description: "Roche: Merciless _ augmented",
  },
  {
    name: "Wild Boar of the Sea",
    image: "/static/media/collactable-6.d4b06653b916bb892c29.png",
    attributes: [
      { trait_type: "championships", value: 12 },
      { trait_type: "consecutiveWins", value: 9 },
      { trait_type: "winLossRatio", value: 29 },
      { trait_type: "author", value: "Lawrence Lustig" },
      { trait_type: "tier", value: 4 },
      { trait_type: "type", value: "aiCopy" },
    ],
    description: "Wild Boar of the Sea _ aiCopy",
  },
  {
    name: "Syanna",
    image: "/static/media/collactable-7.9c9a440dc9866b6edb06.png",
    attributes: [
      { trait_type: "championships", value: 12 },
      { trait_type: "consecutiveWins", value: 37 },
      { trait_type: "winLossRatio", value: 71 },
      { trait_type: "author", value: "Lawrence Lustig" },
      { trait_type: "tier", value: 4 },
      { trait_type: "type", value: "seed" },
    ],
    description: "Syanna _ seed",
  },
];
export default CardMetaData;
