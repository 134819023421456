import { GameSelectionProps } from "components/Game/SelectionPage";
import { SelectionEnum } from "../GameHome";
import OnChain from "assets/Game/OnChain.gif";
import OffChain from "assets/Game/OffChain.gif";

export const HomePageSelection = (
  setSelection: React.Dispatch<React.SetStateAction<SelectionEnum>>,
): GameSelectionProps => {
  return {
    gameProps: {
      notifications: [
        {
          notificationIcon:
            "https://emoji.redditmedia.com/vl6p2wgtkou41_t5_3h47q/kirby-star",
          onClick: () => {
            console.log("Im A Notification");
            // setSelection(selectionEnum.OnChain)
          },
          className: "glow",
        },
      ],
      icons: [
        {
          icon: OnChain,
          iconName: "ON-CHAIN",
          onClick: () => {
            console.log("on chain chosen");
            setSelection(SelectionEnum.OnChain);
          },
          style: { borderRadius: "20px" },
          className: "",
        },
        {
          icon: OffChain,
          iconName: "OFF-CHAIN",
          onClick: () => {
            console.log("off chain chosen");
            setSelection(SelectionEnum.OffChain);
          },
          style: { borderRadius: "20px" },
          className: "",
        },
      ],
      informations: [
        {
          infoIcon: "info",
          information: "something important",
          onClick: () => console.log("Information Settings"),
          className: "material-icons",
        },
        {
          infoIcon: "info",
          information: "something more important",
          onClick: () => console.log("Info Shop"),
          className: "material-icons-outlined yellow",
        },
      ],
    },
  };
};
