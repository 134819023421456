import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import SelectionPage from "components/Game/SelectionPage";
import { HomePageSelection } from "./gameSelections/HomePageSelection";
import { GameTypeSelection } from "./gameSelections/GameTypeSelection";
import { SmartContractHelper } from "Helpers/BaseContractHelper";
import { NFT } from "types/NFT/BaseContract";
import ArcadeMachine from "./GameLoader";

export enum SelectionEnum {
  Home = "Home",
  OnChain = "On Chain",
  OffChain = "Off Chain",
}

export enum GameTypeEnum {
  None = "None",
  PVP = "P V P",
  PVE = "P V C",
}

export enum GameModeEnum {
  None = "None",
  StatAttack = "Stat Attack",
  CardWar = "Card War",
  PunchersChance = "Punchers Chance",
  Tournament = "Tournament",
}

type GameHomeProps = {
  onEnumChange?: (
    selection: SelectionEnum,
    gameType: GameTypeEnum,
    gameMode: GameModeEnum
  ) => void;
  _selection: SelectionEnum;
  _gameType?: GameTypeEnum;
  _gameMode?: GameModeEnum;
};

const GameHome = ({ _selection }: GameHomeProps) => {
  const [gameType, setGameType] = useState<GameTypeEnum>(null);
  const [selection, setSelection] = useState<SelectionEnum>(_selection);
  // const [gameCards, setGameCards] = useState<NFT[]>(null);
  const [gameCards, setGameCards] = useState<NFT[]>([
    {
      championships: 1,
      collection: 1,
      consecutiveWins: 2,
      ipfs: {
        description: "gif ",
        image:
          "https://p2part.infura-ipfs.io/ipfs/QmcLUQuwP8VmjP29AaeZu7cg92vnQJkov3B7AhLaDYQrUi",
        name: "AJ vs Brezeale",
        attributes: [
          {
            trait_type: "author",
            value: "Lawrence Lustig",
          },
          {
            trait_type: "consecutiveWins",
            value: 2,
          },
          {
            trait_type: "winLossRatio",
            value: 40,
          },
          {
            trait_type: "championships",
            value: 1,
          },
          {
            trait_type: "type",
            value: "raw",
          },
          {
            trait_type: "tier",
            value: "1",
          },
        ],
      },
      tier: 1,
      winLossRatio: 40,
    },
  ]);
  const [onChainSelection, setOnChainSelection] = useState<SelectionEnum>();
  const navigate = useNavigate();

  const HandleUserCards = async () => {
    try {
      const smartContract = new SmartContractHelper();
      console.log("fetching:");

      const tokens: NFT[] = await smartContract.getMyTokens();
      console.log(
        "Tokens:",
        tokens.filter((token) => token.ipfs && token.ipfs.image)
      );
      setGameCards(tokens.filter((token) => token.ipfs && token.ipfs.image));
      return tokens;
    } catch (error) {
      console.log("failed to get userOwned Tokens EX:", error);
    }
  };

  // useEffect(() => {
  //   console.log("where are my cards");
  //   (async () => {
  //     await HandleUserCards();
  //   })();
  // }, []);

  useEffect(() => {
    // console.log("Game Home Selection:", selection);
    // console.log("Game Home Game Type:", gameType);
    // console.log("Game Home Game Mode:", gameMode);

    if (gameType) navigate("modes");
  }, [selection, gameType]);

  // Homepage
  switch (selection) {
    case SelectionEnum.Home:
      return <SelectionPage {...HomePageSelection(setSelection)} />;
    case SelectionEnum.OnChain:
      return <SelectionPage {...GameTypeSelection(setGameType /*navigate*/)} />;
    // case SelectionEnum.OffChain:
    //   return <SelectionPage {...OffChainSelection(setSelection)} />;
    // default:
    //   return <SelectionPage {...HomePageSelection(setSelection)} />;
  }
};

export default GameHome;
