import { useEffect, useRef, useState } from "react";
import CardSelection from "../cardSelection/CardSelection";
import { NFT } from "types/NFT/BaseContract";
import { GameModeEnum } from "../GameHome";
import "./gameArena.css";

import Vangouh from "../../components/gameCards/vangough.gif";

import Tape from "./Tape";
import GameCard, { RecordTypes } from "../gameCards/GameCard";
import Punch from "../gameCards/Punch";
import DisintegratingCard from "components/CardUI/disintegratingCard/DisintegratingCard";
import Disintegrate from "components/CardUI/disintegrate/disintegrate";
type GameArenaProps = {
  gameMode: GameModeEnum;
  NFTs: NFT[];
  // Callback function to update parent state
  onPlayChange?: (play: boolean) => void;
};

export type CardDisintigrate = {
  nft: NFT;
  animate: string;
  isChosen?: boolean;
  punched?: boolean;
};

type TaleOfTheTape = {
  p1: CardDisintigrate;
  p2: CardDisintigrate;
};
const GameArena = ({ gameMode, NFTs, onPlayChange }: GameArenaProps) => {
  const [snap, setSnap] = useState(Boolean);
  const [play, setPlay] = useState<boolean>(false);
  const [myCards, setMyCards] = useState<CardDisintigrate[]>([]);
  const [p2Cards, setP2Cards] = useState<CardDisintigrate[]>([]);

  const [tape, setTape] = useState<TaleOfTheTape>({ p1: null, p2: null });

  const [cardWon, setCardWon] = useState<CardDisintigrate>(null);

  const [punchP1Status, setP1PunchStatus] = useState(false);
  const [punchP2Status, setP2PunchStatus] = useState(false);

  const [comparedOn, setComparedOn] = useState<RecordTypes>(null);
  const [wins, setWins] = useState<CardDisintigrate[]>([]);
  // Testing perposes only

  const StartGame = (nfts: NFT[]) => {
    setPlay(true);
    setMyCards(
      nfts.map((token) => {
        // token.giffURL = p2SpaceMan;
        return {
          nft: token,
          animate: "",
          stat: null,
        };
      })
    );

    const cards: CardDisintigrate[] = [
      {
        nft: {
          championships: 22,
          collection: 1,
          consecutiveWins: 15,
          ipfs: {
            description: "gif ",
            image: "",
            name: "Eubank",
            attributes:[
              {
                trait_type: "author",
                value: "Lawrence Lustig"         
              },
              {
                trait_type: "consecutiveWins",
                value: 15         
              },
              {
                trait_type: "winLossRatio",
                value: 31         
              },
              {
                trait_type: "championships",
                value: 22         
              },
              {
                trait_type: "type",
                value: "raw"         
              },
              {
                trait_type: "tier",
                value: "2"         
              },
            ]
              },
          tier: 2,
          winLossRatio: 31,
          tokenId: 1,
        },
        animate: "",
      },
      {
        nft: {
          championships: 84,
          collection: 1,
          consecutiveWins: 56,
          ipfs: {
            description: "gif ",
            image: Vangouh,
            name: "Carl Zagge",
            attributes:[
              {
                trait_type: "author",
                value: "Lawrence Lustig"         
              },
              {
                trait_type: "consecutiveWins",
                value: 56         
              },
              {
                trait_type: "winLossRatio",
                value: 100         
              },
              {
                trait_type: "championships",
                value: 84         
              },
              {
                trait_type: "type",
                value: "raw"         
              },
              {
                trait_type: "tier",
                value: "3"         
              },
            ]
              },
          tier: 3,
          winLossRatio: 100,
          tokenId: 2,
        },
        animate: "",
      },
      {
        nft: {
          championships: 44,
          collection: 1,
          consecutiveWins: 18,
          ipfs: {
            description: "gif ",
            image:
              "https://cdn.discordapp.com/attachments/852529597303488553/1126520938964451439/BROOK-HATTON1.jpg",
            name: "Ebuank duel belts",
            attributes:[
              {
                trait_type: "author",
                value: "Lawrence Lustig"         
              },
              {
                trait_type: "consecutiveWins",
                value: 18         
              },
              {
                trait_type: "winLossRatio",
                value: 31         
              },
              {
                trait_type: "championships",
                value: 44         
              },
              {
                trait_type: "type",
                value: "seed"         
              },
              {
                trait_type: "tier",
                value: "1"         
              },
            ]
              },
          tier: 1,
          winLossRatio: 31,
          tokenId: 3,
        },
        animate: "",
      },
    ];

    let randomItems = [];
    let numberOfItemsToSelect = nfts.length; // Change this to select more or fewer items

    for (let i = 0; i < numberOfItemsToSelect; i++) {
      let randomIndex = Math.floor(Math.random() * cards.length);

      // cards[randomIndex].nft.giffURL = p2SpaceMan;

      randomItems.push(cards[randomIndex]);
      cards.splice(randomIndex, 1); // Removes the selected item from the original array
    }

    setP2Cards(randomItems);
  };

  // useEffect(() => {

  // }, []);

  const handleSelected = (nft: CardDisintigrate, index) => {
    console.log("handleselected tape");
    setP1PunchStatus(false);
    setP2PunchStatus(false);

    setTape((tape) => {
      return {
        p1: nft,
        p2: p2Cards[index],
      };
    });
  };

  const handleSelectedWin = (nft: CardDisintigrate) => {
    console.log("handleselectedWin tape");
    setCardWon(nft);
  };

  useEffect(() => {
    // Call the callback function to update parent state
    // if (!play) onPlayChange(play);
    if (play === false && onPlayChange) {
      onPlayChange(play);
    }
    console.log("mycards:", myCards);

    //Tetsing purposes only
    const recordTypeKeys = Object.keys(RecordTypes);

    // Get a random index
    const randomIndex = Math.floor(Math.random() * recordTypeKeys.length);

    // Get the random RecordType
    const randomRecordType: RecordTypes =
      RecordTypes[recordTypeKeys[randomIndex] as keyof typeof RecordTypes];

    console.log(randomRecordType);

    setComparedOn(randomRecordType);
  }, [play, onPlayChange, snap]);

  const handleSnap = () => {
    //Used to set the disintegrate method against a given NFT
    // cards[0].disintergrate = true;
    // setCards(cards);
    setSnap(!snap);

    setMyCards(
      myCards.map(({ nft, animate }) => {
        if (nft === myCards[0].nft) {
          // nft.disintergrate = true;
          animate = "attack attack-phase";
        } else {
          nft.disintergrate = false;
          animate = "";
        }
        return {
          nft,
          animate,
        };
      })
    );
    setP2Cards(
      p2Cards.map(({ nft, animate }) => {
        if (nft === p2Cards[0].nft && !nft.disintergrate) {
          nft.disintergrate = true;
          animate = "defend attack-phase";
          // nft.giffURL = p2SpaceMan;
        } else {
          nft.disintergrate = false;
          // nft.giffURL = null;
          animate = "";
        }
        return {
          nft,
          animate,
        };
      })
    );
  };

  useEffect(() => {
    // if (tape && tape.p1 && tape.p2 && tape.p1.punched && tape.p2.punched)
    //   return;
  }, [myCards, p2Cards, tape]);

  useEffect(() => {
    if (punchP1Status) {
      //set
      console.log("which card is being punched here 1:", tape.p1);
      setTape(() => {
        tape.p1.punched = true;
        return {
          ...tape,
          p1: tape.p1,
        };
      });
    }
    if (punchP2Status) {
      //set
      console.log("which card is being punched here 2:", tape.p2);
      setTape(() => {
        tape.p2.punched = true;
        return {
          ...tape,
          p2: tape.p2,
        };
      });
    }
  }, [punchP1Status, punchP2Status]);

  return (
    <>
      <div
        id="gameArena"
        style={
          {
            // background: `url(${Arena})`,
          }
        }
      >
        {!play ? (
          <CardSelection
            startGame={StartGame}
            gameMode={gameMode}
            NFTs={NFTs}
          />
        ) : (
          <>
            {/* <button onClick={handleSnap}>Tale of the Tape</button> */}
            <Tape
              nfts={myCards}
              onSelectedTape={handleSelected}
              onSelectedWin={handleSelectedWin}
              nftsWon={wins}
            />

            {tape.p1 && tape.p2 && (
              <div id="arena">
                <Punch
                  onPunch={setP2PunchStatus}
                  disablePunch={punchP1Status}
                  punched={tape.p1.punched}
                  nft={tape.p1.nft}
                  player={1}
                  stat={comparedOn}
                />
                <Punch
                  onPunch={setP1PunchStatus}
                  disablePunch={punchP2Status}
                  punched={tape.p2.punched}
                  nft={tape.p2.nft}
                  player={2}
                  stat={comparedOn}
                />

                {/* <Disintegrate
                  CardComponent={(ref) => (
                    <div className="cardRow">
                      <GameCard 
                        nft={tape.p2.nft}
                        cardRef={ref}
                        stat={comparedOn}
                      />
                    </div>
                  )}    
                />

                <DisintegratingCard
                  key={tape.p2.nft.tokenId}
                  nft={tape.p2.nft}
                  isDev={true}
                  snap={tape.p2.punched}
                  animate={tape.p2.animate}
                  CardComponent={(ref) => (
                    <div className="cardRow">
                      <GameCard 
                        nft={tape.p2.nft}
                        cardRef={ref}
                        stat={comparedOn}
                      />
                    </div>
                  )}
                />
                 */}
              </div>
            )}

            {cardWon && <GameCard nft={cardWon.nft} stat={comparedOn} />}
          </>
        )}
      </div>
    </>
  );
};

export default GameArena;
