import CollectionCard from "components/Card/Collection Card/CollectionCard";
// import Header from './components/Header/Header';
import { useState, useEffect } from "react";
import axios from "axios";
import NFTGrid from "components/Explore/NFTGrid";
import NFTModal from "components/NFTMain/NFTModal";
import { ExploreNFT, ExploreNFTFilter } from "types/react-app-env";
import { ExploreApp, Section } from "./styledTags";
import { getNFTS } from "Helpers/GetNFTS";
import DataGridNFT from "components/Explore/Navigation/DataGridNFT";

const ExploreNFTS = () => {
  const [nftListData, setNFTListData] = useState<ExploreNFT[]>([]);

  const [selectedNFT, setselectedNFT] = useState<number>(null);
  const [nftFilteredListData, setNFTFilteredListData] = useState<ExploreNFT[]>(
    []
  );

  const [showUserTokens, setShowUserTokens] = useState(false);

  const filterList = (filter: ExploreNFTFilter) => {
    // console.log("ExploreNFTS.tsx filter:", filter);

    // setPunkListData()

    var list = nftListData.filter(function (item) {
      for (var key in filter) {
        if (item[key] === undefined || item[key] != filter[key]) {
          item.hidden = true;
          return false;
        }
        item.hidden = false;
      }
      return true;
    });

    if (list == null || list.length == 0) {
      setNFTListData(nftListData);
      return;
    }

    // setNFTFilteredListData(list);
    setNFTFilteredListData(nftListData);
  };

  const handleDataGridDataChange = (nftIds: number[]) => {
    if (nftListData.length > 0 && nftIds.length > 0) {
      console.log("data:", nftIds);
      setNFTFilteredListData(
        nftListData
          .filter((item) => nftIds.includes(item.id))
          .sort((a, b) => nftIds.indexOf(a.id) - nftIds.indexOf(b.id))
      );
    } else {
      setNFTFilteredListData([]);
    }
  };

  const getAllNFTs = async () => {
    // setpunkListData(null);
    if (nftListData.length == 0) {
      setNFTListData(getNFTS());
    }
  };

  const getMyNFTs = async () => {
    //blk chain call to get user tokens
    setNFTListData([]);
    //set the setNFTListData with the data from the blk chain
  };

  useEffect(() => {
    if (showUserTokens) {
      (async () => {
        await getMyNFTs();
      })();
    } else {
      getAllNFTs();
    }
  }, [showUserTokens]);

  return (
    <Section id="exploreNfts">
      <>
        <DataGridNFT
          data={nftListData}
          onDataChange={handleDataGridDataChange}
          showUserTokens={showUserTokens}
          handleShowUserTokens={() => setShowUserTokens(!showUserTokens)}
        />

        <ExploreApp className="app">
          {/* <Header /> */}
          <>
            {/* <NFTModal
              nftListData={nftFilteredListData}
              selectedNFT={selectedNFT}
              isOpen={selectedNFT > 0}
              onClose={() => setselectedNFT(null)}
            /> */}
            <NFTGrid
              nftListData={nftFilteredListData}
              SetSelectedNFT={setselectedNFT}
            />
          </>
        </ExploreApp>
      </>

      {/* {nftListData.length > 0 && (
      )} */}
    </Section>
  );
};

export default ExploreNFTS;
