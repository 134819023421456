import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import React, { useLayoutEffect, useRef } from "react";
import Accordion from "components/Accordion";
import { Container } from "react-bootstrap";
import { Section, Title, Box } from "./styledTags";

const Faq = () => {
  const ref = useRef(null);
  gsap.registerPlugin(ScrollTrigger);
  useLayoutEffect(() => {
    let element = ref.current;

    ScrollTrigger.create({
      trigger: element,
      start: "bottom bottom",
      end: "bottom top",
      pin: true,
      pinSpacing: false,
      scrub: 1,
      // markers:true,
    });

    return () => {
      ScrollTrigger.killAll();
    };
  }, []);

  return (
    <Section ref={ref} id="faq">
      <Title>Faq</Title>

      <Container>
        <Box>
          <Accordion ScrollTrigger={ScrollTrigger} title="WHAT IS AN NFT?">
            An NFT, or Non-Fungible Token, is a unique digital asset that
            represents ownership or proof of authenticity of a specific item or
            piece of content, like artwork, music, or collectibles, using
            blockchain technology. NFTs are indivisible and cannot be exchanged
            on a one-to-one basis like cryptocurrencies such as Bitcoin or
            Ethereum.
          </Accordion>

          <Accordion
            ScrollTrigger={ScrollTrigger}
            title="WHAT MAKES P2P NFTS SPECIAL?
"
          >
            At the heart of our NFT platform is the unwavering commitment to
            empowering creators. We achieve this by securing full licenses from
            world-class creators and extending this ethos to our high-quality
            products. While blockchain relies on verification for trust, we
            recognize the significance of creators in shaping this trust. Our
            mission is to provide a platform where creators can confidently
            share their work, knowing that their art will be treated with the
            utmost respect and trust it deserves. We believe in empowering
            creators to showcase their artistry and establish a legacy of
            trusted, long-term products.
          </Accordion>

          <Accordion
            ScrollTrigger={ScrollTrigger}
            title="WHERE CAN I VIEW MY NFTS?"
          >
            Once minted or bought simply connect to your{" "}
            <a href="https://opensea.io/collection/lawrence-lustig-collection-by-p2p-art/overview">
              OpenSea account
            </a>{" "}
            to view your NFTs.
          </Accordion>
        </Box>
        {/* <Box>
          <Accordion
            ScrollTrigger={ScrollTrigger}
            title="HOW CAN I USE MY NFT?"
          >
            Trade: Within our ecosystem, you have the opportunity to trade your
            NFT assets 24/7 without any restrictions. This means you can buy,
            sell, or exchange your digital items on the open market at your
            convenience, giving you full control over your assets.
            <br />
            <br />
            Play: Owners of NFTs can actively engage in our trading card game.
            By using your digital tokens, you can challenge and compete against
            players from around the world. This feature adds a dynamic and
            interactive dimension to your NFT ownership, making it more than
            just a static digital collectible.
            <br />
            <br />
            Collect: For those who have a passion for collecting rare and unique
            items, our blockchain provides a platform to curate your digital
            collection. These NFTs offer a novel and lasting experience for
            collectors who appreciate the historical and artistic value of
            blockchain-based assets.
          </Accordion>
          <Accordion
            ScrollTrigger={ScrollTrigger}
            title="WHY DO CREATORS NEED NFT'S"
          >
            Blockchain technology, especially through NFTs, is transforming how
            creators validate their work online. It offers immutable
            verification, ownership control, global reach, decentralized
            platforms, diversification of art forms, and a cultural shift in
            recognizing digital art. This empowers artists with transparency,
            global reach, and fair compensation, reshaping the landscape for
            creative expression.
          </Accordion>
        </Box> */}
      </Container>
    </Section>
  );
};

export default Faq;
