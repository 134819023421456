import React from 'react';

import './TextFilter.scss';
import RadioButtonProvStart from '../RadioButtonProvStart/RadioButtonProvStart';

interface TextFilterProps {
	value: any;
	onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
	filters: any;
	onReset: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const TextFilter = ({value, onChange, filters, onReset}: TextFilterProps) => {
	return(
		<div className="filterBlock__textFilterWrap">
			<input
					type="text"
					placeholder="SEARCH"
					value={value}
					onChange={onChange}
					className="filterBlock__textFilter"
				/>
		<RadioButtonProvStart
          checked={filters.key}
          onChange={onReset}
        />

		</div>
	);
}

export default TextFilter;