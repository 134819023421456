import { ethers } from "ethers";
import { useInterval } from "Helpers/UseInterval";
import React, { useEffect, useState } from "react";
import { Toast } from "react-bootstrap";
import toast, { Toaster } from "react-hot-toast";
import { NFTComponent1 } from "./Components/NFTCard1";

import { NFTContainer, Section } from "./styledTags";
import WordShadow, { IWordShadowProps } from "components/WordShadow/WordShadow";
import RaffleHelper, { RaffleStateProps } from "Helpers/RaffleHelper";
import { IGBackgroundContainer } from "Views/Raffle/styledTags";
import IGBackground from "components/IGBackGround/IGBackground";
import "./Components/countdown.css";
import axios from "axios";

declare global {
  interface Window {
    ethereum: ethers.providers.ExternalProvider;
  }
}

const Raffle = () => {
  const [tokenFees, setTokenFees] = useState<{
    r1: string;
    r2: string;
    r3: string;
  }>(null);

  const [quantity, setQuantity] = useState<number>(null);

  // store user address in local state
  const [userAddress, setUserAddressValue] = useState<string>("");
  // store amount in local state
  const [amount, setAmountValue] = useState<number>(0);

  //store all Raffle information in these states
  //Need to update Active State of raffle
  //Need to update the Availability of the raffles
  const [raffleStates, setRaffleStates] = useState<{
    r1: RaffleStateProps;
    r2: RaffleStateProps;
    r3: RaffleStateProps;
  }>();

  const [raffle1CountdownTime, setRaffle1CountdownTime] = useState<{
    days;
    hours;
    minutes;
    seconds;
  }>();
  const [raffle2CountdownTime, setRaffle2CountdownTime] = useState<{
    days;
    hours;
    minutes;
    seconds;
  }>();
  const [raffle3CountdownTime, setRaffle3CountdownTime] = useState<{
    days;
    hours;
    minutes;
    seconds;
  }>();
  const [tokenCosts, setTokenCosts] = useState<{
    r1: number;
    r2: number;
    r3: number;
  }>({
    r1: 0,
    r2: 0,
    r3: 0,
  });

  const getCountdown = (target_date) => {
    var current_date = new Date().getTime();
    var seconds_left = (target_date - current_date) / 1000;

    var days = pad(parseInt(`${seconds_left / 86400}`));
    seconds_left = seconds_left % 86400;

    var hours = pad(parseInt(`${seconds_left / 3600}`));
    seconds_left = seconds_left % 3600;

    var minutes = pad(parseInt(`${seconds_left / 60}`));
    var seconds = pad(parseInt(`${seconds_left % 60}`));

    return {
      days,
      hours,
      minutes,
      seconds,
    };
  };

  const pad = (n) => {
    return (n < 10 ? "0" : "") + n;
  };

  //Need to keep track of the Active pool of Names

  //Need a bulk Buy option

  //Need a Buy Menthod
  const enterRaffle = async (raffleId: number) => {
    try {
      if (quantity) {
        //Greeting
        // await fetchGreeting();
        if (typeof window.ethereum !== "undefined") {
          // request account from metamask
          const [account] = await requestAccount();
          console.log("Account:", account);

          const provider = new ethers.providers.Web3Provider(window.ethereum);

          const signer = provider.getSigner();

          // signer.getAddress
          console.log("signer: addr", await signer.getAddress());

          console.log("signer:", signer);

          const contract = new RaffleHelper();

          console.log("Contract:", contract);

          const fees = []; //raffle prices

          // Get the number of raffles
          const raffleCount = 3; // Specify the number of raffles you have

          for (let raffleId = 0; raffleId < raffleCount; raffleId++) {
            const raffleState = await contract.getRaffleState(raffleId);
            const { price } = raffleState;
            fees[raffleId - 1] = price;
            console.log(`Raffle ${raffleId}: Fee = ${price.toString()} ETH`);
          }
          const transaction = await contract.buyRaffle(raffleId);

          console.log("transaction:", transaction);

          await transaction.wait();

          console.log("BuyRandom:", transaction);

          toast.success(`balance: ${transaction.confirmations}`);
        }
      } else {
        toast.error("Set the Quantity");
      }
    } catch (e: any) {
      console.log("error:", e);
      toast.error(e.data.message);
    }
  };

  //Need to update current Redeemable objects ??

  // request access to the user's MetaMask account
  const requestAccount = async () => {
    if (window.ethereum?.request)
      return window.ethereum.request({ method: "eth_requestAccounts" });

    throw new Error(
      "Missing install Metamask. Please access https://metamask.io/ to install extension on your browser"
    );
  };

  // call the smart contract, read the current greeting value
  const fetchGreeting = async () => {
    if (typeof window.ethereum !== "undefined") {
      toast.success(`Greeting: ${"hello"}`);

      toast.error(`fuck me `);
    }
  };

  const checkRaffleAvailability = async () => {
    const raffleHelper = new RaffleHelper();
    const raffle1State = await raffleHelper.getRaffleState(0);
    const raffle2State = await raffleHelper.getRaffleState(1);
    const raffle3State = await raffleHelper.getRaffleState(2);

    setRaffleStates({
      ...raffleStates,
      r1: raffle1State,
      r2: raffle2State,
      r3: raffle3State,
    });

    // var target_date = new Date().getTime() + 1000 * 3600 * 48;

    setRaffle1CountdownTime(getCountdown(raffle1State.deadline));
    setRaffle2CountdownTime(getCountdown(raffle2State.deadline));
    setRaffle3CountdownTime(getCountdown(raffle3State.deadline));

    const intervalId = setInterval(() => {
      setRaffle1CountdownTime(getCountdown(raffle1State.deadline));
      setRaffle2CountdownTime(getCountdown(raffle2State.deadline));
      setRaffle3CountdownTime(getCountdown(raffle3State.deadline));
    }, 1000);

    return () => clearInterval(intervalId); // cleanup on unmount
  };

  const convertGweiToPounds = async () => {
    // Get the current price of ETH in £
    const response = await axios.get(
      "https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=gbp"
    );
    const currentPriceOfETHinPounds = response.data.ethereum.gbp;

    // Convert the fee from ETH to £
    const t1feeInPounds =
      Number(raffleStates.r1.price) * currentPriceOfETHinPounds;
    const t2feeInPounds =
      Number(raffleStates.r2.price) * currentPriceOfETHinPounds;
    const t3feeInPounds =
      Number(raffleStates.r3.price) * currentPriceOfETHinPounds;

    // console.log("fee t1:", t1feeInPounds.toFixed(2));
    // console.log("fee t1:", t2feeInPounds.toFixed(2));
    // console.log("fee t1:", t3feeInPounds.toFixed(2));

    setTokenCosts({
      ...tokenCosts,
      r1: Number(t1feeInPounds.toFixed(2)),
      r2: Number(t2feeInPounds.toFixed(2)),
      r3: Number(t3feeInPounds.toFixed(2)),
      // t4: t4feeInPounds
    });
  };

  useInterval(() => {
    (async () => {
      await checkRaffleAvailability();
    })();
  }, 5000);

  useEffect(() => {
    //update on State change to keep Track of all States
    console.log("Raffle State:", raffleStates);

    if (!raffleStates) {
      (async () => {
        await checkRaffleAvailability();
      })();
    } else {
      (async () => {
        await convertGweiToPounds();
      })();
    }
  }, [raffleStates]);

  const wordShadows: IWordShadowProps[] = [
    {
      src: "https://cdn.discordapp.com/attachments/852529597303488553/1096889832875237476/L1-PhotoRoom.png-PhotoRoom.png",
      className: "l1",
    },
    {
      src: "https://cdn.discordapp.com/attachments/852529597303488553/1096889833160454214/L2-PhotoRoom.png-PhotoRoom.png",
      className: "l2",
    },
    {
      src: "https://cdn.discordapp.com/attachments/852529597303488553/1096889833919623219/x-PhotoRoom.png-PhotoRoom.png",
      className: "x",
    },
    {
      src: "https://cdn.discordapp.com/attachments/852529597303488553/1096889833395343580/P1-PhotoRoom.png-PhotoRoom.png",
      className: "p1",
    },
    {
      src: "https://cdn.discordapp.com/attachments/852529597303488553/1096889832598425720/2-PhotoRoom.png-PhotoRoom.png",
      className: "2",
    },
    {
      src: "https://cdn.discordapp.com/attachments/852529597303488553/1096889833659572234/P2-PhotoRoom.png-PhotoRoom.png",
      className: "p2",
    },
  ];

  return (
    <Section id="raffle">
      <IGBackgroundContainer>
        <IGBackground username="" />
      </IGBackgroundContainer>
      <WordShadow words={wordShadows} />

      {raffleStates &&
        raffleStates.r1 &&
        raffleStates.r2 &&
        raffleStates.r3 &&
        raffle1CountdownTime &&
        raffle2CountdownTime &&
        raffle3CountdownTime && (
          <NFTContainer>
            <div>
              <div id="countdown">
                <div className="labels">
                  {/* <li>Days</li> */}
                  <li>Hours</li>
                  <li>Mins</li>
                  <li>Secs</li>
                </div>
                <div id="tiles">
                  {/* <span>{raffle1CountdownTime.days}</span> */}
                  <span>{raffle1CountdownTime.hours}</span>
                  <span>{raffle1CountdownTime.minutes}</span>
                  <span>{raffle1CountdownTime.seconds}</span>
                </div>
              </div>
              <NFTComponent1
                className="card card-red"
                leftAvater={`${raffleStates.r1.size - raffleStates.r1.bought}`}
                leftHeader="Available"
                rightAvater={`${raffleStates.r1.bought}`}
                price={raffleStates.r1.price}
                fee={tokenCosts.r1}
                rightHeader="Brought"
                footer={`${raffleStates.r1.size}`}
                setQuantity={(e) => setQuantity(Number(e.target.value))}
                rightMethod={async () => {
                  await enterRaffle(0);
                }}
                raffleNames={raffleStates.r1.participants}
                container="raffle1"
                avaialability={
                  raffleStates.r1.startTime <= new Date() &&
                  raffleStates.r1.deadline > new Date()
                }
              />
            </div>
            <div>
              <div id="countdown">
                <div className="labels">
                  <li>Days</li>
                  <li>Hours</li>
                  <li>Mins</li>
                  <li>Secs</li>
                </div>
                <div id="tiles">
                  <span>{raffle2CountdownTime.days}</span>
                  <span>{raffle2CountdownTime.hours}</span>
                  <span>{raffle2CountdownTime.minutes}</span>
                  <span>{raffle2CountdownTime.seconds}</span>
                </div>
              </div>

              <NFTComponent1
                className="card2"
                leftAvater={`${raffleStates.r2.size - raffleStates.r2.bought}`}
                leftHeader="Available"
                rightAvater={`${raffleStates.r2.bought}`}
                price={raffleStates.r2.price}
                fee={tokenCosts.r2}
                rightHeader="Brought"
                footer={`${raffleStates.r2.size}`}
                setQuantity={(e) => setQuantity(Number(e.target.value))}
                rightMethod={async () => {
                  await enterRaffle(1);
                }}
                raffleNames={raffleStates.r2.participants}
                container="raffle2"
                avaialability={
                  raffleStates.r2.startTime <= new Date() &&
                  raffleStates.r2.deadline > new Date()
                }
              />
            </div>
            <div>
              {raffleStates.r3.startTime <= new Date() && (
                <div id="countdown">
                  <div className="labels">
                    <li>Days</li>
                    <li>Hours</li>
                    <li>Mins</li>
                    <li>Secs</li>
                  </div>
                  <div id="tiles">
                    <span>{raffle3CountdownTime.days}</span>
                    <span>{raffle3CountdownTime.hours}</span>
                    <span>{raffle3CountdownTime.minutes}</span>
                    <span>{raffle3CountdownTime.seconds}</span>
                  </div>
                </div>
              )}

              {raffleStates.r3.startTime >= new Date() && (
                <div style={{ width: "312px", height: "71px" }}></div>
              )}

              <NFTComponent1
                className="card-gold"
                leftAvater={`${raffleStates.r3.size - raffleStates.r3.bought}`}
                leftHeader="Available"
                rightAvater={`${raffleStates.r3.bought}`}
                price={raffleStates.r3.price}
                fee={tokenCosts.r3}
                rightHeader="Brought"
                footer={`${raffleStates.r3.size}`}
                setQuantity={(e) => setQuantity(Number(e.target.value))}
                rightMethod={async () => {
                  await enterRaffle(2);
                }}
                raffleNames={raffleStates.r3.participants}
                container="raffle3"
                avaialability={
                  raffleStates.r3.startTime <= new Date() &&
                  raffleStates.r3.deadline > new Date()
                }
              />
            </div>
          </NFTContainer>
        )}

      <Toaster position="top-right" reverseOrder={false} />
    </Section>
  );
};

export default Raffle;
