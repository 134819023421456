import React, { useEffect, useRef, useState } from "react";

import TagCloud from "TagCloud";
import { TagCloug } from "types/react-app-env";
import { Container } from "./styledTags";

const ETH = require("assets/icons8-ethereum-48.png");

const Sphere = ({ tags, container }) => {
  const tagCloud: TagCloug = TagCloud;

  const [sphereTags, setSphereTags] = useState<string[]>();

  function getRandomSelection(arr, count) {
    // Shallow copy the original array
    let tmpArr = [...arr];
    let result = [];

    for (let i = 0; i < count; i++) {
      if (tmpArr.length === 0) break;

      // Get a random index
      let index = Math.floor(Math.random() * tmpArr.length);

      // Add the selected item to our result array
      result.push(tmpArr[index]);

      // Remove the selected item from the tmp array
      tmpArr.splice(index, 1);
    }

    return result;
  }

  //To change the color of text randomly
  var colors = ["#34A853", "#FBBC05", "#4285F4", "#7FBC00", "FFBA01", "01A6F0"];
  var random_color = colors[Math.floor(Math.random() * colors.length)];
  // document.querySelector('.content').style.color = random_color;

  useEffect(() => {
    setSphereTags(getRandomSelection(tags, 5));
  }, []);

  useEffect(() => {
    // console.log("My Current Tags:", tags);
    // const target = document.querySelector('.content')
    const target = document.querySelector(`.${container}`);

    // console.log("container: ", container);
    // console.log("target: ", target);
    if (target && sphereTags && sphereTags.length > 0) {
      tagCloud(`.${container}`, sphereTags, {
        // radius in px
        radius: 150,

        // animation speed
        // slow, normal, fast
        maxSpeed: "fast",
        initSpeed: "fast",

        // 0 = top
        // 90 = left
        // 135 = right-bottom
        direction: 135,

        // interact with cursor move on mouse out
        keep: true,
        colors: colors,
      });
    }
  }, [sphereTags]);

  return <Container className={container}></Container>;
};

export default Sphere;
