import { GameSelectionProps } from "components/Game/SelectionPage";
import { GameModeEnum } from "../GameHome";
import { NFT } from "types/NFT/BaseContract";
import toast from "react-hot-toast";
import ChampionshipGif from "assets/Championship_Button.gif";
import StatAttack from "assets/Game/StatAttack.gif";
import CardWar from "assets/Game/Card_War.gif";
import PunchersChance from "assets/Game/PunchersChance.gif";

export const GameModeSelection = (
  setSelection: React.Dispatch<React.SetStateAction<GameModeEnum>>,
  gameCards: NFT[]
): GameSelectionProps => {
  return {
    gameProps: {
      notifications: [
        // {
        // notificationIcon: "https://emoji.redditmedia.com/vl6p2wgtkou41_t5_3h47q/kirby-star",
        //     onClick: (() => console.log("Im A Notification")),
        //     className: "glow"
        // },
      ],
      icons: [
        {
          icon: CardWar,
          iconName: GameModeEnum.CardWar.toString(),
          onClick: () => {
            if (!gameCards || gameCards.length < 1) {
              //notify user that they do not have required cards
              toast.error(`Warning: ${"1 NFT Required"}`);

              return;
            }
            console.log(`${GameModeEnum.CardWar.toString()} chosen`);
            setSelection(GameModeEnum.CardWar);
          },
          style: { borderRadius: "20px" },
          className: gameCards && gameCards.length >= 1 ? "" : "hidden",
        },
        {
          icon: StatAttack,
          iconName: GameModeEnum.StatAttack.toString(),
          onClick: () => {
            if (!gameCards || gameCards.length < 3) {
              //notify user that they do not have required cards
              toast.error(`Warning: ${"3 NFTS Required"}`);
              toast.success(`cards in m y deck: ${gameCards}`);

              return;
            }
            console.log(`${GameModeEnum.StatAttack.toString()} chosen`);
            setSelection(GameModeEnum.StatAttack);
          },
          style: { borderRadius: "20px" },
          className: gameCards && gameCards.length >= 3 ? "" : "hidden",
        },
        {
          icon: PunchersChance,
          iconName: GameModeEnum.PunchersChance.toString(),
          onClick: () => {
            if (!gameCards || gameCards.length < 3) {
              //notify user that they do not have required cards
              toast.success(`cards in m y deck: ${gameCards}`);
              toast.error(`Warning: ${"3 NFTS Required"}`);

              return;
            }
            console.log(`${GameModeEnum.PunchersChance.toString()} chosen`);
            setSelection(GameModeEnum.PunchersChance);
          },
          style: { borderRadius: "20px" },
          className: gameCards && gameCards.length >= 3 ? "" : "hidden",
        },
        {
          // icon: "https://www.shutterstock.com/image-vector/black-gold-elegant-sport-game-260nw-2011225937.jpg",
          icon: ChampionshipGif,
          iconName: GameModeEnum.Tournament.toString(),
          onClick: () => {
            if (!gameCards || gameCards.length < 1) {
              //notify user that they do not have required cards
              toast.error(`Warning: ${"1 NFTS Required"}`);

              return;
            }
            console.log(`${GameModeEnum.PunchersChance.toString()} chosen`);
            setSelection(GameModeEnum.Tournament);
          },
          style: { borderRadius: "20px" },
          className: gameCards && gameCards.length >= 1 ? "" : "hidden",
        },
      ],
      informations: [
        {
          infoIcon: "info",
          information: "something important",
          onClick: () => console.log("Information Settings"),
          className: "material-icons",
        },
        {
          infoIcon: "info",
          information: "something more important",
          onClick: () => console.log("Info Shop"),
          className: "material-icons-outlined yellow",
        },
      ],
    },
  };
};
