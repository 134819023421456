import "./trophyEmoji.css"

const TrophyEmoji = () => {
  return (
    <div id="trophy-emoji">
      {/* <div className="wrapper">
        <div className="glow"></div>
        <div className="mask">
          <div className="container">
            <div className="star">&#10022;</div>
            <div className="main"></div>
            <div className="stem1"></div>
            <div className="stemCrease"></div>
            <div className="stem2"></div>
            <div className="base"></div>
            <div className="arms"></div>
          </div>
        </div>
      </div> */}
    <div className="em-2x em_trophy"></div>
    </div>
  );
};

export default TrophyEmoji;
