import { Suspense } from "react";
import Loading from "components/Loading";
import {
  Container,
  Section,
  Title,
  Div1,
  Div2,
  Img,
  Div3,
  Div4,
  P,
} from "./styledTags";
import logo from "assets/Plus.svg";
import MyParticles from "./ParticlesComponent";

const Arron = require("assets/Team/Arron.jpeg");
const Tevin = require("assets/Team/Tevin.jpg");
const Jesal = require("assets/Team/Jesal.jpg");
const Lawerence = require("assets/Team/Lawrence_Camera.png");

const MemberComponent = ({ img, name = " ", position = " " }) => {
  return (
    <Div2>
      <Img src={img} alt="" />
      <Div3>
        <Div4>{name}</Div4>
        <P>{position}</P>
      </Div3>
    </Div2>
  );
};

const Team = () => {
  return (
    <Section id="team">
      <Suspense fallback={<Loading />}>
        <MyParticles img={logo} />
      </Suspense>
      <Title>Team</Title>
      <Container>
        <Div1>
          <MemberComponent img={Arron} name="Arron" position="Co-Founder" />
          <MemberComponent img={Tevin} name="Tevin" position="Co-Founder" />
          <MemberComponent
            img={Jesal}
            name="Jesal"
            position="Lead Technician"
          />
          <MemberComponent
            img={Lawerence}
            name="Lawrence Lustig"
            position="Artist"
          />
        </Div1>
      </Container>
    </Section>
  );
};

export default Team;
