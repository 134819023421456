import React, { useRef } from "react";
import "./Card.scss";
import {
  CardData,
} from "Views/ExploreGrid/variables/Array/CARD_INFO_ARRAY";
import { getAttrValue } from "Helpers/GetNFTS";
import { MetaDataInfo, NFT } from "types/NFT/BaseContract";

interface CardProps {
  product: NFT;
  onClick?: (product: NFT) => void;
}

const Card = ({ product, onClick }: CardProps) => {

  // console.log("CARD.PRODUCT:", product)
  const cardRef = useRef(null);

  const handleCardHover = () => {
    const cardElement = cardRef.current;
    if (cardElement) {
      const cardPosition = cardElement.offsetLeft;
      const cardWidth = cardElement.offsetWidth;
      const viewportWidth = window.innerWidth;
      const availableRightSpace = viewportWidth - (cardPosition + cardWidth);
      const tooltipWidth = 177; // The width you've set for the tooltip in CSS

      const tooltipElement = cardElement.querySelector(".tooltip");
      if (availableRightSpace < tooltipWidth && tooltipElement) {
        // Adjust the tooltip to show on the left
        // You can add a class or adjust an inline style to position the tooltip on the left
        // For example, using a class:
        tooltipElement.classList.add("left-positioned");
      } else {
        // Show the tooltip on the right (default behavior)
        // Ensure any left-positioning classes or styles are removed
        tooltipElement.classList.remove("left-positioned");
      }
    }
  };

  const factionClass = getAttrValue(product.ipfs, "type") ?? "random";

  //check that we have a tier in the MetaData otherwise infer from NFT
// if(getAttrValue(product.ipfs, "tier")){
//   switch (getAttrValue(product.ipfs, "tier")) {
//     case 1:
//       borderClass = "gold";
//       break;
//     case 2:
//       borderClass = "gold";
//       break;
//     case 3:
//       borderClass = "bronze";
//       break;
//     case 4:
//       borderClass = "bronze";

//       break;
//     default:
//       break;
//   }

// }else{
//   switch (product.tier) {
//     case 1:
//       borderClass = "gold";
//       break;
//     case 2:
//       borderClass = "gold";
//       break;
//     case 3:
//       borderClass = "bronze";
//       break;
//     case 4:
//       borderClass = "bronze";

//       break;
//     default:
//       break;
//   }

// }

  // console.log("product:", product);
  //Championships
  const c = (
    <p className="provisionBg__number">
      {getAttrValue(product.ipfs, "championships")}
    </p>
  );

  //ConsecutiveWins
  const cc = (
    <p className="provisionBg__number">
      {getAttrValue(product.ipfs, "consecutiveWins")}
    </p>
  );

  //WinLossRatio
  const wl = (
    <p className="provisionBg__number">
      {getAttrValue(product.ipfs, "WinLossRatio")}
    </p>
  );

  let championships: JSX.Element | null = (
    <div className="provisionBg c">{c}</div>
  );
  let consequtiveWins: JSX.Element | null = (
    <div className="provisionBg cc">{cc}</div>
  );
  let winLossRatio: JSX.Element | null = (
    <div className="provisionBg wl">{wl}</div>
  );

  let tierType;
  let borderClass = "";
  let tierColor;
  switch (product.tier) {
    case 1:
      borderClass = "gold";
      tierColor = "legendary";
      tierType = (
        <p className="tierBg__number">{product.tier}</p>
      );
      break;
    case 2:
      borderClass = "gold";
      tierColor = "epic";
      tierType = <div className="tierBg__t4"></div>;
      break;
    case 3:
      borderClass = "bronze";
      tierColor = "rare";
      tierType = <div className="tierBg__artifact"></div>;
      break;
    case 4:
      borderClass = "bronze";
      tierColor = "usual";
      tierType = <div className="tierBg__strategy"></div>;
      championships = null;
      consequtiveWins = null;
      winLossRatio = null;
      break;
    default:
      break;
  }

  //no Attributes against the playable Stats
  if(!getAttrValue(product.ipfs, "championships")){
    championships = null;
    consequtiveWins = null;
    winLossRatio = null;
  }

  const nameTooltip = <h1 className="tooltip__title">{product.ipfs.name}</h1>;

  return (
    <div
      className={`card_exploregrid _${factionClass} _${borderClass}`}
      onClick={() => (onClick != null ? onClick(product) : null)}
      ref={cardRef}
      onMouseEnter={handleCardHover}
    >
      <img src={product.ipfs.image} alt={product.ipfs.name} />

      <div className="borderBg"></div>
      <div className={`tierBg _${tierColor}`}>{tierType}</div>
      {championships}
      {consequtiveWins}
      {winLossRatio}

      <div className="tooltip">
        <div className="tooltip__titleWrap">{nameTooltip}</div>
        <div className="tooltip__infoWrap">
          <div className="tooltip__info">{CardData(product.ipfs)}</div>
        </div>
      </div>
    </div>
  );
};

export default Card;
