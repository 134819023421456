import React, { lazy, Suspense } from "react";
// import CoverVideo from '../CoverVideo'
// import TypeWriterText from '../TypeWriterText'
import Loading from "components/Loading";
import Showcase from "components/ShowCase/Showcase";
import { Container, Section, Box, Round, Circle } from "./styledTags";
import GameHomeSlideShow from "components/SlideShow/HomePageSlideShow";
import Promo from "assets/Promo.gif";
import P2PGIF from "assets/P2P_x_LL.gif";
import CardSelection from "Views/Game/components/cardSelection/CardSelection";
import { GameModeEnum } from "Views/Game/components/GameHome";
import Timeline from "components/Timeline/TimeLine";
const RoundTextBlack = require("assets/Rounded-Text-Black.png");

const CoverVideo = lazy(() => import("components/CoverVideo"));
const TypeWriterText = lazy(() => import("components/TypeWriterText"));

const Home = () => {
  return (
    <>
      <Section id="home">
        <Container>
          <img
            src={P2PGIF}
            style={{
              minHeight: "100vh",
              width: "100vw",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              position: "relative",
              overflow: "hidden",
              visibility: "visible",
            }}
          />
        </Container>
        {/* <GameHomeSlideShow /> */}
      </Section>
    </>
  );
};

export default Home;
