import "./tape.css";
import { CardDisintigrate } from "./GameArenaView";
import TapeLoader from "../gameCards/TapeLoader";
import { useEffect, useState } from "react";
import CardLoader from "../gameCards/CardLoader";

type TapeProps = {
  nfts: CardDisintigrate[];
  onSelectedTape: (nft: CardDisintigrate, index) => void;
  onSelectedWin: (nft: CardDisintigrate, index) => void;
  nftsWon: CardDisintigrate[];
};
const Tape = ({ nfts, onSelectedTape, nftsWon }: TapeProps) => {
  const [myNFTs, setMyNFTs] = useState(nfts);

  const handleSelectedTape = (nft: CardDisintigrate, index) => {
    //reset chosen nft
    const updatedNFTs = myNFTs.map((nftItem, idx) => ({
      ...nftItem,
      isChosen: idx === index, // Set isChosen to true for the clicked NFT, false for others
    }));

    setMyNFTs(updatedNFTs);
    if (nft.isChosen) return;
    // nft.isChosen = true;
    onSelectedTape(nft, index);
  };

  const handleWonNFTS = (nft: CardDisintigrate, index) => {
    //MAP winned cards to the state
    //delay this soo blocks loader can appear
    //return mapped nfts
  };

  useEffect(() => {
    console.log("my nfts:", myNFTs);
  }, [myNFTs]);

  return (
    <div id="tape">
      <section className="screenshot-wrapper">
        <ul className="screenshot-container">
          {myNFTs &&
            myNFTs.map(({ nft, isChosen }, index) => {
              if (isChosen) {
                // <CardLoader />
                return (
                  <li key={nft.tokenId} className="screenshot">
                      <TapeLoader />
                  </li>
                );
              } else {
                return (
                  <li
                    key={nft.tokenId}
                    className="screenshot"
                    onClick={() => handleSelectedTape(nfts[index], index)}
                  >
                    <div className="image-container">
                      <img src={nft.ipfs.image} />
                      <button>x</button>
                    </div>
                  </li>
                );
              }
            })}
        </ul>
      </section>
    </div>
  );
};

export default Tape;
