import React, { useState, useCallback, useEffect } from "react";
import SearchBar from "./SearchBar/SearchBar";
import TopFilter from "./TopFilter/TopFilter";
import DeckBar from "./DeckBar/DeckBar";
import CardWrap from "./CardWrap/CardWrap";

import "./Constructor.scss";
import { getMetaDataFilters } from "Helpers/GetNFTS";
import { MetaDataInfo, NFT } from "types/NFT/BaseContract";

interface ConstructorProps {
  cardsInfo: NFT[];
}

export type metaDataFilterProps = {
  key: string;
  // type: any;
  //used to extend the metadata per collection
  //we capture the types in a switch on the cardRendering level
  value: any;
};
const Constructor = ({ cardsInfo }: ConstructorProps) => {
  const [filterText, setFilterText] = useState("");
  const [tokens, setTokens] = useState<NFT[]>(cardsInfo);

  const [inUsual, setInUsual] = useState(false);
  const [inRare, setInRare] = useState(false);
  const [inEpic, setInEpic] = useState(false);
  const [inLegendary, setInLegendary] = useState(false);
  const [inType, setInType] = useState("");

  const [metaDataFilters, setMetaDataFilters] = useState<string[]>([]);
  const [filterProps, setFilterProps] = useState<metaDataFilterProps>({
    key: "name",
    value: "",
  });
  const [inFaction, setInFaction] = useState("");
  const [inBorder, setInBorder] = useState("");
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleDropdown = useCallback(() => {
    setMenuOpen((prevMenuOpen) => !prevMenuOpen);
  }, []);

  const mocVar = menuOpen ? "open" : "close";

  useEffect(() => {
    //check if There are any Tokens
    if(tokens.length === 0){
      setTokens(cardsInfo);
      setMetaDataFilters([...getMetaDataFilters(cardsInfo.map(x => x.ipfs))]);

    }else{
      console.log("my tokens:", tokens)
      setMetaDataFilters([...getMetaDataFilters(tokens.map(x => x.ipfs))]);
    }

    // setMetaDataFilters([...getMetaDataFilters(tokens)]);
  }, [tokens]);

  return (
    <div className="constructor">
      <SearchBar
        onTokensChange={setTokens}
        onInUsualChange={setInUsual}
        onInRareChange={setInRare}
        onInEpicChange={setInEpic}
        onInLegendaryChange={setInLegendary}
        onInFractionChange={setInFaction}
        onInBorderChange={setInBorder}
        onInTypeChange={setInType}
        inUsualOnly={inUsual}
        inRareOnly={inRare}
        inEpicOnly={inEpic}
        inLegendaryOnly={inLegendary}
        inFactionOnly={inFaction}
        inTypeOnly={inType}
        inBorderOnly={inBorder}
        moc={mocVar}
      />

      <div className="cardListWrap">
        <div className="cardListWrap__hinge"></div>
        <div className="cardListWrap__hinge"></div>
        <div className="cardListWrap__hinge"></div>
        <div className="cardListWrap__hinge"></div>

        <TopFilter
          filterText={filterText}
          metaDataFilters={metaDataFilters}
          onFilterTextChange={setFilterText}
          onFiltersChange={setFilterProps}
          onSelectMenu={toggleDropdown}
          onSelectMenuClass={mocVar}
          filters={filterProps}
        />
        <CardWrap
          cardsInfo={tokens}
          filterText={filterText}
          metaDataFilters={filterProps}
          inFaction={inFaction}
          inBorder={inBorder}
          inType={inType}
        />
      </div>

      {/* May not need this is the right side bar */}
      <DeckBar />
    </div>
  );
};

export default Constructor;
