import { GameSelectionProps } from "components/Game/SelectionPage";
import { GameTypeEnum } from "../GameHome";
import PVP from "assets/Game/PVP.gif";
import PVC from "assets/Game/PVC.gif";
// import { NavigateFunction } from "react-router";

export const GameTypeSelection = (
  setSelection: React.Dispatch<React.SetStateAction<GameTypeEnum>>,
  // navigate: NavigateFunction,
): GameSelectionProps => {
  return {
    gameProps: {
      notifications: [
        //Notifications are placed here
      ],
      icons: [
        {
          icon: PVP,
          iconName: GameTypeEnum.PVP.toString(),
          onClick: () => {
            console.log("PVP chosen");
            setSelection(GameTypeEnum.PVP);
          },
          style: { borderRadius: "20px" },
          className: "",
        },
        {
          icon: PVC,
          iconName: GameTypeEnum.PVE.toString(),
          onClick: () => {
            console.log("PVE chosen");
            setSelection(GameTypeEnum.PVE);
          },
          style: { borderRadius: "20px" },
          className: "",
        },
      ],
      informations: [
        {
          infoIcon: "info",
          information: "something important",
          onClick: () => console.log("Information Settings"),
          className: "material-icons",
        },
        {
          infoIcon: "info",
          information: "something more important",
          onClick: () => console.log("Info Shop"),
          className: "material-icons-outlined yellow",
        },
      ],
    },
  };
};
