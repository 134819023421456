import React from "react";

import "./DeckBar.scss";
import DeckCollection from "./DeckItem/DeckItem";

interface DeckBarProps {}

const DeckBar = ({}: DeckBarProps) => {
  return (
    <div className="deckListWrap">
      <div className="contentWrap">
        <DeckCollection
          bgName={"lawrenceLustig"}
          iconName={"deathShadow"}
          collectionName={"Lawrence Lustig"}
          symbol={"LL"}
        />
      </div>
    </div>
  );
};

export default DeckBar;
