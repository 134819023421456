import styled from "styled-components"

export const   Section = styled.section`
min-height: ${props => `calc(100vh - ${props.theme.navHeight})`   };
width: 100vw;
// position: relative;
// background-color: ${props => props.theme.body};

// position: absolute;
// z-index: 1;
// opacity: 0.7;

`

export const  Container = styled.div`
width: 75%;
min-height: 20vh;
margin: 0 auto;
/* background-color: lightblue; */

display: flex;
justify-content: center;
align-items: center;

@media (max-width: 64em) {
  width: 85%;
}
@media (max-width: 48em) {
  flex-direction: column-reverse;
  width: 100%;
  &>*:first-child{
    width: 100%;
    margin-top: 2rem;
  }
}
`
export const NFTContainer = styled.div`
width: 75%;
margin: 2rem auto;

display: flex;
justify-content: space-between;
align-items: center;
flex-wrap: wrap;

@media (max-width: 64em){
justify-content: center;
width: 80%;
}
@media (max-width: 48em){
width: 90%;
justify-content: center;
}
`

export const IGBackgroundContainer = styled.div`
  position: absolute;
  opacity: 70%;

  min-height: ${props => `calc(100vh - ${props.theme.navHeight})`   };  width: 100vw;
  // background-color: ${props => props.theme.text};
  display: flex;
  // flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`