import React, { useEffect, useState } from "react";

export type TimelineItemProps = {
  point: string;
  year: string;
  season: string;
  imgSrc: string;
  title: string;
  description: string;
};

const TimelineItem: React.FC<TimelineItemProps> = ({
  point,
  year,
  season,
  imgSrc,
  title,
  description,
}) => {
  const [isFlipped, setIsFlipped] = useState(false);

  const handleClick = () => {
    setIsFlipped(!isFlipped);
  };

  return (
    <div className={`js-timeline_item ag-timeline_item`} onClick={handleClick}>
      <div className="ag-timeline-card_box">
        <div className="js-timeline-card_point-box ag-timeline-card_point-box">
          <div className="ag-timeline-card_point">{year}</div>
        </div>
        <div className="ag-timeline-card_meta-box">
          <div className="ag-timeline-card_meta">{season}</div>
        </div>
      </div>
      <div className="ag-timeline-card_item">
        <div className="ag-timeline-card_inner">
          {
            isFlipped ? (
              <div className="ag-timeline-card_description">
                <p>{description}</p>
              </div>
            ) : (
              <div className="ag-timeline-card_img-box">
                <img
                  src={imgSrc}
                  className="ag-timeline-card_img"
                  width="640"
                  height="360"
                  alt=""
                />
            </div>
            )
          }
          <div className="ag-timeline-card_info">
            <div className="ag-timeline-card_title">{title}</div>
            {/* <div className="ag-timeline-card_desc">{point}</div> */}
            <div className="ag-timeline-card_desc">Click to view {isFlipped ? "Content" : "Info"}</div>
          </div>
        </div>
        <div className="ag-timeline-card_arrow"></div>
      </div>
    </div>
  );
};

export default TimelineItem;
