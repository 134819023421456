import GlobalStyles from "./styles/GlobalStyles";
import { light } from "./styles/Themes";
import { ThemeProvider } from "styled-components";
import Navigation from "./components/Navigation/Navigation";
import Main from "Views/Main/Main";
import awsmobile from "./aws-exports";
// import { Amplify } from "aws-amplify";

// (Amplify as any).configure(awsmobile);

const App = () => {
  return (
    <main>
      <GlobalStyles />
      <ThemeProvider theme={light}>
        <Navigation />
        <Main />
      </ThemeProvider>
    </main>
  );
};

export default App;
