export default class NFTInfo {
  tokenId?: number;
  uriHash: string;
  tier: number;
  collection: number;
  consecutiveWins: number;
  championships: number;
  winLossRatio: number;
  available?: boolean = true;

  constructor(
    uriHash: string,
    tier: number,
    collection: number,
    consecutiveWins: number,
    championships: number,
    winLossRatio: number,
    available?: boolean
  ) {
    this.uriHash = uriHash;
    this.tier = tier;
    this.collection = collection;
    this.consecutiveWins = consecutiveWins;
    this.championships = championships;
    this.winLossRatio = winLossRatio;
    if (available !== undefined) {
      this.available = available;
    }
  }
}

export interface MintParams {
  tierQuantities: number[];
  amount: number;
}

  // Example u sage:
  //let nft = new NFTInfo("hash", 1, 1, 0, 0, 0);
  