import React from 'react';
import "./tapeLoader.css"
const TapeLoader: React.FC = () => {
  return (
    <div id="tapeLoader" className="container">
      <div className="holder">
        <div className="box"></div>
      </div>
      <div className="holder">
        <div className="box"></div>
      </div>
      <div className="holder">
        <div className="box"></div>
      </div>
    </div>
  );
};

export default TapeLoader;
