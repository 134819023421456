import { ExploreNFT } from "types/react-app-env";
import { ImportAll } from "./ImportData";
import {
  MetaDataAttributeValueProps,
  MetaDataInfo,
} from "types/NFT/BaseContract";
import tier4Metadata from "assets/Nfts/Metadata/tier4Metadata.json";

export const getNFTS = () => {
  const tier1 = ImportAll(
    require.context("assets/Nfts/Tier1", false, /\.(png|jpe?g|svg|jpg|gif)$/)
  );

  const tier2 = ImportAll(
    require.context("assets/Nfts/Tier2", false, /\.(gif)$/)
  );

  const tier3 = ImportAll(
    require.context("assets/Nfts/Tier3", false, /\.(png|jpe?g|svg|jpg|gif)$/)
  );

  const tier4 = ImportAll(
    require.context("assets/Nfts/Tier4", false, /\.(png|jpe?g|svg|jpg|gif)$/)
  );

  console.log("tier1:", tier1);
  console.log("tier2:", tier2);
  console.log("tier3:", tier3);
  console.log("tier4:", tier4);
  const myNFTs: ExploreNFT[] = [];
  let nftId = 1;

  tier1.forEach((img, id) => {
    myNFTs.push({
      id: id++,
      NFT: img,
      author: "Lawrence Lustig",
      name: img.split("/static/media/")[1].split(".")[0],
      tier: 1,
      collection: "Lawrence Lustig",
      description: "template",
      collectionType: "Boxing",
      winLossRatio: Math.floor(Math.random() * 10),
      championships: Math.floor(Math.random() * 10),
      consecutiveWins: Math.floor(Math.random() * 10),
    });
    nftId = id++;
  });
  tier2.forEach((img, id) => {
    myNFTs.push({
      id: nftId,
      NFT: img,
      author: "Lawrence Lustig",
      name: img.split("/static/media/")[1].split(".")[0],
      tier: 2,
      collection: "Lawerence Lustig",
      description: "template",
      collectionType: "Boxing",
      winLossRatio: Math.floor(Math.random() * 10),
      championships: Math.floor(Math.random() * 10),
      consecutiveWins: Math.floor(Math.random() * 10),
    });
    nftId += 1;
  });
  tier3.forEach((img, id) => {
    myNFTs.push({
      id: nftId,
      NFT: img,
      author: "Lawrence Lustig",
      name: img.split("/static/media/")[1].split(".")[0],
      tier: 3,
      collection: "Lawerence Lustig",
      description: "template",
      collectionType: "Boxing",
      winLossRatio: Math.floor(Math.random() * 10),
      championships: Math.floor(Math.random() * 10),
      consecutiveWins: Math.floor(Math.random() * 10),
    });
    nftId += 1;
  });
  tier4.forEach((img, id) => {
    const metadata = tier4Metadata.find(md => md.tokenID === nftId);

    myNFTs.push({
      id: nftId,
      NFT: img,
      author: "Lawrence Lustig",
      name: img.split("/static/media/")[1].split(".")[0],
      tier: 4,
      collection: "Lawrence Lustig",
      description: metadata.description,
      collectionType: "Boxing",
      winLossRatio: null,
      championships: null,
      consecutiveWins: null,
    });
    nftId += 1;
  });

  return myNFTs;
};

/* any other unwanted keys */
const BLACKLIST = new Set(["image", "description"]);

export const getMetaDataFilters = (cardsInfo: MetaDataInfo[]) => {
  const metaDataKeys: Set<string> = new Set();
  //new obj
  const ipfs = cardsInfo.map((x) => {
    return { attributes: flattenAttributes(x) };
  });
  for (const item of ipfs) {
    for (const key in item.attributes) {
      if (!BLACKLIST.has(key)) {
        // Check against the blacklist
        metaDataKeys.add(key);
      }
    }
  }

  return metaDataKeys;
};

//Extract Attribute from metaData
export const getAttrValue = (
  metaData: MetaDataInfo,
  traitType: string
): MetaDataAttributeValueProps | undefined => {
  const attributeType = metaData.attributes?.find(
    (attr) =>
      attr.trait_type.toLocaleLowerCase() === traitType.toLocaleLowerCase()
  );

  //   console.log("Helper.getNFTS.DATA:", metaData);
  //   console.log("Helper.getNFTS.TRAITtYPE:", attributeType);
  return attributeType?.value;
};

// Helper function to flatten attributes
export const flattenAttributes = (
  data: MetaDataInfo
): Record<string, MetaDataAttributeValueProps> => {
  const result: Record<string, MetaDataAttributeValueProps> = {
    //   name: data.name,
    //   image: data.image
  };

  if (data.name) {
    result.name = data.name;
  }
  if (data.image) {
    result.image = data.image;
  }
  if (data.description) {
    result.description = data.description;
  }

  if (data.attributes) {
    data.attributes.forEach((attr) => {
      result[attr.trait_type] = attr.value;
    });
  }

  return result;
};

export const extractValueFromProduct = (
  product: MetaDataInfo,
  filterKey: string
): MetaDataAttributeValueProps | undefined => {
  // Check if the property exists directly on the product
  if (product.hasOwnProperty(filterKey)) {
    return (product as any)[filterKey];
  }

  // If not, check within the attributes
  const attribute = product.attributes?.find(
    (attr) => attr.trait_type === filterKey
  );
  return attribute?.value;
};
