import React, { useEffect, useRef, useState } from 'react'

import img1 from 'assets/Nfts/Collactables/collacatable.jpg';
import img2 from 'assets/Nfts/Collactables/collacatable.jpg';
import img3 from 'assets/Nfts/Collactables/collacatable.jpg';
import img4 from 'assets/Nfts/Collactables/collacatable.jpg';
import img5 from 'assets/Nfts/Collactables/collacatable.jpg';
import img6 from 'assets/Nfts/Collactables/collacatable.jpg';
import img7 from 'assets/Nfts/Collactables/collacatable.jpg';
import img8 from 'assets/Nfts/Collactables/collacatable.jpg';
import img9 from 'assets/Nfts/Collactables/collacatable.jpg';
import img10 from 'assets/Nfts/Collactables/collacatable.jpg';
import img11 from 'assets/Nfts/Collactables/collacatable.jpg';

import { Details, ImgContainer, Price, Row, Section } from './styledTags';
import { NFT } from 'types/react-app-env';

const ETH = require('assets/icons8-ethereum-48.png');


const NftItem = ({ img, number = 0, price = 0, passRef, collection = null }) => {

  let play = (e) => {
    passRef.current.style.animationPlayState = 'running';
  }
  let pause = (e) => {
    passRef.current.style.animationPlayState = 'paused';
  }


  return (
    <ImgContainer onMouseOver={e => pause(e)} onMouseOut={e => play(e)}  >
      <img width={500} height={"auto"} src={img} alt="P2P.art" />
      <Details>
        <div>
          <span> {collection ?? "P2P.art"}</span> <br />
          <h1>#{number}</h1>
        </div>

        <div>
          <span>Price</span>
          <Price>
            <img width={200} height={200} src={ETH} alt="ETH" />
            <h1>{Number(price).toFixed(1)}</h1>
          </Price>
        </div>
      </Details>
    </ImgContainer>
  )
}


const Showcase = () => {

  const [tier1, setTier1] = useState<NFT[]>(null);
  const [tier2, setTier2] = useState<NFT[]>(null);
  const [tier3, setTier3] = useState<NFT[]>(null);
  const [tier4, setTier4] = useState<NFT[]>(null);

  const [isloading, setIsLoading] = useState(true);

  const Row1Ref = useRef(null);
  const Row2Ref = useRef(null);
  const Row3Ref = useRef(null);
  const Row4Ref = useRef(null);

  useEffect(() => {
      //grab data from db
      const tier1Data: NFT[] = [{ number: 1, src: img1, price: 1.2 }, { number: 2, src: img2, price: 1.9 }, { number: 3, src: img3, price: 3.4 }, { number: 4, src: img4, price: 4.4 }, { number: 5, src: img5, price: 1.5 },];

      const tier2Data: NFT[] = [{ number: 1, src: img6, price: 1.2 }, { number: 2, src: img7, price: 1.9 }, { number: 3, src: img8, price: 3.4 }, { number: 4, src: img9, price: 4.4 }, { number: 5, src: img10, price: 1.5 },];

      const tier3Data: NFT[] = [{ number: 1, src: img11, price: 1.2 }, { number: 2, src: img7, price: 1.9 }, { number: 3, src: img8, price: 3.4 }, { number: 4, src: img9, price: 4.4 }, { number: 5, src: img10, price: 1.5 },];

      const tier4Data: NFT[] = [{ number: 1, src: img6, price: 1.2 }, { number: 2, src: img7, price: 1.9 }, { number: 3, src: img8, price: 3.4 }, { number: 4, src: img9, price: 4.4 }, { number: 5, src: img10, price: 1.5 },];

      if (tier1 == null) setTier1(tier1Data);
      if (tier2 == null) setTier2(tier2Data);
      if (tier3 == null) setTier3(tier3Data);
      if (tier4 == null) setTier4(tier4Data);

      // console.log("shouwcase: ", tier1, '\n', tier2, '\n', tier3, '\n', tier4)

    if (tier1 && tier2 && tier3 && tier4) setIsLoading(false);

  }, [isloading, tier1, tier2, tier3, tier4]);

  return (
    <Section id="showcase">
      {isloading ?
        <>
          <div>Loading ...</div>
        </>

        :

        <>
          {/* {Tier 1} */}
          <Row dir="none" ref={Row1Ref}>
            {
              tier1 && tier1.map((nft, index) => (
                <NftItem img={nft.src} key={index} number={nft.number} price={nft.price} passRef={Row1Ref} />

              ))
            }
          </Row>

          {/* {Tier 2} */}
          <Row dir="reverse" ref={Row2Ref}>
            {
              tier2 && tier2.map((nft, index) => (
                <NftItem img={nft.src} key={index} number={nft.number} price={nft.price} passRef={Row2Ref} />

              ))
            }
          </Row>

          {/* {Tier 3} */}
          <Row dir="none" ref={Row3Ref}>
            {
              tier3 && tier3.map((nft, index) => (
                <NftItem img={nft.src} key={index} number={nft.number} price={nft.price} passRef={Row3Ref} />

              ))
            }
          </Row>

          {/* {Tier 4} */}
          <Row dir="none" ref={Row4Ref}>
            {
              tier4 && tier4.map((nft, index) => (
                <NftItem img={nft.src} key={index} number={nft.number} price={nft.price} passRef={Row4Ref} />

              ))
            }
          </Row>
        </>
      }


    </Section>
  )
}

export default Showcase