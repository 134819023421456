type SapphireProps = {
  tier: number;
};

const Sapphire = ({ tier }: SapphireProps) => {
  // return (
  //   <div id="gem" className="container">
  //     <div className="sapphire">
  //       <div className="shape">
  //         <div className="shape top-left"></div>
  //         <div className="shape top-right"></div>
  //         <div className="shape left"></div>
  //         <div className="shape right"></div>
  //         <div className="shape bottom-left"></div>
  //         <div className="shape bottom-right"></div>
  //       </div>
  //       <div className="hexagon">
  //         <div className="hexagon top"></div>
  //         <div className="hexagon middle"></div>
  //         <div className="hexagon bottom"></div>
  //       </div>
  //     </div>
  //   </div>
  // );

  return (
    <div id="container">
      <div id="background-bubble"></div>
      <div id="prompt">{tier}</div>
      <div id="svg-wrapper">
        {tier === 1 && (
          <svg
            id="svg-gem"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
          >
            <path
              fill="rgb(220,220,220)"
              d="M256 0L34.297 128v256L256 512l221.703-128V128z"
            ></path>
            <path fill="rgb(255, 255, 255)" d="M256 256V0L34.297 128z"></path>
            <path d="M256 256z" fill="rgb(220,220,220)"></path>
            <path fill="rgb(192,192,192)" d="M34.297 128v256L256 256z"></path>
            <path
              fill="rgb(245,245,245)"
              d="M477.703 128L256 256l221.703 128z"
            ></path>
            <path
              fill="rgb(128,128,128)"
              d="M256 256L34.297 384 256 512z"
            ></path>
            <path
              fill="rgb(169,169,169)"
              d="M256 512l221.703-128L256 256z"
            ></path>
            <path
              fill="#C0C0C0"
              d="M381.121 328.238V183.762L256 111.523l-125.121 72.239v144.476L256 400.477z"
            ></path>
            <path
              fill="rgb(211,211,211)"
              d="M381.121 328.238V183.762L256 111.523v288.954z"
            ></path>
          </svg>
        )}
        {tier === 2 && (
          <svg
            id="svg-gem"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
          >
            <path
              fill="rgb(0,255,0)"
              d="M256 0L34.297 128v256L256 512l221.703-128V128z"
            ></path>
            <path fill="rgb(50,205,50)" d="M256 256V0L34.297 128z"></path>
            <path d="M256 256z" fill="rgb(220,220,220)"></path>
            <path fill="gb(34,139,34)" d="M34.297 128v256L256 256z"></path>
            <path
              fill="rgb(34,139,34)"
              d="M477.703 128L256 256l221.703 128z"
            ></path>
            <path fill="rgb(85,107,47)" d="M256 256L34.297 384 256 512z"></path>
            <path fill="rgb(0,100,0)" d="M256 512l221.703-128L256 256z"></path>
            <path
              fill="rgb(0,128,0)"
              d="M381.121 328.238V183.762L256 111.523l-125.121 72.239v144.476L256 400.477z"
            ></path>
            <path
              fill="rgb(0,128,0)"
              d="M381.121 328.238V183.762L256 111.523v288.954z"
            ></path>
          </svg>
        )}
        {tier === 3 && (
          <svg
            id="svg-gem"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
          >
            <path
              fill={"#82d2ff"}
              d="M256 0L34.297 128v256L256 512l221.703-128V128z"
            />
            <path fill="#08b7fc" d="M256 256V0L34.297 128z" />
            <path d="M256 256z" fill="#82d2ff" />
            <path fill="#006dff" d="M34.297 128v256L256 256z" />
            <path fill="#08b7fc" d="M477.703 128L256 256l221.703 128z" />
            <path fill="#0050c0" d="M256 256L34.297 384 256 512z" />
            <path fill="#006dff" d="M256 512l221.703-128L256 256z" />
            <path
              fill="#82d2ff"
              d="M381.121 328.238V183.762L256 111.523l-125.121 72.239v144.476L256 400.477z"
            />
            <path
              fill="#55cbff"
              d="M381.121 328.238V183.762L256 111.523v288.954z"
            />
          </svg>
        )}

        {tier === 4 && (
          <svg
            id="svg-gem"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
          >
            <path
              fill="rgb(220,20,60)"
              d="M256 0L34.297 128v256L256 512l221.703-128V128z"
            ></path>
            <path fill="rgb(205,92,92)" d="M256 256V0L34.297 128z"></path>
            <path d="M256 256z" fill="rgb(220,220,220)"></path>
            <path fill="rgb(220,20,60)" d="M34.297 128v256L256 256z"></path>
            <path
              fill="rgb(255,0,0)"
              d="M477.703 128L256 256l221.703 128z"
            ></path>
            <path fill="rgb(139,0,0)" d="M256 256L34.297 384 256 512z"></path>
            <path
              fill="rgb(178,34,34)"
              d="M256 512l221.703-128L256 256z"
            ></path>
            <path
              fill="rgb(255,0,0)"
              d="M381.121 328.238V183.762L256 111.523l-125.121 72.239v144.476L256 400.477z"
            ></path>
            <path
              fill="rgb(255,0,0)"
              d="M381.121 328.238V183.762L256 111.523v288.954z"
            ></path>
          </svg>
        )}
        <svg
          id="svg-gem-sparkle"
          viewBox="0 0 347 367"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M341.478 177.585c-11.078-2.011-21.81-4.751-31.899-8.144-73.954-24.87-115.76-85.293-129.515-163.078-1.306-7.369-11.936-7.37-13.242 0C150.836 96.749 97.632 160.668 5.867 177.7c-7.298 1.354-7.296 11.871.004 13.222 90.918 16.817 145.053 80.615 160.984 170.28 1.003 5.649 6.968 5.678 6.628 5.547a6.725 6.725 0 0 0 6.614-5.561c8.111-46.239 30.883-109.059 93.316-145.737 19.945-11.706 42.851-19.994 68.08-24.637a6.723 6.723 0 0 0-.015-13.229z"
            fill="#ffffff"
            fillRule="nonzero"
          />
        </svg>
        <svg
          id="svg-gem-sparkle-2"
          viewBox="0 0 347 367"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M341.478 177.585c-11.078-2.011-21.81-4.751-31.899-8.144-73.954-24.87-115.76-85.293-129.515-163.078-1.306-7.369-11.936-7.37-13.242 0C150.836 96.749 97.632 160.668 5.867 177.7c-7.298 1.354-7.296 11.871.004 13.222 90.918 16.817 145.053 80.615 160.984 170.28 1.003 5.649 6.968 5.678 6.628 5.547a6.725 6.725 0 0 0 6.614-5.561c8.111-46.239 30.883-109.059 93.316-145.737 19.945-11.706 42.851-19.994 68.08-24.637a6.723 6.723 0 0 0-.015-13.229z"
            fill="#ffffff"
            fillRule="nonzero"
          />
        </svg>
        <svg
          id="svg-gem-sparkle-3"
          viewBox="0 0 347 367"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M341.478 177.585c-11.078-2.011-21.81-4.751-31.899-8.144-73.954-24.87-115.76-85.293-129.515-163.078-1.306-7.369-11.936-7.37-13.242 0C150.836 96.749 97.632 160.668 5.867 177.7c-7.298 1.354-7.296 11.871.004 13.222 90.918 16.817 145.053 80.615 160.984 170.28 1.003 5.649 6.968 5.678 6.628 5.547a6.725 6.725 0 0 0 6.614-5.561c8.111-46.239 30.883-109.059 93.316-145.737 19.945-11.706 42.851-19.994 68.08-24.637a6.723 6.723 0 0 0-.015-13.229z"
            fill="#ffffff"
            fillRule="nonzero"
          ></path>
          <svg
            id="svg-gem-sparkle-2"
            viewBox="0 0 347 367"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M341.478 177.585c-11.078-2.011-21.81-4.751-31.899-8.144-73.954-24.87-115.76-85.293-129.515-163.078-1.306-7.369-11.936-7.37-13.242 0C150.836 96.749 97.632 160.668 5.867 177.7c-7.298 1.354-7.296 11.871.004 13.222 90.918 16.817 145.053 80.615 160.984 170.28 1.003 5.649 6.968 5.678 6.628 5.547a6.725 6.725 0 0 0 6.614-5.561c8.111-46.239 30.883-109.059 93.316-145.737 19.945-11.706 42.851-19.994 68.08-24.637a6.723 6.723 0 0 0-.015-13.229z"
              fill="#ffffff"
              fillRule="nonzero"
            ></path>
          </svg>
        </svg>
        <svg
          id="svg-chest-back"
          viewBox="0 0 424 411"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g fillRule="nonzero" fill="none">
            <path
              d="M260.763.821H164.24c-2.963 0-5.39 2.425-5.39 5.39v25.333c0 2.963 2.425 5.39 5.39 5.39h96.522c2.964 0 5.39-2.425 5.39-5.39V6.211c0-2.965-2.426-5.39-5.389-5.39z"
              fill="#BF953E"
            />
            <path
              d="M181.217 31.543V6.211c0-2.964 2.425-5.39 5.39-5.39H164.24c-2.963 0-5.39 2.425-5.39 5.39v25.333c0 2.963 2.425 5.39 5.39 5.39h22.366c-2.964-.001-5.389-2.427-5.389-5.391z"
              fill="#000"
              opacity=".1"
            />
            <path
              d="M391.508 231.069c0 3.415-2.425 6.211-5.389 6.211h-347.232c-2.964 0-5.39-2.795-5.39-6.211V32.399c0-3.416 2.425-6.212 5.39-6.212h347.232c2.964 0 5.39 2.795 5.39 6.212v198.67h.001z"
              fill="#EFBA50"
            />
            <path
              d="M67.4 231.069V32.399c0-3.416 2.425-6.212 5.39-6.212H38.886c-2.963 0-5.39 2.795-5.39 6.212v198.67c0 3.416 2.425 6            .39 5.39h33.903c-2.964 0-5.389-2.796-5.389-6.212z"
              fill="#000"
              opacity=".1"
            />
          </g>
        </svg>
        <svg
          id="svg-chest-front"
          viewBox="0 0 424 411"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g fillRule="nonzero" fill="none">
            <path
              d="M348.93 372.306v31.714c0 2.964 2.425 5.39 5.39 5.39h37.252c2.964 0 5.39-2.425 5.39-5.39v-31.714H348.93zM28.041 404.02c0 2.964 2.425 5.39 5.39 5.39h37.254c2.964 0 5.39-2.425 5.39-5.39v-31.714H28.041v31.714z"
              fill="#5A482E"
            />
            <path
              d="M371.517 404.02v-31.714h-22.585v31.714c0 2.964 2.425 5.39 5.39 5.39h22.584c-2.965-.001-5.389-2.426-5.389-5.39zm-321.215 0v-31.714H28.041v31.714c0 2.964 2.425 5.39 5.39 5.39h22.263c-2.965-.001-5.392-2.426-5.392-5.39z"
              fill="#000"
              opacity=".1"
            />
            <path
              d="M417.937 377.479c0 2.964-2.425 5.39-5.39 5.39H12.456c-2.963 0-5.39-2.425-5.39-5.39V191.101c0-2.964 2.425-5.39 5.39-5.39h400.091c2.964 0 5.39 2.425 5.39 5.39v186.378z"
              fill="#BF953E"
            />
            <path
              d="M40.602 377.479V191.101c0-2.964 2.425-5.39 5.39-5.39H12.455c-2.963 0-5.39 2.425-5.39 5.39v186.378c0 2.964 2.425 5.39 5.39 5.39h33.536c-2.963-.001-5.389-2.425-5.389-5.39z"
              fill="#000"
              opacity=".1"
            />
            <path
              d="M43.868 351.271c-2.964 0-5.39-2.425-5.39-5.39V222.696c0-2.964 2.425-5.39 5.39-5.39h337.267c2.964 0 5.39 2.425 5.39 5.39v123.186c0 2.964-2.425 5.39-5.39 5.39H43.868v-.001z"
              fill="#EFBA50"
            />
            <path
              d="M71.976 345.882V222.696c0-2.964 2.425-5.39 5.39-5.39H43.868c-2.964 0-5.39 2.425-5.39 5.39v123.186c0 2.964 2.425 5.39 5.39 5.39h33.497c-2.964-.001-5.389-2.426-5.389-5.39z"
              fill="#000"
              opacity=".1"
            />
            <path
              d="M167.267 185.711v100.973c0 2.964 2.425 5.39 5.39 5.39h79.688c2.964 0 5.39-2.425 5.39-5.39V185.711h-90.468z"
              fill="#A87B2C"
            />
            <path
              d="M228.999 223.534c0-9.165-7.384-16.594-16.498-16.594-9.108 0-16.497 7.429-16.497 16.594a16.601 16.601 0 0 0 8.66 14.602v24.978a7.837 7.837 0 1 0 15.674 0v-24.976a16.602 16.602 0 0 0 8.661-14.604z"
              fill="#5A482E"
            />
            <path
              d="M167.267 185.711v100.973c0 2.964 2.425 5.39 5.39 5.39h22.16c-2.964 0-5.39-2.425-5.39-5.39V185.711h-22.16z"
              fill="#000"
              opacity=".1"
            />
          </g>
        </svg>
      </div>
    </div>
  );
};

export default Sapphire;
