import styled from "styled-components"

export const  Container = styled.div`
width: 75%;
width: 100vw;
min-height: 20vh;
margin: 0 auto;
/* background-color: lightblue; */

display: flex;
justify-content: center;
align-items: center;

@media (max-width: 64em) {
  // width: 85%;
}
@media (max-width: 48em) {
  flex-direction: column-reverse;
  &>*:first-child{
    width: 100%;
    margin-top: 2rem;
  }
}
`