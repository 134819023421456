import { useEffect, useState } from "react";
import { Container } from "./styledTags";
import "./wordShadow.css"

export interface IWordShadowProps{
  src: string;
  className: string
}

export type WordShadowProps ={
  words: IWordShadowProps[];
}
const WordShadow = ( {words}: WordShadowProps) => {
  const [wordShadows, setWordShadows] = useState(words);

  useEffect(() => {

}, [wordShadows]);
  return (
    <Container>
      <div id="image-container">
      {wordShadows.map((word, index) => (
        <div className={`image-wrapper ${word.className}`} key={index}>
          <img src={word.src} alt="" className="image" />
          {/* <div
          className="image"
          style={{
            backgroundImage: `url(${word.src})`,
            backgroundSize: '100% 100%',
            // You can also set other background properties as needed
            backgroundPosition: 'center center',
            backgroundRepeat: 'no-repeat',
        }}
          >

          </div> */}
        </div>
      ))}
      </div>
    </Container>
  );
}

export default WordShadow;
