import Button from "./Components/Button";
import { Account } from "types/react-app-env";

const AccountCounter = ({publicAddr, ethBalance}:Account) => {
    return (
        <Button account={
            {
                publicAddr,
                ethBalance
            }
        } />

    )
}

export default AccountCounter;

