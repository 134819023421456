import React, { useEffect, useState } from "react";
import "./bTF.css";

type TimeDisplayProps = {
  className?: string;
};

const TimeDisplay: React.FC<TimeDisplayProps> = ({ className }) => {
  const [currentTime, setCurrentTime] = useState({
    month: "",
    day: "",
    year: "",
    hour: "",
    minute: "",
    ampm: "",
  });

  useEffect(() => {
    const tick = () => {
      const d = new Date();
      let year = d.getFullYear().toString();
      let month = d.toLocaleString("en-uk", { month: "short" });
      let day = d.getDate().toString().padStart(2, "0");
      let hours = d.getHours();
      let minutes = d.getMinutes().toString().padStart(2, "0");
      let ampm = hours >= 12 ? "PM" : "AM";
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      let hour = hours.toString().padStart(2, "0");

      setCurrentTime({ month, day, year, hour, minute: minutes, ampm });
    };

    tick(); // Initialize immediately
    const timerID = setInterval(tick, 1000);

    return () => {
      clearInterval(timerID);
    };
  }, []);

  return (
    <div className={`back-to-the-future ${className}`}>
      {/* <!-- destination time --> */}

      <div id="destination" className="outer">
        <div className="row">
          <div className="header-label">MONTH</div>
          <div className="header-label">DAY</div>
          <div className="header-label">YEAR</div>
          <div className="header-label">HOUR</div>
          <div className="header-label">MIN</div>
        </div>
        <div className="row">
          <div className="display month">
            <p className="destination time">Feb</p>
          </div>

          <div className="display">
            <p className="destination time">29</p>
            <p className="number-back">88</p>
          </div>

          <div className="display">
            <p className="destination time">2024</p>
            <p className="number-back">8888</p>
          </div>

          <div className="led-time-separator-container">
            <div className="ampm-label am-label">AM</div>
            <div className="led led-destination"></div>
            <div className="ampm-label pm-label">PM</div>
            <div className="led led-destination-on"></div>
          </div>

          <div className="display">
            <p className="destination time">06</p>
            <p className="number-back">88</p>
          </div>

          <div className="led-unit-separator-container">
            <div className="led led-destination-on"></div>
            <div className="led led-destination-on"></div>
            <div className="led led-destination"></div>
            <div className="led led-destination"></div>
          </div>

          <div className="display">
            <p className="destination time">39</p>
            <p className="number-back">88</p>
          </div>
        </div>
        <div className="row">
          <div className="footer-label">DESTINATION TIME</div>
        </div>
      </div>

      {/* <!-- present time --> */}

      <div id="present" className="outer">
        <div className="row">
          <div className="header-label">MONTH</div>
          <div className="header-label">DAY</div>
          <div className="header-label">YEAR</div>
          <div className="header-label">HOUR</div>
          <div className="header-label">MIN</div>
        </div>
        <div className="row">
          <div className="display month">
            <p id="present-month" className="present time">
              {currentTime.month}
            </p>
          </div>

          <div className="display">
            <p id="present-day" className="present time">
              {currentTime.day}
            </p>
            <p className="number-back">88</p>
          </div>

          <div className="display">
            <p id="present-year" className="present time">
              {currentTime.year}
            </p>
            <p className="number-back">8888</p>
          </div>

          <div className="led-time-separator-container">
            <div className="ampm-label am-label">AM</div>
            <div
              id="present-am-led"
              className={`led ${
                currentTime.ampm === "AM" ? "led-present-on" : "led-present"
              }`}
            ></div>
            <div className="ampm-label pm-label">PM</div>
            <div
              id="present-pm-led"
              className={`led ${
                currentTime.ampm === "PM" ? "led-present-on" : "led-present"
              }`}
            ></div>
          </div>

          <div className="display">
            <p id="present-hour" className="present time">
              {currentTime.hour}
            </p>
            <p className="number-back">88</p>
          </div>

          <div className="led-unit-separator-container">
            <div className="led led-present-on"></div>
            <div className="led led-present-on"></div>
            <div className="led led-present"></div>
            <div className="led led-present"></div>
          </div>

          <div className="present display">
            <p id="present-minute" className="present time">
              {currentTime.minute}
            </p>
            <p className="number-back">88</p>
          </div>
        </div>
        <div className="row">
          <div className="footer-label">PRESENT TIME</div>
        </div>
      </div>

      {/* <!-- departed time --> */}

      <div id="departed" className="outer">
        <div className="row">
          <div className="header-label">MONTH</div>
          <div className="header-label">DAY</div>
          <div className="header-label">YEAR</div>
          <div className="header-label">HOUR</div>
          <div className="header-label">MIN</div>
        </div>
        <div className="row">
          <div className="display month">
            <p className="departed time">Oct</p>
          </div>

          <div className="display">
            <p className="departed time">31</p>
            <p className="number-back">88</p>
          </div>

          <div className="display">
            <p className="departed time">2008</p>
            <p className="number-back">8888</p>
          </div>

          <div className="led-time-separator-container">
            <div className="ampm-label am-label">AM</div>
            <div className="led led-departed"></div>
            <div className="ampm-label pm-label">PM</div>
            <div className="led led-departed-PM"></div>
          </div>

          <div className="display">
            <p className="departed time">02</p>
            <p className="number-back">88</p>
          </div>

          <div className="led-unit-separator-container">
            <div className="led led-departed-on"></div>
            <div className="led led-departed-on"></div>
            <div className="led led-departed"></div>
            <div className="led led-departed"></div>
          </div>

          <div className="display">
            <p className="departed time">10</p>
            <p className="number-back">88</p>
          </div>
        </div>
        <div className="row">
          <div className="footer-label">LAST TIME DEPARTED</div>
        </div>
      </div>
    </div>
  );
};

export default TimeDisplay;
