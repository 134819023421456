import React, { Suspense, useEffect, useState } from "react";
import { Canvas } from "@react-three/fiber";
import {
  OrbitControls,
  useGLTF,
  Environment,
  PerspectiveCamera,
} from "@react-three/drei";
import "./panel.css";
import { Section } from "./styledTags";

import season1 from "assets/RoadMap/Muhammad_Ali.gif";
import season2 from "assets/RoadMap/MikeTyson.jpg";
import p2SpaceMan from "assets/RoadMap/P2-Space-Win.gif";
import Timeline from "components/Timeline/TimeLine";

const Model1 = ({ url }) => {
  const gltf = useGLTF(url, "/draco-gltf/");

  if (!("scene" in gltf)) {
    console.error("GLTF object does not contain a scene:", gltf);
    return null;
  }

  return <primitive object={gltf.scene} dispose={null} />;
};

const Model = ({ url }) => {
  const gltfData = useGLTF(url);

  // Check if gltfData is an array and get the first element, otherwise use it directly
  const gltf = Array.isArray(gltfData) ? gltfData[0] : gltfData;

  if (!gltf.scene) return null;

  return (
    <>
      <primitive object={gltf.scene} dispose={null} />
    </>
  );
};

const Loader = () => (
  <mesh>
    <ringGeometry args={[0.1, 0.2, 32]} />
    <meshBasicMaterial color="white" />
  </mesh>
);

export enum RoadMapType {
  Map = "Map",
  Game = "Game",
}
const Roadmap = () => {
  const [showRoad, setShowRaod] = useState<RoadMapType>(RoadMapType.Map);

  useEffect(() => {
    // setShowRaod(RoadMapType.Game);
  }, [showRoad]);

  return (
    <Section id="roadmap">
      <Timeline
        timelineItems={[
          {
            point: "Boxing Genesis",
            year: "Q1",
            season: "p2p season 1",
            imgSrc: season1,
            title: "Season 1: Boxing Genesis",
            description:
              "In the inaugural season of our NFT collection Seasons, we're excited to launch an exclusive OpenSea drop featuring a unique collection of 888 images, each with three editions. This season, named Boxing Genesis, showcases a captivating array of boxing-themed images. From the intensity of weigh-ins and press conferences to the dedication seen in training sessions, each image offers a glimpse into the world of boxing. Additionally, fans will get access to never-before-seen behind-the-scenes photographs capturing the essence of world title fights. These images not only celebrate the sport but also bring fans closer to the moments that define boxing history.",
          },
          {
            point: "Knockout Chronicles",
            year: "Q3",
            season: "p2p season 2",
            imgSrc: season2,
            title: "Season 2: Knockout Chronicles",
            description:
              "Our second season, Knockout Chronicles, elevates the experience by releasing a stunning array of in-fight images. This collection spans some of the most iconic world title and blockbuster fights from the last two decades, both in the UK and globally. The images in this season are categorized into three distinct tiers to cater to different tastes. Tier 1, Raw Ring Legends, features unedited, raw images capturing the true spirit of boxing. Tier 2, Dynamic Fight Art, transforms these moments through creative image manipulation, adding an artistic flair. Lastly, Tier 3, AI Infused Classics, presents a generative art project, blending classic boxing imagery with modern AI techniques, offering a unique, futuristic perspective on the sport.",
          },
          {
            point: "Battle of Champions",
            year: "Q3-Q4",
            season: "p2p season 3",
            imgSrc: p2SpaceMan,
            title: "Season 3: Punchers Chance",
            description:
              "The final season, Punchers Chance, is where the collection truly comes to life. In this season, we integrate utility into all the images from Seasons 1 and 2. These NFTs will be incorporated into a trading card game, where players can use their Tier 1, 2, and 3 NFTs to battle against each other. Each image will be imbued with stats, making every card unique and strategic. As the season progresses, we will also introduce Tier 4 NFTs, seamlessly integrating them into the game. This season is all about interaction, strategy, and bringing the boxing community together in a virtual arena where legends, art, and technology collide.",
          },
        ]}
      />
    </Section>
  );
};

export default Roadmap;
