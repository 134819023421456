import React from "react";
// import "Views/ExploreGrid/components/Constructor/TopFilter/TopFilter.scss";


import Constructor from "Views/ExploreGrid/components/Constructor/Constructor";

import Preloader from "Views/ExploreGrid/components/Preloader/Preloader";
import CARD_INFO_ARRAY from "Views/ExploreGrid/variables/Array/CARD_INFO_ARRAY";
import "./explorePage.scss";

const ExplorePage = () => {
  return (
    <div id="explore-page">
      {/* <Preloader /> */}
      {/* <Header /> */}
      <Constructor cardsInfo={CARD_INFO_ARRAY} />
    </div>
  );
};

export default ExplorePage;
