import { StyleHTMLAttributes, useEffect, useState } from "react";
import { Container } from "./styledTags";
import "./selectionPage.css";
import { Toaster } from "react-hot-toast";

export interface IGameSelectionNotificationProps {
  notificationIcon: string;
  onClick: () => void;
  className?: string;
}
export interface IGameSelectionIconProps {
  icon: string;
  iconName: string;
  onClick: () => void;
  style?: React.CSSProperties;
  className?: string;
  description?:string;
  // onNavigate?: () => void;
}
export interface IGameSelectionInfoProps {
  infoIcon: string;
  information: string;
  onClick: () => void;
  className?: string;
}

export interface IGameSelectionProps {
  notifications: IGameSelectionNotificationProps[];
  icons: IGameSelectionIconProps[];
  informations: IGameSelectionInfoProps[];
}

export type GameSelectionProps = {
  gameProps: IGameSelectionProps;
};
const SelectionPage = ({ gameProps }: GameSelectionProps) => {
  // const [wordShadows, setWordShadows] = useState(words);

  useEffect(() => {}, []);

  return (
    <Container>
      <div className="game-body">
        <div className=" game-header sticky-sides">
          <div className="info-block">
            <ul className="notifications">
              {gameProps.notifications.map((notification, index) => (
                <li key={index} onClick={notification.onClick}>
                  <img
                    className={notification.className}
                    src={notification.notificationIcon}
                  />
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="game-main">
          <ul className="game-list">
            {gameProps.icons.map((gameIcon, index) => (
              <li id={gameIcon.iconName} key={index}>
                <div className="centered">{gameIcon.iconName}</div>
                <a
                  onClick={() => {
                    gameIcon.onClick(); 
                    // gameIcon.onNavigate();
                  }}
                >
                  <img
                    className={gameIcon.className}
                    style={gameIcon.style}
                    src={gameIcon.icon}
                    alt={gameIcon.iconName}
                  />
                </a>
              </li>
            ))}
          </ul>
          <div className="action-list sticky-sides">
            {gameProps.informations.map((info, index) => (
              <div key={index}>
                <span className={info.className} onClick={info.onClick}>
                  {`${info.infoIcon}`}
                </span>
              </div>
            ))}
          </div>
        </div>
        <div className="sticky-sides">
          <hr />
        </div>
      </div>
      <Toaster position="top-right" reverseOrder={false} />
    </Container>
  );
};

export default SelectionPage;
