import { ethers } from "ethers";
import { useInterval } from "Helpers/UseInterval";
import { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import WordShadow, { IWordShadowProps } from "components/WordShadow/WordShadow";
import { SmartContractHelper } from "Helpers/BaseContractHelper";
import Showcase from "components/ShowCase/Showcase";
import InfoPane, { CollectionArgs } from "components/InformationPane/InfoPane";
import { Section } from "./styledTags";
import NFTInfo, { MintParams } from "Entites/NFT";
import { API } from "aws-amplify";

declare global {
  interface Window {
    ethereum: ethers.providers.ExternalProvider;
  }
}

const CollectionDapp = () => {
  // store greeting in local state
  const [t1Balance, setT1Balance] = useState<number>(0);
  const [t2Balance, setT2Balance] = useState<number>(0);
  const [t3Balance, setT3Balance] = useState<number>(0);
  const [t4Balance, setT4Balance] = useState<number>(0);
  const [isOwner, setIsOwner] = useState<boolean>(false);

  const [greeting, setGreetingValue] = useState<string>("");
  const [tokenInfo, setTokenInfo] = useState<{
    tier: number;
    collectionType: number;
  }>(null);

  const [tokenFees, setTokenFees] = useState<{
    t1: string;
    t2: string;
    t3: string;
    t4: string;
  }>(null);

  const [quantity, setQuantity] = useState<number>(1);

  // store user address in local state
  const [userAddress, setUserAddressValue] = useState<string>("");
  // store amount in local state
  const [amount, setAmountValue] = useState<number>(0);

  // request access to the user's MetaMask account
  const requestAccount = async () => {
    if (window.ethereum?.request)
      return window.ethereum.request({ method: "eth_requestAccounts" });

    throw new Error(
      "Missing install Metamask. Please access https://metamask.io/ to install extension on your browser"
    );
  };

  // call the smart contract, read the current greeting value
  const fetchGreeting = async () => {
    if (typeof window.ethereum !== "undefined") {
      //   const provider = new ethers.providers.Web3Provider(window.ethereum);
      //   const contract = new ethers.Contract(
      //     greeterAddress,
      //     GreeterArtifacts.abi,
      //     provider
      //   ) as Greeter;
      //   try {
      //     const data = await contract.greet();
      //     toast.success(`Greeting: ${data}`);
      //   } catch (err) {
      //     toast.error(`Error: ${err}`);
      //   }

      toast.success(`Greeting: ${"hello"}`);

      toast.error(`fuck me `);
    }
  };

  // get All Tokens of the token contract
  async function getAllTokens() {
    if (typeof window.ethereum !== "undefined") {
      const contract = new SmartContractHelper();

      console.log("Contract:", contract);
      // request account from metamask
      const [account] = await requestAccount();

      console.log("Account:", account);

      const balance = await contract.getAllTokens();

      console.log("All Tokens Balance:", balance);

      toast.success(`AllToken balance: ${balance.length.toString()}`);
    }
  }
  async function getMyTokens() {
    if (typeof window.ethereum !== "undefined") {
      // request account from metamask
      const [account] = await requestAccount();

      console.log("Account:", account);

      const contract = new SmartContractHelper();

      console.log("Contract:", contract);

      const balance = await contract.getMyTokens();

      console.log("My Token Balance:", balance);

      toast.success(`MyToken balance: ${balance.length.toString()}`);
    }
  }

  async function getTokenInfo() {
    if (typeof window.ethereum !== "undefined") {
      const contract = new SmartContractHelper();

      console.log("Contract:", contract);
      // request account from metamask
      const [account] = await requestAccount();

      console.log("Account:", account);

      const balance = await contract.getNFTInfo(
        tokenInfo.tier,
        tokenInfo.collectionType
      );

      console.log("TokenInfo:", balance);

      toast.success(
        `Tier ${tokenInfo.tier} balance: ${balance.length.toString()}`
      );
    }
  }

  const getAvailability = async (tier: number): Promise<number> => {
    if (typeof window.ethereum !== "undefined") {
      const contract = new SmartContractHelper();

      // console.log("Contract:", contract);

      // request account from metamask
      const [account] = await requestAccount();

      //we have the current logged in account at this point FYI
      // console.log("Account:", account);

      const balance = await contract.getAvailableTokens(tier);

      // console.log("TokenInfo:", balance);

      toast.success(`Tier ${tier} balance: ${balance.toString()}`);

      return balance;
    }

    throw new Error(
      "Missing install Metamask. Please access https://metamask.io/ to install extension on your browser"
    );
  };

  async function buyRandomNFT(tier: number) {
    try {
      if (tier || quantity) {
        console.log("tier", tier);
        console.log("quantity", quantity);

        //Greeting
        await fetchGreeting();
        if (typeof window.ethereum !== "undefined") {
          const contract = new SmartContractHelper();

          // Here's an example how you can call your API

          const init = {
            body: {
              // replace with the request body
              tier: tier,
              amount: amount,
            },
            headers: {
              // Optional - replace or remove as necessary
              "Content-Type": "application/json",
              // ,
              // 'Authorization': `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
            },
          };

          API.post("sudorandomnft", "/buy", init)
            .then(async (response) => {
              console.log("my API call", response);

              // Map response data to NFTInfo instances
              const nftInfos = response.data.map(
                (item) =>
                  new NFTInfo(
                    item.uriHash,
                    item.tier,
                    item.collection,
                    item.consecutiveWins,
                    item.championships,
                    item.winLossRatio,
                    item.available
                  )
              );

              console.log("Contract:", contract);
              const transaction = await contract.buyPack(tier, nftInfos);

              console.log("transaction:", transaction);

              await transaction.wait();

              console.log("BuyRandom:", transaction);

              toast.success(`balance: ${transaction.confirmations}`);
            })
            .catch((error) => {
              console.log("Error fetching data", error);
            });
        }
      } else {
        toast.error("Choose Tier");
      }
    } catch (e: any) {
      console.log("error:", e);
      if (e && e.data) toast.error(e.data.message);
    }
  }

  async function handleFees() {
    try {
      if (typeof window.ethereum !== "undefined") {
        const contract = new SmartContractHelper();
        const fees = await contract.getAllTokenFees();
        setTokenFees({
          ...tokenFees,
          t1: ethers.utils.formatEther(fees[0]),
          t2: ethers.utils.formatEther(fees[1]),
          t3: ethers.utils.formatEther(fees[2]),
          t4: ethers.utils.formatEther(fees[3]),
        });
        console.log("Contract:", contract);
      } else {
        //send message to user need a wallet
      }
    } catch (e: any) {
      console.log("error:", e);
      if (e && e.data) toast.error(e.data.message);
    }
  }

  async function SetTokenAvalability(avalability: MintParams) {
    try {
      const contract = new SmartContractHelper();

      console.log("avalability", avalability);
      const transaction = await contract.mint(avalability);
    } catch (e: any) {
      console.log("error:", e);
      toast.error(e.data.message);
    }
  }
  async function checkIsOwner() {
    try {
      const contract = new SmartContractHelper();

      const owner = await contract.owner();

      setIsOwner(owner);
    } catch (e: any) {
      console.log("error:", e);
      toast.error(e.data.message);
    }
  }

  const handleTierQuantity = async () => {
    setT1Balance(await getAvailability(1));
    setT2Balance(await getAvailability(2));
    setT3Balance(await getAvailability(3));
    setT4Balance(await getAvailability(4));
  };

  useEffect(() => {
    (async () => {
      await checkIsOwner();
    })();
  }, []);

  useInterval(() => {
    (async () => {
      await handleTierQuantity();
      await handleFees();
    })();
  }, 5000);

  const wordShadows: IWordShadowProps[] = [
    {
      src: "https://cdn.discordapp.com/attachments/852529597303488553/1096889832875237476/L1-PhotoRoom.png-PhotoRoom.png",
      className: "l1",
    },
    {
      src: "https://cdn.discordapp.com/attachments/852529597303488553/1096889833160454214/L2-PhotoRoom.png-PhotoRoom.png",
      className: "l2",
    },
    {
      src: "https://cdn.discordapp.com/attachments/852529597303488553/1096889833919623219/x-PhotoRoom.png-PhotoRoom.png",
      className: "x",
    },
    {
      src: "https://cdn.discordapp.com/attachments/852529597303488553/1096889833395343580/P1-PhotoRoom.png-PhotoRoom.png",
      className: "p1",
    },
    {
      src: "https://cdn.discordapp.com/attachments/852529597303488553/1096889832598425720/2-PhotoRoom.png-PhotoRoom.png",
      className: "2",
    },
    {
      src: "https://cdn.discordapp.com/attachments/852529597303488553/1096889833659572234/P2-PhotoRoom.png-PhotoRoom.png",
      className: "p2",
    },
  ];
  return (
    <>
      {isOwner && (
        <Section id="dapp">
          <WordShadow words={wordShadows} />

          <InfoPane
            tokenAddress={new SmartContractHelper().contractAddress()}
            getMyTokens={getMyTokens}
            // getMyTokens={() => db.bulkUpdateUriHash()}
            getTokenInfo={getTokenInfo}
            getAllTokens={getAllTokens}
            buyRandomNFT={buyRandomNFT}
            onTierChange={async (x) =>
              await setTokenInfo({ ...tokenInfo, tier: Number(x.target.value) })
            }
            onCollectionTypeChange={async (x) =>
              await setTokenInfo({
                ...tokenInfo,
                collectionType: Number(x.target.value),
              })
            }
            //set all token avaialability
            mintCollection={async (args) => await SetTokenAvalability(args)}
          />
          <Toaster position="top-right" reverseOrder={false} />
        </Section>
      )}

      {!isOwner && (
        <div
          style={{
            margin: "auto",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            minHeight: "100vh",
            position: "absolute",
          }}
        >
          Permission Denied only Owner of the Contract can Access
        </div>
      )}
      <Showcase />
    </>
  );
};

export default CollectionDapp;
