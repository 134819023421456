import { RaffleState } from "Helpers/RaffleHelper";
import { Contract, ethers } from "ethers";

export const ContractAddress = "0x8aF6777DEA02cfF8d6a668791dC0B71e9F4452Db";

// Use the defined interfaces in the Game interface
export interface Raffle extends Contract {
  buyRaffle(
    id: number,
    overrides?: ethers.PayableOverrides
  ): Promise<ethers.ContractTransaction>;
  claimNFT(): Promise<ethers.ContractTransaction>;
  setDeadline(
    id: number,
    newDeadline: number
  ): Promise<ethers.ContractTransaction>;
  getRaffleState(id: number): Promise<RaffleState>;
}
