import Card from "components/CardUI";
import React, { useEffect, useState } from "react";
import { ExploreNFT } from "types/react-app-env";
import ExploreNFTS from "Views/Explore/ExploreNFTS";
import CollectionCard from "../Card/Collection Card/CollectionCard";
import "./NFTGrid.css";
import NFTCard from "components/Card/CardList/NFTCard";

interface INFT {
nftListData: ExploreNFT[];
  SetSelectedNFT: (number: number) => void;
}

const NFTGrid = ({ nftListData, SetSelectedNFT: SetSelectedNFT }: INFT) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [selectedNFT, setSelectedNFT] = useState<ExploreNFT>(null);

  const RemoveActiveNavs = () => {
    Array.from(document.querySelectorAll(".Active")).forEach((el) =>
      el.classList.remove("Active")
    );
  };

  useEffect(() => {
    if (selectedNFT) {
      SetSelectedNFT(selectedNFT.id);
    }
  }, [selectedNFT]);

  useEffect(() => {
    setIsLoading(false);
  }, [nftListData]);

  if (isLoading) {
    return <>....loading</>;
  }

  if (nftListData.length === 0) {
    return <div className="punkList">No NFTs available at the moment.</div>; // Message or component when there are no NFTs
  }

  return (
    <ul className="punkList" onClick={RemoveActiveNavs}>
      {nftListData.map((nft, index) => (
        <li
          key={index}
          onClick={() => setSelectedNFT(nft)}
          hidden={nft.hidden ?? false}
        >
          <NFTCard nft={nft} />
        </li>
      ))}
    </ul>
  );
};
export default NFTGrid;
