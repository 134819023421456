import styled from "styled-components";

export const Container = styled('div')`


`
export const P = styled('p')`
    white-space: nowrap; 
    width: 100px; 
    overflow: hidden;
    text-overflow: ellipsis; 
`
export const GlowingAddrBullet = styled('span')`
font-size: 80px;
  color: #2ace5b;
  text-align: center;

//   animation: glow 1s ease-in-out infinite alternate;

//   @-webkit-keyframes glow {
//     from {
//       text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #e60073, 0 0 40px #e60073, 0 0 50px #e60073, 0 0 60px #e60073, 0 0 70px #e60073;
//     }
    
//     to {
//       text-shadow: 0 0 20px #fff, 0 0 30px #ff4da6, 0 0 40px #ff4da6, 0 0 50px #ff4da6, 0 0 60px #ff4da6, 0 0 70px #ff4da6, 0 0 80px #ff4da6;
//     }
//   }

  animation: blinker 1s linear infinite;
  
  @keyframes blinker {  
    50% { opacity: 0; }

    from {
        text-shadow: 0 0 1px #fff, 0 0 5px #fff, 0 0 10px #e60073, 0 0 20px #e60073, 0 0 40px #e60073, 0 0 40px #e60073, 0 0 50px #e60073;
      }
      
      to {
        text-shadow: 0 0 20px #fff, 0 0 30px #ff4da6, 0 0 40px #ff4da6, 0 0 20px #ff4da6, 0 0 30px #ff4da6, 0 0 40px #ff4da6, 0 0 50px #ff4da6;
      }
  
  }
  
  height: 0px;
  position: relative;
  top: -58px;  
`
export const GlowingBalanceBullet = styled('span')`
font-size: 80px;
  color: #0fd5d6;
  text-align: center;

//   animation: glow 1s ease-in-out infinite alternate;

//   @-webkit-keyframes glow {
//     from {
//       text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #e60073, 0 0 40px #e60073, 0 0 50px #e60073, 0 0 60px #e60073, 0 0 70px #e60073;
//     }
    
//     to {
//       text-shadow: 0 0 20px #fff, 0 0 30px #ff4da6, 0 0 40px #ff4da6, 0 0 50px #ff4da6, 0 0 60px #ff4da6, 0 0 70px #ff4da6, 0 0 80px #ff4da6;
//     }
//   }

  animation: blinker 1s linear infinite;
  
  @keyframes blinker {  
    50% { opacity: 0; }

    from {
        text-shadow: 0 0 1px #fff, 0 0 5px #fff, 0 0 10px #e60073, 0 0 20px #e60073, 0 0 40px #e60073, 0 0 40px #e60073, 0 0 50px #e60073;
      }
      
      to {
        text-shadow: 0 0 20px #fff, 0 0 30px #ff4da6, 0 0 40px #ff4da6, 0 0 20px #ff4da6, 0 0 30px #ff4da6, 0 0 40px #ff4da6, 0 0 50px #ff4da6;
      }
  
  }
  
  height: 0px;
  position: relative;
  top: -58px;  
`
export const Btn = styled.button`
display: flex;
background-color: ${props => props.theme.text};
color: ${props => props.theme.body};
outline: none;
border: none;

font-size: ${props => props.theme.fontmd};
padding: 0.9rem 2.3rem;
border-radius: 50px;
cursor: pointer;
transition: all 0.2s ease;
position: relative;
&:hover{
    transform: scale(0.9);
}

&::after{
    content: ' ';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0);
    border: 2px solid ${props => props.theme.text};
    width: 100%;
    height: 100%;
    border-radius: 50px;
    transition: all 0.2s ease;
  }

  &:hover::after{
      transform: translate(-50%, -50%) scale(1);
      padding: 0.3rem;
  }

  @media (max-width: 48em) {
  font-size: ${props => props.theme.fontsm};
  }
`
