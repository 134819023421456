import { useEffect, useState } from "react";
import Button from "../Button";
import Logo from "../Logo";
import detectEthereumProvider from "@metamask/detect-provider";
import { Account, ExternalProvider, Window } from "../../types/react-app-env";
import { HamburgerMenu, Menu, MenuItem, NavBar, Section } from "./styledTags";
import AccountCounter from "components/Counter/AccountCounter";
import { ethers } from "ethers";
import { useNavigate } from "react-router";
import { SmartContractHelper } from "Helpers/BaseContractHelper";

// {children: children, web3Connect: web3Connect}
const Navigation = () => {
  const [click, setClick] = useState(false);
  const [accounts, setAccounts] = useState<Account>(null);
  const [signer, setSigner] = useState<any>(null);
  const [signerAddr, setSignerAddr] = useState<any>(null);
  const [provider, setProvider] = useState<any>(null);
  const navigate = useNavigate();
  const [isOwner, setIsOwner] = useState(false);

  const scrollTo = (id: string) => {
    let element = document.getElementById(id);
    console.log("element: ", element);

    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    } else {
      navigate("");
      setTimeout(() => scrollTo(id), 100);
    }

    setClick(!click);
  };

  useEffect(() => {
    // Update the document title using the browser API
    // web3Connect();
    isConnected();
  }, [signer, signerAddr]);

  const switchNetwork = async () => {
    if ((window as Window).ethereum) {
      try {
        // Try to switch to the Ethereum Mainnet
        console.log(
          "TRYING TO SWITCH to Ethereum Mainnet",
          `0x${Number(1).toString(16)}`
        );
        await (window as Window).ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [
            {
              chainId: `0x${Number(1).toString(16)}`, // chainId for Ethereum Mainnet
            },
          ],
        });
      } catch (error) {
        console.log(error);
        // Handle other errors, such as the user rejecting the request, etc.
        if ((error as any).code === -32002) {
          console.log("Request is Pending");
        } else {
          alert(
            "Failed to switch to the Ethereum Mainnet. Check your MetaMask and try again."
          );
        }
      }
    } else {
      alert(
        "MetaMask is not installed. Please install it to use this app: https://metamask.io/download.html"
      );
    }
  };

  //local testNet
  const switchNetwork1 = async () => {
    if ((window as Window).ethereum) {
      try {
        // Try to switch to the Mumbai testnet
        console.log("TRYING TO SWITCH", `0x${Number(1337).toString(16)}`);
        await (window as Window).ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [
            {
              chainId: `0x${Number(1337).toString(16)}`,
              // chainId: `0x539`,
              // chainId: '5777',
            },
          ], // Check networks.js for hexadecimal network ids
        });
      } catch (error) {
        // This error code means that the chain we want has not been added to MetaMask
        // In this case we ask the user to add it to their MetaMask
        if ((error as any).code === 4902) {
          console.log("TRYING TO Add Chain");
          try {
            await (window as Window).ethereum.request({
              method: "wallet_addEthereumChain",
              params: [
                {
                  // chainId: '"0x539',
                  chainId: `0x${Number(1337).toString(16)}`,
                  chainName: "ganache",
                  //locallHost
                  rpcUrls: ["HTTP://127.0.0.1:7545"],
                  nativeCurrency: {
                    name: "development",
                    symbol: "IDA",
                    decimals: 18,
                  },
                  blockExplorerUrls: null,
                },
              ],
            });
          } catch (error) {
            console.log(error);
          }
        }
        // This error code means that the chain we want to switch to is pending confirmation
        // In this case we ask the user to wait
        else if ((error as any).code === -32002) {
          console.log("Request is Pending");
        }
        console.log(error);
      }
    } else {
      // If window.ethereum is not found then MetaMask is not installed
      alert(
        "MetaMask is not installed. Please install it to use this app: https://metamask.io/download.html"
      );
    }
  };

  //Gorlie tESTNET
  const switchNetworkGorli = async () => {
    if ((window as Window).ethereum) {
      try {
        // Try to switch to the Goerli testnet
        console.log(
          "TRYING TO SWITCH to Goerli",
          `0x${Number(5).toString(16)}`
        );
        await (window as Window).ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [
            {
              chainId: `0x${Number(5).toString(16)}`, // chainId for Goerli
            },
          ],
        });
      } catch (error) {
        // This error code means that the chain we want has not been added to MetaMask
        if ((error as any).code === 4902) {
          console.log("TRYING TO Add Goerli Chain");
          try {
            await (window as Window).ethereum.request({
              method: "wallet_addEthereumChain",
              params: [
                {
                  chainId: `0x${Number(5).toString(16)}`, // chainId for Goerli
                  chainName: "Goerli",
                  rpcUrls: [
                    "https://goerli.infura.io/v3/YOUR_INFURA_PROJECT_ID",
                  ], // Goerli RPC URL (replace with your Infura project ID)
                  nativeCurrency: {
                    name: "Ethereum",
                    symbol: "ETH",
                    decimals: 18,
                  },
                  blockExplorerUrls: ["https://goerli.etherscan.io/"], // Goerli block explorer URL
                },
              ],
            });
          } catch (error) {
            console.log(error);
          }
        }
        // This error code means that the chain we want to switch to is pending confirmation
        else if ((error as any).code === -32002) {
          console.log("Request is Pending");
        }
        console.log(error);
      }
    } else {
      alert(
        "MetaMask is not installed. Please install it to use this app: https://metamask.io/download.html"
      );
    }
  };

  const checkIsOwner = async () => {
    try {
      const contract = new SmartContractHelper();

      const owner = await contract.owner();

      setIsOwner(owner);
    } catch (e: any) {
      console.log("error:", e);
    }
  };

  const connect = async () => {
    // return async (dispatch) => {
    // };

    const provider = (await detectEthereumProvider()) as ExternalProvider | any;

    if (provider) {
      // From now on, this should always be true:
      // provider === window.ethereum

      await switchNetwork();

      console.log("Provider:", provider);
      const ethAccounts = await provider.request({
        method: "eth_requestAccounts",
      });

      if (ethAccounts) {
        // console.log("Found Accounts:", ethAccounts);

        setAccounts({
          publicAddr: ethAccounts,
          ethBalance: "0",
        });
      }
    } else {
      console.log("Please install MetaMask!");
      alert("Please install MetaMask!");
    }
  };

  const isConnected = async () => {
    console.log("IsConnected:");
    if (accounts == null) {
      const ethProvider = (await detectEthereumProvider()) as
        | ExternalProvider
        | any;

      if (ethProvider) {
        await switchNetwork();

        // From now on, this should always be true:
        // provider === window.ethereum
        // console.log("Provider:", ethProvider);
        const ethAccounts = await ethProvider.request({
          method: "eth_accounts",
        });

        if (ethAccounts && ethAccounts.length > 0) {
          // console.log("Found Accounts:", ethAccounts);

          // const ethBalance = await provider.request({
          //   method: "eth_getBalance",
          //   params: [ethAccounts[0], "latest"]
          // });
          // console.log("Get Balance:", ethBalance);

          const ethersProvider = new ethers.providers.Web3Provider(
            (window as Window).ethereum
          );
          // console.log("Get Provider ethers:", ethersProvider);

          if (!provider) setProvider(ethersProvider);

          setSigner(await ethersProvider.getSigner());
          // console.log("Get signer:", signer);

          //Get connected wallet address
          if (signer) setSignerAddr(await signer.getAddress());

          // if (signerAddr) console.log("Get signerAddr:", signerAddr);

          //Connect to contract
          // tokenContract = await new ethers.Contract(tokenContractAddress , tokenABI , signer);
          // const balance = await ethersProvider.getBalance(ethAccounts);

          setAccounts({
            publicAddr: ethAccounts,
            ethBalance: "0",
          });

          await checkIsOwner();

          if (provider) await getBalance();
        }
      }
    }
  };

  const getBalance = async () => {
    if (accounts != null) {
      console.log("Get Balance:");
      // Get the balance of an account (by address or ENS name, if supported by network)
      const balance = await provider.getBalance("ethers.eth");
      // { BigNumber: "182826475815887608" }
      console.log("Balance:", balance);

      // Often you need to format the output to something more user-friendly,
      // such as in ether (instead of wei)
      const fotmatedBalance = ethers.utils.formatEther(balance);
      // '0.182826475815887608'
      console.log("fotmatedBalance:", fotmatedBalance);

      // If a user enters a string in an input field, you may need
      // to convert it from ether (as a string) to wei (as a BigNumber)
      ethers.utils.parseEther("1.0");
      // { BigNumber: "1000000000000000000" }
    }
  };

  return (
    <Section id="navigation">
      <NavBar>
        <Logo />
        <HamburgerMenu onClick={() => setClick(!click)} click={click}>
          &nbsp;
        </HamburgerMenu>
        <Menu click={click}>
          <MenuItem onClick={() => scrollTo("home")}>Home</MenuItem>

          {isOwner && (
            <>
              <MenuItem onClick={() => navigate("explore2")}>Explore2</MenuItem>
            </>
          )}

          <MenuItem onClick={() => scrollTo("about")}>About</MenuItem>
          <MenuItem onClick={() => scrollTo("roadmap")}>Roadmap</MenuItem>

          <MenuItem onClick={() => scrollTo("team")}>Team</MenuItem>

          <MenuItem onClick={() => scrollTo("faq")}>Faq</MenuItem>

          {/*hide behind owner call from blkchain*/}
          {isOwner && (
            <>
              <MenuItem onClick={() => navigate("mint")}>Mint</MenuItem>
              <MenuItem onClick={() => navigate("dapp")}>Dapp</MenuItem>

              <MenuItem onClick={() => navigate("raffle")}>Raffle</MenuItem>

              <MenuItem onClick={() => navigate("game/home")}>Game</MenuItem>
            </>
          )}

          <MenuItem>
            <div className="mobile">
              {accounts ? (
                <AccountCounter {...accounts} />
              ) : (
                <Button
                  text="Connect Wallet"
                  onClick={async () => await connect()}
                />
              )}
            </div>
          </MenuItem>
        </Menu>
        <div className="desktop">
          {accounts ? (
            <AccountCounter {...accounts} />
          ) : (
            <Button
              text="Connect Wallet"
              onClick={async () => await connect()}
            />
          )}
        </div>
      </NavBar>
    </Section>
  );
};

export default Navigation;

// export default connect(mapStateToProps, mapDispatchToProps)(Navigation)
