import React from "react";
import RadioButton from "../../../UI/RadioButton/RadioButton";
import FrontBlock from "../../../UI/FrontBlock/FrontBlock";

import "./DateFilterWrap.scss";

interface DateFilterWrapProps {
  checked: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const DateFilterWrap = ({ onChange, checked }: DateFilterWrapProps) => {
  return (
    <label className="sideFilterWrap">
      <input type="checkbox" className="openFilter" />
      <FrontBlock frontFilterIcon={"faction"} frontFilterName={"Date"} />
      <div className="popupFilter">
        <RadioButton
          value={""}
          radioName={"faction"}
          checked={checked}
          onChange={onChange}
        />
        <RadioButton
          value={"seed"}
          radioName={"faction"}
          checked={checked}
          onChange={onChange}
        />
        <RadioButton
          value={"ai"}
          radioName={"faction"}
          checked={checked}
          onChange={onChange}
        />
        <RadioButton
          value={"seedCopy"}
          radioName={"faction"}
          checked={checked}
          onChange={onChange}
        />
        <RadioButton
          value={"augmented"}
          radioName={"faction"}
          checked={checked}
          onChange={onChange}
        />
        <RadioButton
          value={"raw"}
          radioName={"faction"}
          checked={checked}
          onChange={onChange}
        />
        <RadioButton
          value={"aiCopy"}
          radioName={"faction"}
          checked={checked}
          onChange={onChange}
        />
        <RadioButton
          value={"augmentedCopy"}
          radioName={"faction"}
          checked={checked}
          onChange={onChange}
        />
      </div>
    </label>
  );
};

export default DateFilterWrap;
