import { Container, Section } from "./styledTags";
import T1 from "assets/About/Tier_1_Gif.gif";
import T2 from "assets/About/Tier_2_Gif.gif";
import T3 from "assets/About/Tier_3_Gif.gif";
import Tier1 from "assets/Tierage/tier1.png";
import Tier2 from "assets/Tierage/tier2.png";
import Tier3 from "assets/Tierage/tier3.png";
import Tier4 from "assets/Tierage/tier4.png";
import T4 from "assets/About/Tier_4_Gif.gif";
import Slider, { DataItem } from "components/Carousel/Slider";

const Preview = () => {
  //carousol slider data test
  const data: DataItem[] = [
    {
      name: "Tier 1 - Raw Ring Legends",
      information: "Information",
      description:
        "Unaltered, rare images capturing global fighters in iconic moments. Each image pairs with winning fighter stats for our trading card game. Collect history in its purest form.",
      facts: [
        {
          header: `Release:`,
          topFact: "Q3",
          bottomFact: "2024",
        },

        // {
        //   header: `Total`,
        //   topFact: "TBD",
        //   bottomFact: "?%",
        // },
        // {
        //   header: `Total`,
        //   topFact: "TBD",
        //   bottomFact: "?%",
        // },
        // {
        //   header: `Total`,
        //   topFact: "TBD",
        //   bottomFact: "?%",
        // },
      ],
      photo: T1,
      slider: Tier1,
    },
    {
      name: "Tier 2 - Dynamic Fight Art",
      information: {},
      description:
        "Witness fighters in motion as altered images simulate movement, adding life to each iconic moment. Paired with winning fighter stats for our trading card game. Embrace the action.",
      facts: [
        {
          header: `Release:`,
          topFact: "Q3",
          bottomFact: "2024",
        },

        // {
        //   header: `Total`,
        //   topFact: "TBD",
        //   bottomFact: "?%",
        // },
        // {
        //   header: `Total`,
        //   topFact: "TBD",
        //   bottomFact: "?%",
        // },
        // {
        //   header: `Total`,
        //   topFact: "TBD",
        //   bottomFact: "?%",
        // },
      ],
      photo: T2,
      slider: Tier2,
    },
    {
      name: "Tier 3 - AI-Infused Classics",
      information: {
        created: "15/10/23",
        colorProfile: "blue",
        Dimensions: "blue",
      },
      description:
        "AI merges historical fight images, crafting captivating new art within digital frames. Paired with fighter stats for your collection. Revive the past with innovation.",
      facts: [
        {
          header: `Release:`,
          topFact: "Q3",
          bottomFact: "2024",
        },

        // {
        //   header: `Championships`,
        //   topFact: "TBD",
        //   bottomFact: "?%",
        // },
        // {
        //   header: `Consecutive wins`,
        //   topFact: "TBD",
        //   bottomFact: "?%",
        // },
        // {
        //   header: `Win/Loss %`,
        //   topFact: "TBD",
        //   bottomFact: "?%",
        // },
      ],
      photo: T3,
      slider: Tier3,
    },
    {
      name: "Tier 4 - Fighter Chronicles",
      information: {
        Created: "",
        Dimensions: "",
        Resolution: "",
        ColorSpace: "",
        ColorProfile: "",
      },
      description:
        "Delve into the intimate world of fighters. Experience the weigh-ins, intense press conferences, electrifying face-offs, captivating photoshoots & behind-the-scenes action.",
      facts: [
        {
          header: `Release:`,
          topFact: "Q1",
          bottomFact: "2024",
        },
        // {
        //   header: `Total`,
        //   topFact: "TBD",
        //   bottomFact: "?%",
        // },
      ],
      photo: T4,
      slider: Tier4,
    },
  ];

  return (
    <Section id="preview">
      <Container>
        <Slider data={data} startIndex={data.length - 1} />
      </Container>
    </Section>
  );
};

export default Preview;
