import { useEffect, useState } from "react";
import { ImageContainer, Item, ItemNFT, Name, Position } from "./styledTags";
import "./styles.css";
import Sphere from "components/Sphere/Sphere";

export const NFTComponent1 = ({
  footer = null,
  className = "",
  leftHeader = null,
  leftValue = null,
  rightHeader = null,
  rightValue = null,
  price = 0,
  fee = 0,
  currency = "£",
  leftMethod = () => {},
  rightMethod = () => {},
  setQuantity = (e: React.ChangeEvent<HTMLInputElement>) => {},
  leftAvater = "",
  rightAvater = "",
  raffleNames = [""],
  container = "container",
  avaialability = false,
}) => {
  useEffect(() => {}, [raffleNames]);

  return (
    <ItemNFT id="raffle_card" className={`card ${className}`}>
      <div className="card__body">
        <div className="card__top">
          <div className="card__owner">
            <div className="card__avatar">{leftAvater}</div>
            <div className="card__user">
              <span className="card__user__title">{leftHeader}</span>
              <span className="card__user__code">{leftValue}</span>
            </div>
          </div>
          <div className="card__creator">
            <div className="card__avatar">{rightAvater}</div>
            <div className="card__user">
              <span className="card__user__title">{rightHeader}</span>
              <span className="card__user__code">{rightValue}</span>
            </div>
          </div>
        </div>
        <div className="card__image2">
          <ImageContainer>
            <Sphere
              tags={[...raffleNames]
                // .filter((x: string) => x !== "0x0000000000000000000000000000000000000000")
                .sort((a, b) =>
                  a === "0x0000000000000000000000000000000000000000" ? 1 : -1
                )

                .map((x: string) => x.substring(0, 4))}
              container={container}
            />
          </ImageContainer>
        </div>
      </div>
      <div className="card__info">
        <p>
          <b>Price:</b> ETH {price}
        </p>
        <p>({`${currency}${fee}`})</p>
      </div>
      <div className="card__footer">
        <input
          disabled={!avaialability}
          type="number"
          max={1}
          min={1}
          placeholder="Quantity"
          className="card__btn card__btn--secondary"
          onChange={(e) => {
            const num: number = Number(e.target.value);
            if (num > 20) e.target.value = "20";
            setQuantity(e);
          }}
        />
        <input
          disabled={!avaialability}
          type="button"
          value="Buy Now"
          className="card__btn card__btn--primary"
          onClick={() => rightMethod()}
        />
      </div>
      <Position>{footer ? `Capacity ${footer}` : "Lawrence Lustig X"}</Position>
    </ItemNFT>
  );
};
