import styled from "styled-components";

export const Section = styled.section`
  min-height: 100vh;
  width: 100vw;
  // background-color: ${(props) => props.theme.body};
  background-color: black;
  position: relative;
  overflow: hidden;
`;

export const Title = styled.h1`
  font-size: ${(props) => props.theme.fontxxl};
  text-transform: capitalize;
  color: ${(props) => props.theme.text};
  color: rgb(255 255 255);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem auto;
  // border-bottom: 2px solid ${(props) => props.theme.text};
  width: fit-content;

  @media (max-width: 40em) {
    font-size: ${(props) => props.theme.fontxl};
  }
`;

export const Container = styled.div`
  width: 75%;
  margin: 2rem auto;

  display: flex;
  // justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  @media (max-width: 64em) {
    width: 80%;
  }
  @media (max-width: 48em) {
    width: 90%;
    justify-content: center;
  }
`;

export const Item = styled.div`
  width: calc(20rem - 4vw);
  padding: 1rem 0;
  color: ${(props) => props.theme.body};
  margin: 2rem 1rem;
  position: relative;
  z-index: 5;

  backdrop-filter: blur(4px);

  border: 2px solid ${(props) => props.theme.text};
  border-radius: 20px;

  &:hover {
    img {
      transform: translateY(-2rem) scale(1.2);
    }
  }

  @media (max-width: 30em) {
    width: 70vw;
  }
`;

export const ImageContainer = styled.div`
  width: 80%;
  margin: 0 auto;
  background-color: ${(props) => props.theme.carouselColor};
  border: 1px solid ${(props) => props.theme.text};
  padding: 1rem;

  border-radius: 20px;
  cursor: pointer;

  img {
    width: 100%;
    height: auto;
    transition: all 0.3s ease;
  }
`;

export const Name = styled.h2`
  font-size: ${(props) => props.theme.fontlg};
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  color: ${(props) => props.theme.text};
  margin-top: 1rem;
`;

export const Position = styled.h2`
  font-size: ${(props) => props.theme.fontmd};
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
  color: ${(props) => `rgba(${props.theme.textRgba},0.9)`};
  font-weight: 400;
`;

export const Div1 = styled.div`
  block-size: 2434px;
  border-block-end-color: rgb(229, 231, 235);
  border-block-end-style: solid;
  border-block-start-color: rgb(229, 231, 235);
  border-block-start-style: solid;
  border-inline-end-color: rgb(229, 231, 235);
  border-inline-end-style: solid;
  border-inline-start-color: rgb(229, 231, 235);
  border-inline-start-style: solid;
  box-sizing: border-box;
  caret-color: rgb(38, 38, 38);
  color: rgb(38, 38, 38);
  column-gap: 16px;
  column-rule-color: rgb(38, 38, 38);
  display: flex;
  flex-wrap: wrap;
  overflow: scroll;
  // height: 2434px;
  inline-size: 1920px;
  justify-items: center;
  min-block-size: auto;
  // max-height: 100vh;
  height: auto;
  min-inline-size: auto;
  min-width: auto;
  perspective-origin: 960px 1217px;
  row-gap: 16px;
  tab-size: 4;
  text-align: center;
  text-decoration: none solid rgb(38, 38, 38);
  text-emphasis-color: rgb(38, 38, 38);
  text-size-adjust: 100%;
  transform-origin: 960px 1217px;
  width: 1920px;
  border: 0px solid rgb(229, 231, 235);
  font: 16px / 24px Rubik, ui-sans-serif, system-ui, -apple-system, "system-ui",
    "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  outline: rgb(38, 38, 38) none 0px;
  // padding: 0px 160px 384px;
  justify-content: space-evenly;
  background-color: rgb(32, 32, 32);
  border-radius: 25px;
`;

export const Div2 = styled.div`
  block-size: 436px;
  border-block-end-color: rgb(229, 231, 235);
  border-block-end-style: solid;
  border-block-start-color: rgb(229, 231, 235);
  border-block-start-style: solid;
  border-end-end-radius: 12px;
  border-end-start-radius: 12px;
  border-inline-end-color: rgb(229, 231, 235);
  border-inline-end-style: solid;
  border-inline-start-color: rgb(229, 231, 235);
  border-inline-start-style: solid;
  border-start-end-radius: 12px;
  border-start-start-radius: 12px;
  bottom: 0px;
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.1) 0px 4px 6px -4px;
  box-sizing: border-box;
  caret-color: rgb(38, 38, 38);
  color: rgb(38, 38, 38);
  column-rule-color: rgb(38, 38, 38);
  height: 436px;
  inline-size: 320px;
  inset-block-end: 0px;
  inset-block-start: 0px;
  inset-inline-end: 0px;
  inset-inline-start: 0px;
  left: 0px;
  margin-block-end: 32px;
  margin-block-start: 32px;
  max-inline-size: 320px;
  max-width: 320px;
  min-block-size: auto;
  min-height: auto;
  min-inline-size: auto;
  min-width: auto;
  perspective-origin: 160px 218px;
  position: relative;
  right: 0px;
  tab-size: 4;
  text-align: center;
  text-decoration: none solid rgb(38, 38, 38);
  text-emphasis-color: rgb(38, 38, 38);
  text-size-adjust: 100%;
  top: 0px;
  transform-origin: 160px 218px;
  width: 320px;
  background: rgb(255, 255, 255) none repeat scroll 0% 0% / auto padding-box
    border-box;
  border: 0px solid rgb(229, 231, 235);
  border-radius: 12px;
  font: 16px / 24px Rubik, ui-sans-serif, system-ui, -apple-system, "system-ui",
    "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  margin: 32px 0px;
  outline: rgb(38, 38, 38) none 0px;
  overflow: hidden;
`;

export const Img = styled.img`
  block-size: 320px;
  border-block-end-color: rgb(229, 231, 235);
  border-block-end-style: solid;
  border-block-start-color: rgb(229, 231, 235);
  border-block-start-style: solid;
  border-end-end-radius: 16px;
  border-end-start-radius: 16px;
  border-inline-end-color: rgb(229, 231, 235);
  border-inline-end-style: solid;
  border-inline-start-color: rgb(229, 231, 235);
  border-inline-start-style: solid;
  border-start-end-radius: 16px;
  border-start-start-radius: 16px;
  box-sizing: border-box;
  caret-color: rgb(38, 38, 38);
  color: rgb(38, 38, 38);
  column-rule-color: rgb(38, 38, 38);
  display: block;
  height: 320px;
  inline-size: 320px;
  max-inline-size: 100%;
  max-width: 100%;
  padding-block-end: 8px;
  padding-block-start: 8px;
  padding-inline-end: 8px;
  padding-inline-start: 8px;
  perspective-origin: 160px 160px;
  tab-size: 4;
  text-align: center;
  text-decoration: none solid rgb(38, 38, 38);
  text-emphasis-color: rgb(38, 38, 38);
  text-size-adjust: 100%;
  transform-origin: 160px 160px;
  vertical-align: middle;
  width: 320px;
  border: 0px solid rgb(229, 231, 235);
  border-radius: 16px;
  font: 16px / 24px Rubik, ui-sans-serif, system-ui, -apple-system, "system-ui",
    "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  outline: rgb(38, 38, 38) none 0px;
  padding: 8px;
`;

export const Div3 = styled.div`
  block-size: 116px;
  border-block-end-color: rgb(229, 231, 235);
  border-block-end-style: solid;
  border-block-start-color: rgb(229, 231, 235);
  border-block-start-style: solid;
  border-inline-end-color: rgb(229, 231, 235);
  border-inline-end-style: solid;
  border-inline-start-color: rgb(229, 231, 235);
  border-inline-start-style: solid;
  box-sizing: border-box;
  caret-color: rgb(38, 38, 38);
  color: rgb(38, 38, 38);
  column-rule-color: rgb(38, 38, 38);
  height: 116px;
  inline-size: 320px;
  padding-block-end: 16px;
  padding-block-start: 16px;
  padding-inline-end: 24px;
  padding-inline-start: 24px;
  perspective-origin: 160px 58px;
  tab-size: 4;
  text-align: center;
  text-decoration: none solid rgb(38, 38, 38);
  text-emphasis-color: rgb(38, 38, 38);
  text-size-adjust: 100%;
  transform-origin: 160px 58px;
  width: 320px;
  border: 0px solid rgb(229, 231, 235);
  font: 16px / 24px Rubik, ui-sans-serif, system-ui, -apple-system, "system-ui",
    "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  outline: rgb(38, 38, 38) none 0px;
  padding: 16px 24px;
`;

export const Div4 = styled.div`
  block-size: 28px;
  border-block-end-color: rgb(229, 231, 235);
  border-block-end-style: solid;
  border-block-start-color: rgb(229, 231, 235);
  border-block-start-style: solid;
  border-inline-end-color: rgb(229, 231, 235);
  border-inline-end-style: solid;
  border-inline-start-color: rgb(229, 231, 235);
  border-inline-start-style: solid;
  box-sizing: border-box;
  caret-color: rgb(38, 38, 38);
  color: rgb(38, 38, 38);
  column-rule-color: rgb(38, 38, 38);
  height: 28px;
  inline-size: 272px;
  margin-block-end: 8px;
  perspective-origin: 136px 14px;
  tab-size: 4;
  text-align: center;
  text-decoration: none solid rgb(38, 38, 38);
  text-emphasis-color: rgb(38, 38, 38);
  text-size-adjust: 100%;
  transform-origin: 136px 14px;
  width: 272px;
  border: 0px solid rgb(229, 231, 235);
  font: 700 20px / 28px Rubik, ui-sans-serif, system-ui, -apple-system,
    "system-ui", "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans",
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    "Noto Color Emoji";
  margin: 0px 0px 8px;
  outline: rgb(38, 38, 38) none 0px;
`;

export const P = styled.p`
  block-size: 24px;
  border-block-end-color: rgb(229, 231, 235);
  border-block-end-style: solid;
  border-block-start-color: rgb(229, 231, 235);
  border-block-start-style: solid;
  border-inline-end-color: rgb(229, 231, 235);
  border-inline-end-style: solid;
  border-inline-start-color: rgb(229, 231, 235);
  border-inline-start-style: solid;
  box-sizing: border-box;
  caret-color: rgb(55, 65, 81);
  color: rgb(55, 65, 81);
  column-rule-color: rgb(55, 65, 81);
  height: 24px;
  inline-size: 240px;
  margin-block-end: 16px;
  margin-block-start: 16px;
  margin-inline-end: 16px;
  margin-inline-start: 16px;
  perspective-origin: 120px 12px;
  tab-size: 4;
  text-align: center;
  text-decoration: none solid rgb(55, 65, 81);
  text-emphasis-color: rgb(55, 65, 81);
  text-size-adjust: 100%;
  transform-origin: 120px 12px;
  width: 240px;
  border: 0px solid rgb(229, 231, 235);
  font: 16px / 24px Rubik, ui-sans-serif, system-ui, -apple-system, "system-ui",
    "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  margin: 16px;
  outline: rgb(55, 65, 81) none 0px;
`;
