
import CollectionCard from 'components/Card/Collection Card/CollectionCard';
// import Header from './components/Header/Header';
import { useState, useEffect } from 'react'
import NFTModal from 'components/NFTMain/NFTModal';
import UserNFTGrid from 'components/Explore/UserNFTGrid';
import { ExploreNFT, ExploreNFTFilter } from 'types/react-app-env';
import { ExploreApp, Section } from './styledTags';
import { getNFTS } from 'Helpers/GetNFTS';
import DataGridNFT from 'components/Explore/Navigation/DataGridNFT';
import { ethers } from 'ethers';
import StandardTokenArtifacts from "../../contracts/SmartContract721.json";
import { StandardToken } from 'types/StandardToken.sol/StandardToken';
import toast, { Toaster } from "react-hot-toast";
import { SmartContractHelper } from 'Helpers/BaseContractHelper';
import { NFT } from 'types/NFT/BaseContract';

declare global {
    interface Window {
        ethereum: ethers.providers.ExternalProvider;
    }
}

const UserNFTS = () => {
    const [nftListData, setNFTListData] = useState<NFT[]>([]);

    const [selectedNFT, setselectedNFT] = useState<number>(null);
    const [nftFilteredListData, setNFTFilteredListData] = useState<NFT[]>([]);

    const handleDataGridDataChange = (nftIds: number[]) => {
        if (nftListData.length > 0 && nftIds) {
            console.log("data:", nftIds)
            setNFTFilteredListData(
                nftListData.filter(
                    item => nftIds.includes(item.tokenId)
                ).sort((a, b) => nftIds.indexOf(a.tokenId) - nftIds.indexOf(b.tokenId))
            )
        }
    }

    const HandleUserCards = async () => {
        const smartContract = new SmartContractHelper();
    
        const tokens: NFT[] = await smartContract.getMyTokens();
        console.log("Tokens:", tokens);
    
        setNFTListData(
            // tokens.map((token, index) => ({
            //     // id: Number(token.index),
            //     id: index,
            //     NFT: token.ipfs.image,
            //     description: token.ipfs.description,
            //     name: token.ipfs.name,
            //     author: token.ipfs.author,
            //     collection: token.ipfs.collectionType,
            //     collectionType: token.ipfs.collectionType,
            //     tier: Number(token.tier),
            //     knight: Number(token.ipfs.championships) ?? null,
            //     rook: Number(token.ipfs.consecutiveWins) ?? null,
            //     bishop: Number(token.ipfs.wins) ?? null
            // }))
            tokens
        )
      }
    
    const getMyNfts = async () => {
        // setpunkListData(null);
        if (nftListData.length == 0) {
            HandleUserCards();
            console.log("how many times am i being called")
        }
    }

    useEffect(() => {
        getMyNfts();
    }, []);

    return (
        <Section id="exploreNfts">
            {nftListData.length > 0 && (
                <>
                    {/* <DataGridNFT
                        data={nftListData}
                        onDataChange={handleDataGridDataChange}
                    hideGrid={true}
                    /> */}

                    <ExploreApp className='app'>
                        {/* <Header /> */}
                        <>
                            {/* <NFTModal
                                nftListData={nftFilteredListData}
                                selectedNFT={selectedNFT}
                            /> */}
                            {/* <ExploreNav handleFilter={filterList} /> */}
                            {/* <UserNFTGrid
                                nftListData={nftFilteredListData} SetSelectedNFT={setselectedNFT}
                            /> */}
                        </>

                    </ExploreApp>

                </>
            )}
        </Section>
    )
}

export default UserNFTS;
