import { ImageContainer, Item, ItemNFT, Name, Position } from "./styledTags";
import "./styles.css";
import GGGpicture from "assets/GGGvsBrook.png"; //default pic 
import { useEffect, useState } from "react";
import tier1 from "assets/Tierage/tier1.png";
import tier2 from "assets/Tierage/tier2.png";
import tier3 from "assets/Tierage/tier3.png";

interface BuyProps {
  img: string;
  name: string;
  footer: string;
  className: string;
  leftHeader: string;
  leftValue: string;
  rightHeader: string;
  rightValue: string;
  price: number;
  fee: number;
  leftAvater: string;
  rightAvater: string;
  handlePurchase: () => void;
  setQuantity: (e: number) => void;
}

export const NFTComponent1 = ({
  img,
  name,
  footer,
  className,
  leftHeader,
  leftValue,
  rightHeader,
  rightValue,
  price,
  fee,
  leftAvater,
  rightAvater,
  handlePurchase,
  setQuantity,
}: BuyProps) => {
  const [q, setQ] = useState<number>(1);

  const handleQuantity = (q: number) => {
    setQ(q);
    setQuantity(q);
  };

  const handleTierage = (tier: string): any => {
    switch (Number(tier)) {
      case 1:
        return <img className="tier" src={tier1} alt="Tier1" />;
      case 2:
        return <img className="tier" src={tier2} alt="Tier2" />;
      case 3:
        return <img className="tier" src={tier3} alt="Tier3" />;
      case 4:
        return <img className="tier" src={null} alt="Tier4" />;
      default:
        return <p>N/A</p>;
    }
  };

  useEffect(() => {
    //handle setting of Quantity
  }, [q]);

  return (
    <ItemNFT className={`card ${className}`}>
      <div className="card__body">
        <div className="card__top">
          <div className="card__owner">
            <div className="card__avatar">{handleTierage(leftAvater)}</div>
            <div className="card__user">
              <span className="card__user__title">
                {leftHeader ?? "Owned by"}
              </span>
              <span className="card__user__code">{leftValue ?? "2304RC"}</span>
            </div>
          </div>
          <div className="card__creator">
            <div className="card__avatar">{rightAvater}</div>
            <div className="card__user">
              <span className="card__user__title">
                {rightHeader ?? "Created by"}
              </span>
              <span className="card__user__code">{rightValue ?? "20AR02"}</span>
            </div>
          </div>
        </div>
        <div className="card__image">
          <ImageContainer>
            <img src={img ?? GGGpicture} alt="" />
          </ImageContainer>
        </div>
      </div>
      <div className="card__info">
        <p>
          <b>Price:</b> ETH {price}
        </p>
        <p>(£{fee > 0 ? fee : "..."})</p>
      </div>
      <div className="card__footer">
        <input
          type="number"
          max={20}
          min={1}
          placeholder="Quantity"
          className="card__btn card__btn--secondary"
          maxLength={20}
          onChange={(e) => {
            handleQuantity(Number(e.target.value));
          }}
          value={q}
        />
        <input
          value="Buy Now"
          type="button"
          className="card__btn card__btn--primary"
          onClick={() => handlePurchase()}
        />
      </div>
      <Position>{footer ?? "Lawrence Lustig X"}</Position>
    </ItemNFT>
  );
};
