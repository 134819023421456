import "components/DataGrid/DataGrid.scss";
import { ReactNode, useEffect, useMemo, useRef, useState } from "react";
import DataGrid from "components/DataGrid/DataGrid";
import { ExploreNFT, ExploreNFTFilter } from "types/react-app-env";
import { getNFTS } from "Helpers/GetNFTS";
import { mapColumnz, removeEmpty } from "Helpers/DataGridHelper";
import { GridColDef } from "@mui/x-data-grid";

const obj: ExploreNFT = {
  id: 0,
  NFT: "",
  author: "Lawrence Lustig",
  name: "",
  tier: 1,
  collection: "Lawrence Lustig",
  description: "template",
  collectionType: "Boxing",
  winLossRatio: Math.floor(Math.random() * 10),
  championships: Math.floor(Math.random() * 10),
  consecutiveWins: Math.floor(Math.random() * 10),
};

interface DataGridProps {
  data: ExploreNFT[];
  onDataChange: (x: number[]) => void;
  showUserTokens?: boolean;
  handleShowUserTokens?: () => void;
  hideGrid?: boolean;
}
const NFTDataGrid = <T extends Record<any, T>>({
  data,
  onDataChange: onChange,
  hideGrid,
  showUserTokens, 
  handleShowUserTokens
}: DataGridProps) => {
  const apiRef: React.MutableRefObject<T> = useRef(null);

  const [filtered, setFiltered] = useState<number[]>();

  const [isHidden, setIsHidden] = useState<boolean>(hideGrid ?? false);

  const cols = useMemo<GridColDef<ExploreNFT>[]>(
    () => mapColumnz(obj, apiRef),
    []
  );

  const handleHideTableChange = () => {
    setIsHidden(!isHidden);
  };

  useEffect(() => {
    //need a loader here or a check for data
    console.log(`DataGridNFT.Filtered: ${filtered}`);
    console.log("hidden:", isHidden);

    if (isHidden) {
      onChange(filtered);
    } else {
      onChange([]);
    }
  }, [filtered, isHidden]);

  //DATA
  const HandleStateChange = () => {
    // console.log("APIREF:", apiRef);

    if (apiRef.current) {
      const state = apiRef.current?.state;
      //console.log("DataGridNFT.state:", state);

      const filteredIds: number[] = [];

      //Visible NFT Ids
      Object.keys(state.filter?.filteredRowsLookup).map((index) => {
        let isVisible = state.filter?.filteredRowsLookup[index];

        //check variable
        if (isVisible && isVisible) filteredIds.push(Number.parseInt(index));
      });

      //Sorted Order !<does not respect filter>!:
      Object.keys(state.sorting?.sortedRows).map((index) => {
        let nftId = state.sorting?.sortedRows[index];

        //check is number
        if (typeof Number.parseInt(index) === typeof 0)
          return Number.parseInt(`${nftId}`);
      });

      setFiltered(
        removeEmpty(
          Object.keys(state.sorting?.sortedRows).map((index) => {
            let nftId = state.sorting?.sortedRows[index];

            //check is number
            if (
              typeof Number.parseInt(index) === typeof 0 &&
              filteredIds.includes(Number.parseInt(`${nftId}`))
            ) {
              return Number.parseInt(`${nftId}`);
            }
            return null;
          })
        )
      );

      // console.log(`filteredIds: ${filteredIds}`);
    }
  };

  return (
    <div className="table">
      <DataGrid
        data={data}
        columns={cols}
        onDataChange={HandleStateChange}
        hideTable={isHidden}
        onHideTableChange={handleHideTableChange}
        userTokens={showUserTokens}
        onUserTokensChange={handleShowUserTokens}
      />
    </div>
  );
};

export default NFTDataGrid;
