import React from "react";
import RadioButtonProv from "../../../UI/RadioButtonProv/RadioButtonProv";
import "./ProvisionFilter.scss";

interface ProvisionFilterProps {
  checked: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  filters: string[];
}

const ProvisionFilter = ({
  checked,
  onChange,
  filters,
}: ProvisionFilterProps) => {
  return (
    <div className="filterBlock__provWrap clearfix">
      {filters.map((key, index) => (
        <RadioButtonProv
          key={key}
          value={key}
          radioName={"provision"}
          checked={key === checked}
          onChange={onChange}
          label={key}
        />
      ))}
    </div>
  );
};

export default ProvisionFilter;
