import NFTInfo from "Entites/NFT";
import { Contract, ethers } from "ethers";
import { BigNumber } from "ethers";

export const ContractAddress = "0xc7D988A4C16D5B375C6114853b33B71594f00CD9";
//0x39620C3a07C111bC325AA8ee77082D41f2B08078

export interface NFT {
  tokenId?: number;
  ipfs: MetaDataInfo;
  tier: number;
  collection: number;
  consecutiveWins: number;
  championships: number;
  winLossRatio: number;
  disintergrate?: boolean;
  // giffURL?: string
}

export type MetaDataInfo = {
  name: string;
  description?: string;
  image: string;
  attributes?: Attributes[];
  // tier?: string | number;
};

interface Attributes {
  trait_type: string;
  value: MetaDataAttributeValueProps;
}

export type MetaDataAttributeValueProps = string | number | boolean | null;

export interface NFTData {
  tokenId?: number;
  uriHash: string;
  tier: number;
  collection: number;
  consecutiveWins: number;
  championships: number;
  winLossRatio: number;
  available?: boolean;
}

export interface SmartContract extends Contract {
  name: string;
  symbol: string;
  raffle: string;
  game: string;

  getAllTokens(): Promise<NFT[]>;
  owner(): Promise<string>;
  getMyTokens(): Promise<NFTInfo[]>;
  getToken(tokenId: number): Promise<NFTInfo>;
  getNFTInfo(tier: number, collection: number): Promise<NFT[]>;
  randomNum(tier: number): Promise<number>;
  getAvailableTokens(tier: number): Promise<number>;
  checkTokenIsApproved(tokenId: number): Promise<boolean>;
  claimNFT(claimer: string): Promise<void>;
  lockNFT(player: string, id: number): Promise<void>;

  // Methods from ERC721 Standard
  approve(to: string, tokenId: number): Promise<void>;
  setApprovalForAll(operator: string, approved: boolean): Promise<void>;
  transferFrom(from: string, to: string, tokenId: number): Promise<void>;
  getApproved(tokenId: number): Promise<string>;
  isApprovedForAll(owner: string, operator: string): Promise<boolean>;

  // Overridden methods from ERC721 Standard
  safeTransferFrom(from: string, to: string, tokenId: number): Promise<void>;
  safeTransferFrom(
    from: string,
    to: string,
    tokenId: number,
    data: any
  ): Promise<void>;

  // Additional methods from BaseContract
  getCurrentTokenId(): Promise<number>;
  incrementTokenId(tier: number): Promise<void>;
  BuyPack(
    quantity: number,
    packType: number,
    overrides?: ethers.PayableOverrides
  ): Promise<ethers.ContractTransaction>;
  getCurrentTokenId(): Promise<number>;
  incrementTokenId(): Promise<void>;
  setMaxMintAmountPerTx(_maxMintAmount: number): Promise<void>;
  _setPack(
    packId: number,
    tiers: number[],
    _defaultUriHash: string
  ): Promise<void>;
  _assignAvaialableMints(
    tierQuantities: number[],
    _currentCollection: number
  ): Promise<void>;
  setTokenFees(tier: number, newFeeInEther: number): Promise<void>;
  addToWhitelist(): Promise<void>;
  removeFromWhitelist(user: string): Promise<void>;
  isWhitelisted(user: string): Promise<boolean>;
  // BuyRandomNFT(
  //   nftData: NFTData[],
  //   tier: number,
  //   overrides?: ethers.PayableOverrides
  // ): Promise<ethers.ContractTransaction>;

  // Methods from your custom contract
  GetAvaialbleTokenIDs(tier: number): Promise<number>;
  tokenExists(tokenId: number): Promise<boolean>;
  mint(tierQuantities: number[], amount: number): Promise<void>;
  fee(packType: number): Promise<number>;
  setTokenFees(tier: number, newFeeInEther: number): Promise<void>;
  addToWhitelist(): Promise<void>;
  removeFromWhitelist(user: string): Promise<void>;
  isWhitelisted(user: string): Promise<boolean>;
}
