// This file contains variables for different themes
import "styled-components";
import { DefaultTheme } from "styled-components";

export const light: DefaultTheme = {
    
    body:"#fff",
    text:"#202020", // black shade
    bodyRgba : "255, 255, 255",
    textRgba:"32,32,32",

    carouselColor: 'ghostwhite',

    fontxs:'0.75em',
    fontsm:'0.875em',
    fontmd:'1em', // 1em = 16px
    fontlg:'1.25em',
    fontxl:'2em',
    fontxxl:'3em',
    fontxxxl:'4em',

    fontButton:'0.875em',


    navHeight: '5rem',
}


export const dark: DefaultTheme = {
    
    body:"#202020",
    text: "#fff", // black shade
    bodyRgba :"32,32,32" ,
    textRgba: "255, 255, 255",

    //carouselColor: '#EEEDDE',
    carouselColor: 'ghostwhite',

    fontxs:'0.75em',
    fontsm:'0.875em',
    fontmd:'1em', // 1em = 16px
    fontlg:'1.25em',
    fontxl:'2em',
    fontxxl:'3em',
    fontxxxl:'4em',

    fontButton:'0.875em',


    navHeight: '5rem',
}