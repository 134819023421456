import styled from "styled-components";

export const Section = styled.section`
  min-height: ${(props) => `calc(100vh - ${props.theme.navHeight})`};
  width: 100vw;
  overflow: scroll;
  height: 500px;
  // position: relative;
  // background-color: ${(props) => props.theme.body};

  // position: absolute;
  // z-index: 1;
  // opacity: 0.7;

  //move the dapp with scroll
  position: fixed;
  z-index: 1;

  // Apply position: fixed and higher z-index on larger screens
  @media (min-width: 768px) {
    z-index: 2;
  }

  // Apply position: relative on smaller screens
  @media (max-width: 767px) {
    // position: relative;
  }
`;

export const Container = styled.div`
  width: 75%;
  min-height: 20vh;
  margin: 0 auto;
  /* background-color: lightblue; */

  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 64em) {
    width: 85%;
  }
  @media (max-width: 48em) {
    flex-direction: column-reverse;
    width: 100%;
    & > *:first-child {
      width: 100%;
      margin-top: 2rem;
    }
  }
`;
export const NFTContainer = styled.div`
  width: 85%;
  margin: 2rem auto;

  height: auto; /* Remove the fixed height */
  overflow-y: hidden; /* Hide the vertical scrollbar by default */

  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  @media (max-width: 64em) {
    justify-content: center;
    width: 80%;
  }
  @media (max-width: 48em) {
    width: 90%;
    justify-content: center;
    overflow-y: auto; /* Enable vertical scrolling on mobile devices */
    max-height: calc(
      100vh - 300px
    ); /* Set a maximum height for the container on mobile devices */
  }
`;
