import React, { useEffect, useState, useRef, useLayoutEffect } from "react";
import { ExploreApp, Section } from "./styledTags";
import Card from "components/CardUI";
import GameHome, {
  GameModeEnum,
  GameTypeEnum,
  SelectionEnum,
} from "./components/GameHome";
import Stadium from "assets/Game/Backgrounds/Stadium.gif";
import Arena from "assets/Game/Backgrounds/Foyer.gif";
import Walk from "assets/Game/Backgrounds/TheWalk.gif";
import LockerRoom from "assets/Game/Backgrounds/LockerRoom.gif";
import RingPane from "assets/Game/Backgrounds/RingPane.gif";

const Game = () => {
  const [visible, setVisible] = useState(false);
  const [count, setCount] = useState(10);
  const [selection, setSelection] = useState(SelectionEnum.Home);
  const [gameType, setGameType] = useState(GameTypeEnum.None);
  const [gameMode, setGameMode] = useState(GameModeEnum.None);
  const [backgroundImage, setBackgroundImage] = useState(Stadium);

  const handleToggle = () => {
    handleEnumChange(SelectionEnum.Home, GameTypeEnum.None, GameModeEnum.None);

    // setVisible(!visible);
    setVisible((prevVisible) => !prevVisible);
  };

  const handleClick = () => setCount((prevCount) => prevCount + 1);

  const nft: any = {};

  const handleEnumChange = (selection, gameType, gameMode) => {
    // Handle the updated enums in the parent component
    // You can perform any necessary actions or state updates here
    if (selection) setSelection(selection);

    if (gameType) setGameType(gameType);

    if (gameMode) setGameMode(gameMode);

    let newBackgroundImage = Stadium;

    // if (
    //   !visible &&
    //   selection === SelectionEnum.Home &&
    //   gameType === GameTypeEnum.None &&
    //   gameMode === GameModeEnum.None
    // ) {
    //   newBackgroundImage = Stadium;
    // } else if (
    //   visible &&
    //   gameType === GameTypeEnum.None &&
    //   gameMode === GameModeEnum.None
    // ) {
    //   newBackgroundImage = Arena;
    // } else if(selection !== undefined)
  };

  useEffect(() => {
    // Handle Game modes based on the selectionType
    // When not Onchain and Game Type is null, modes are defaulted
    // console.log("Selection:", selection);
    // console.log("Game Type:", gameType);
    // console.log("Game Mode:", gameMode);
    // console.log("visible:", visible);

    let newBackgroundImage = Stadium;

    if (
      visible &&
      gameMode !== GameModeEnum.None &&
      gameType !== GameTypeEnum.None
    ) {
      //When Game Mode is being chosen
      newBackgroundImage = RingPane;
    } else if (visible && gameType !== GameTypeEnum.None) {
      //When GameType is being Chosen
      newBackgroundImage = LockerRoom;
    } else if (visible && selection !== SelectionEnum.Home) {
      newBackgroundImage = Walk;
    } else if (visible) {
      newBackgroundImage = Arena;
    }

    setBackgroundImage(newBackgroundImage);
  }, [selection, gameType, gameMode, backgroundImage, visible]);

  return (
    <Section
      id="game"
      style={{
        background: `url(${backgroundImage})`,
      }}
    >
      {/* <Header /> */}
      <div
      // style={{
      //   height: `${visible ? "90vh" : ""}`,
      //   width: `${visible ? "100vh" : ""}`
      // }}
      >
        <button onClick={handleToggle}>{!visible ? "Enter" : "Home"}</button>
        {visible && (
          <GameHome
            onEnumChange={handleEnumChange}
            _selection={selection}
            _gameType={gameType}
            _gameMode={gameMode}
          />
        )}
      </div>
    </Section>
  );
};
export default Game;

// export default connect(mapStateToProps, mapDispatchToProps)(Navigation)
