import styled from "styled-components";

export const Container = styled.div`
  width: 75%;
  margin: 2rem auto;

  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  @media (max-width: 64em) {
    width: 80%;
  }
  @media (max-width: 48em) {
    width: 90%;
    justify-content: center;
  }
`;

export const Item = styled.div`
  width: calc(23rem - 4vw);
  padding: 1rem 0;
  color: ${(props) => props.theme.body};
  margin: 2rem 1rem;
  position: relative;
  // z-index:5;

  backdrop-filter: blur(4px);

  border: 2px solid ${(props) => props.theme.text};
  border-radius: 20px;

  &:hover {
    img {
      transform: translateY(-2rem) scale(1.2);
    }
  }

  @media (max-width: 30em) {
    width: 70vw;
  }
`;
export const ItemNFT = styled.div`
  // width: calc(23rem - 4vw);
  // padding: 1rem 0;
  // color: ${(props) => props.theme.body};
  // margin: 2rem 1rem;
  position: relative;
  // z-index:5;
  margin-bottom: 40px;

  backdrop-filter: blur(4px);

  // border: 2px solid ${(props) => props.theme.text};
  //border-radius: 20px;

  &:hover {
    img {
      transform: translateY(-2rem) scale(1.2);
    }
  }

  @media (max-width: 30em) {
    // width: 70vw;
  }
`;

export const ImageContainer = styled.div`
  // width: 80%;
  margin: 0 auto;
  background-color: ${(props) => props.theme.carouselColor};
  border: 1px solid ${(props) => props.theme.text};
  padding: 1rem;

  border-radius: 50%;
  cursor: pointer;

  img {
    width: 100%;
    height: 270px;
    border-radius: 15px;
    transition: all 0.3s ease;
  }
`;

export const Name = styled.h2`
  font-size: ${(props) => props.theme.fontlg};
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  color: ${(props) => props.theme.text};
  margin-top: 1rem;
`;

export const Position = styled.h2`
  font-size: ${(props) => props.theme.fontmd};
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
  color: ${(props) => `rgba(${props.theme.textRgba},0.9)`};
  font-weight: 400;
  font: bold 20px "Droid Sans", Arial, sans-serif;
`;
