import { createGlobalStyle } from "styled-components";
import "@fontsource/akaya-telivigala";
import "@fontsource/sora";

const GlobalStyles: any = createGlobalStyle`

${
  "" /* 
*{
    outline: 1px solid red !important;
} */
}

html {
    // overflow: hidden;
    width: 100%;
  }
  body {
    height: 100%;
    // position: fixed;
    /* prevent overscroll bounce*/
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    /* iOS velocity scrolling */
  }

*,*::before,*::after{
    margin: 0;
    padding: 0;
}

body{
    font-family: 'Sora', sans-serif;
    overflow-x: hidden;
}

h1,h2,h3,h4,h5,h6{
    margin: 0;
    padding: 0;
}
a{
    color: inherit;
    text-decoration:none;
}

.dropdown-menu.show{
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    height: 20px;
}
::-webkit-scrollbar {
    display: none;
}
.dropdown-menu{
    display: none;
}
`;

export default GlobalStyles;
