import { ContractAddress, Raffle } from "types/Raffle/Raffle";
import raffle from "contracts/Raffle.json";
import { ethers } from "ethers";

export type RaffleState = {
  size: ethers.BigNumber;
  bought: ethers.BigNumber;
  maxWinners: ethers.BigNumber;
  price: ethers.BigNumber;
  participants: string[];
  deadline: ethers.BigNumber;
  startTime: ethers.BigNumber;
};

export type RaffleStateProps = {
  size: number;
  bought: number;
  maxWinners: number;
  price: number;
  participants: string[];
  deadline: Date;
  startTime: Date;
};

class RaffleHelper {
  private contract: Raffle;

  constructor() {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();

    const contract = new ethers.Contract(
      ContractAddress,
      raffle.abi,
      // provider
      signer
    ) as Raffle;
    this.contract = contract;
  }

  async buyRaffle(raffleId: number): Promise<ethers.ContractTransaction> {
    const gasPrice = ethers.utils.parseUnits("20", "gwei"); // or any gas price you want to set

    const fee = (await this.contract.getRaffleState(raffleId)).price;
    const fee1 = ethers.utils.formatUnits(fee, "gwei");
    console.log("Parsed Fee => Gwei:", ethers.utils.formatUnits(fee, "gwei"));
    console.log("Parsed Fee => Num:", Number(fee));
    console.log("Parsed Fee => Eth:", ethers.utils.formatEther(fee));
    console.log("FEE:", ethers.utils.formatEther(fee));

    const overrides = {
      // value: ethers.utils.parseUnits(fee.toString(), "gwei"),
      value: fee,
      gasPrice: gasPrice,
    };
    console.log("override fees:", overrides);

    const transaction = await this.contract.buyRaffle(raffleId, overrides);

    console.log("transaction", transaction);

    await transaction.wait();

    console.log("Transaction:", transaction);

    return transaction;
  }

  async claimNFT(): Promise<ethers.ContractTransaction> {
    return await this.contract.claimNFT();
  }

  async setDeadline(
    id: number,
    newDeadline: number
  ): Promise<ethers.ContractTransaction> {
    return await this.contract.setDeadline(id, newDeadline);
  }

  async getRaffleState(id: number): Promise<RaffleStateProps> {
    const state = await this.contract.getRaffleState(id);

    // console.log(`State raffle ${id}:`, state);
    // console.log(`size: ${id}:`, ethers.BigNumber.from(state.size));
    // console.log(`Deadline date: ${id}`, new Date(state.deadline * 1000));
    // console.log(`start date: ${id}`, new Date(state.startTime * 1000));
    return {
      bought: Number(ethers.BigNumber.from(state.bought)),
      size: Number(ethers.BigNumber.from(state.size)),
      price: Number(ethers.utils.formatUnits(state.price, "gwei")),
      participants: state.participants,
      maxWinners: Number(ethers.BigNumber.from(state.maxWinners)),
      deadline: new Date(Number(ethers.BigNumber.from(state.deadline)) * 1000),
      startTime: new Date(
        Number(ethers.BigNumber.from(state.startTime)) * 1000
      ),
    };
  }
}

export default RaffleHelper;
