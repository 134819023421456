// import { BigNumber, ethers } from "ethers";
// import React, { useState } from "react";
import { Container } from "./styledTags";
import { useEffect, useState } from "react";
import { MintParams } from "Entites/NFT";

export type CollectionArgs = {
  Tier1: number;
  Tier2: number;
  Tier3: number;
  Tier4: number;
  AirDrop: number;
  Raffle: number;
  Game: number;
  Private: number;
  Collection: number;
  Amount: number;
};

export function validateCollectionArgs(args: CollectionArgs): boolean {
  if (
    args.Tier1 == 0 ||
    args.Tier2 == 0 ||
    args.Tier3 == 0 ||
    args.Tier4 == 0 
    // args.AirDrop == 0 ||
    // args.Raffle == 0 ||
    // args.Game == 0 ||
    // args.Private == 0
  ) {
    console.log("failed the mint check");
    return false;
  }

  // You could add more checks here if you need to validate the values as well
  // For example, to check that the tier values are positive integers, you could do:
  // if (!Number.isInteger(args.Tier1) || args.Tier1 < 0) {
  //   return false;
  // }

  return true;
}

function InfoPane({
  buyRandomNFT = (tier: number) => {},
  getMyTokens = () => {},
  getTokenInfo = () => {},
  getAllTokens = () => {},
  onTierChange = (e: React.ChangeEvent<HTMLInputElement>) => {},
  onCollectionTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {},
  mintCollection = (args: MintParams) => {},
  tokenAddress,
}) {
  const [collectionArgs, setCollectionArgs] = useState<CollectionArgs>({
    Tier1: 0,
    Tier2: 0,
    Tier3: 0,
    Tier4: 0,
    AirDrop: 0,
    Raffle: 0,
    Game: 0,
    Private: 0,
    Collection: 0,
    Amount: 0,
  });

  const setMintArgs = (): MintParams => {
    console.log("im here");
    //vrify data
    if (validateCollectionArgs(collectionArgs)) {
      const {
        Tier1,
        Tier2,
        Tier3,
        Tier4,
        AirDrop,
        Raffle,
        Game,
        Private,
        Collection,
        Amount,
      } = collectionArgs;

      const tierQuantities = [
        Tier1,
        Tier2,
        Tier3,
        Tier4,
        AirDrop,
        Raffle,
        Game,
        Private,
      ];

      const total = (
        collectionArgs.Tier1 +
        collectionArgs.Tier2 +
        collectionArgs.Tier3 +
        collectionArgs.Tier4 +
        collectionArgs.AirDrop +
        collectionArgs.Raffle +
        collectionArgs.Game +
        collectionArgs.Private
      );

      return { tierQuantities, amount: total };
    } else {
      console.log("malformed Data");
      alert("Data is malformed please check again");
    }
  };

  useEffect(() => {
    //refresh when args are managed
  }, [collectionArgs]);

  return (
    <Container>
      <div className="min-h-screen bg-gray-100 py-6 flex flex-col justify-center sm:py-12">
        <div className="relative py-3 sm:max-w-xl sm:mx-auto">
          <div className="lg:flex md:flex text-xl justify-center items-center mx-auto border-orange-500 max-w-2xl py-4 px-4">
            <div className="font-semibold p-2">
              <span className="text-gray-800">Greeter Contract</span>
              <span className="text-orange-500 mx-1 text-3xl">/</span>
              <a
                href={`https://google.co.uk`}
                target="_blank"
                className="px-4 py-1 rounded-full focus:outline-none bg-orange-500 text-white shadow ml-2"
                rel="noreferrer"
              >
                Check
              </a>
            </div>
          </div>
          <div className="relative px-4 py-10 bg-white shadow-lg sm:rounded-3xl sm:p-20 flex flex-col">
            <div className="flex flex-row flex-wrap">
              <button
                className="btn btn-green mt-1"
                type="button"
                onClick={() => buyRandomNFT(null)}
              >
                Get Random NFT
              </button>
              <div className="flex flex-row flex-wrap mt-1">
                <button
                  className="btn btn-green ml-1"
                  type="button"
                  onClick={getMyTokens}
                >
                  Get My Tokens
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-10 relative py-3 sm:max-w-xl sm:mx-auto">
          <div className="lg:flex md:flex text-xl justify-center items-center mx-auto border-orange-500 max-w-2xl py-4 px-4">
            <div className="font-semibold p-2">
              <span className="text-gray-800">Token Contract</span>
              <span className="text-orange-500 mx-1 text-3xl">/</span>
              <a
                href={`https://ropsten.etherscan.io/address/${tokenAddress}`}
                target="_blank"
                className="px-4 py-1 rounded-full focus:outline-none bg-orange-500 text-white shadow ml-2"
                rel="noreferrer"
              >
                {tokenAddress}
              </a>
            </div>
          </div>
          <div className="relative px-4 py-10 bg-white shadow-lg sm:rounded-3xl sm:p-20 flex flex-col">
            <button
              className="btn btn-green mt-1"
              type="button"
              // onClick={getBalance}
              onClick={getAllTokens}
            >
              Get All Tokens
            </button>

            <hr className="mt-4" />

            <input
              type="number"
              onChange={(e) => onTierChange(e)}
              placeholder="Tier"
            />
            <input
              type="string"
              onChange={(e) => onCollectionTypeChange(e)}
              placeholder="Collection Type"
            />
            <button
              className="btn btn-green mt-1"
              type="button"
              onClick={getTokenInfo}
            >
              Token info
            </button>

            <hr className="mt-4" />

            <div>
              <input
                type="number"
                onChange={(e) =>
                  setCollectionArgs({
                    ...collectionArgs,
                    Tier1: Number(e.target.value),
                  })
                }
                placeholder="Tier1"
              />
              <input
                type="number"
                onChange={(e) =>
                  setCollectionArgs({
                    ...collectionArgs,
                    Tier2: Number(e.target.value),
                  })
                }
                placeholder="Tier2"
              />
              <input
                type="number"
                onChange={(e) =>
                  setCollectionArgs({
                    ...collectionArgs,
                    Tier3: Number(e.target.value),
                  })
                }
                placeholder="Tier3"
              />
              <input
                type="number"
                onChange={(e) =>
                  setCollectionArgs({
                    ...collectionArgs,
                    Tier4: Number(e.target.value),
                  })
                }
                placeholder="Tier4"
              />
            </div>

            <div>
              <input
                type="number"
                onChange={(e) =>
                  setCollectionArgs({
                    ...collectionArgs,
                    AirDrop: Number(e.target.value),
                  })
                }
                placeholder="AirDrop"
              />
              <input
                type="number"
                onChange={(e) =>
                  setCollectionArgs({
                    ...collectionArgs,
                    Raffle: Number(e.target.value),
                  })
                }
                placeholder="Raffle"
              />
              <input
                type="number"
                onChange={(e) =>
                  setCollectionArgs({
                    ...collectionArgs,
                    Game: Number(e.target.value),
                  })
                }
                placeholder="Game"
              />
              <input
                type="number"
                onChange={(e) =>
                  setCollectionArgs({
                    ...collectionArgs,
                    Private: Number(e.target.value),
                  })
                }
                placeholder="Private"
              />
            </div>
            <input
              type="number"
              onChange={(e) =>
                setCollectionArgs({
                  ...collectionArgs,
                  Collection: Number(e.target.value),
                })
              }
              placeholder="Collection Type"
            />
            <input
              type="number"
              // onChange={(e) =>
              //   setCollectionArgs({
              //     ...collectionArgs,
              //     Amount: Number(e.target.value),
              //   })
              // }
              value={
                collectionArgs.Tier1 +
                collectionArgs.Tier2 +
                collectionArgs.Tier3 +
                collectionArgs.Tier4 +
                collectionArgs.AirDrop +
                collectionArgs.Raffle +
                collectionArgs.Game +
                collectionArgs.Private
              }
              placeholder="Total Tokens Available"
            />
            <button
              className="btn btn-green mt-1"
              type="button"
              onClick={() => mintCollection(setMintArgs())}
            >
              Mint Collection
            </button>
          </div>
        </div>
      </div>
    </Container>
  );
}

export default InfoPane;
