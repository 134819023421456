import { animated, useSpring } from "@react-spring/web";
import GameCard, { RecordTypes } from "./GameCard";
import Eubank from "assets/Nfts/Tier2/Eubanks.gif";
import { useWindowDimensions } from "Helpers/useWindowDimensions";
import { NFT } from "types/NFT/BaseContract";
import { useEffect, useState } from "react";

type PunchProps = {
  onPunch: (boolean) => void;
  disablePunch: boolean;
  punched?: boolean;
  nft: NFT;
  player: number;
  stat: RecordTypes;
};
const Punch = ({
  onPunch,
  disablePunch,
  punched,
  nft,
  player,
  stat,
}: PunchProps) => {
  const { width } = useWindowDimensions(); // use our custom hook
  const [disabled, setDisabled] = useState(false);
  const [styles, api] = useSpring(
    () => ({
      x: 0,
      rotateZ: 0,
    }),
    []
  );

  const handleClick = () => {
    if(disablePunch) return;
    if (disabled) return;
    setDisabled(true);
    onPunch(true);
    api.start({
      to: [
        {
          // x: player === 1 ? width : -width,
          x: player === 1 ? width : -width,
          rotateZ: 0,
        }, // use the width
        {
          x: 0,
          rotateZ: 0,
        },
      ],
      onRest: () => setDisabled(false),
    });
  };

  useEffect(() => {
    let timeoutId;
    if (punched) {
      timeoutId = setTimeout(() => {
        console.log("Gif has finished playing");
        // Add your action here.

        //explode the card
        setDisabled(false);
      }, 5000);
    }
    return () => clearTimeout(timeoutId);
  }, [punched, disabled /*gifDuration*/]);

  return (
    <animated.div
      className="spring-box"
      onClick={handleClick}
      style={{
        ...styles,
        cursor: "pointer",
      }}
    >
      <GameCard
        nft={nft}
        stat={stat}
        punchStatus={punched}
      />
    </animated.div>
  );
};

export default Punch;
