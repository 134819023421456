import React from 'react';
import CheckboxButton from '../../../UI/CheckboxButton/CheckboxButton';
import FrontBlock from '../../../UI/FrontBlock/FrontBlock';

import './TierFilterWrap.scss';

interface TierFilterWrapProps {
    checkedUsual: boolean;
    onChangeUsual: (event: React.ChangeEvent<HTMLInputElement>) => void;
    checkedRare: boolean;
    onChangeRare: (event: React.ChangeEvent<HTMLInputElement>) => void;
    checkedEpic: boolean;
    onChangeEpic: (event: React.ChangeEvent<HTMLInputElement>) => void;
    checkedLegendary: boolean;
    onChangeLegendary: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const TierFilterWrap = ({
  checkedUsual,
  onChangeUsual,
  checkedRare,
  onChangeRare,
  checkedEpic,
  onChangeEpic,
  checkedLegendary,
  onChangeLegendary
}: TierFilterWrapProps) => {
    return (
        <label className="sideFilterWrap">
            <input type="checkbox" className="openFilter" />
            <FrontBlock frontFilterIcon={'cost'} frontFilterName={'Tier'} />
            <div className="popupFilter">
                <CheckboxButton
                    value={"usual"}
                    checked={checkedUsual}
                    onChange={onChangeUsual}
                />
                <CheckboxButton
                    value={"rare"}
                    checked={checkedRare}
                    onChange={onChangeRare}
                />
                <CheckboxButton
                    value={"epic"}
                    checked={checkedEpic}
                    onChange={onChangeEpic}
                />
                <CheckboxButton
                    value={"legendary"}
                    checked={checkedLegendary}
                    onChange={onChangeLegendary}
                />
            </div>
        </label>
    );
}

export default TierFilterWrap;
