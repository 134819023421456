import React, { ChangeEvent, useCallback } from "react";
import DateFilterWrap from "./DateFilterWrap/DateFilterWrap";
import TypeFilterWrap from "./TypeFilterWrap/TypeFilterWrap";
import BorderFilterWrap from "./BorderFilterWrap/BorderFilterWrap";
import TierFilterWrap from "./TierFilterWrap/TierFilterWrap";

import "./SearchBar.scss";
import OwnedTokensFilter from "./OwnedTokensFilter/OwnedTokensFilter";
import { MetaDataInfo, NFT } from "types/NFT/BaseContract";

interface SearchBarProps {
  moc: string;
  onTokensChange: (value: NFT[]) => void;

  onInUsualChange: (value: boolean) => void;
  onInRareChange: (value: boolean) => void;
  onInEpicChange: (value: boolean) => void;
  onInLegendaryChange: (value: boolean) => void;
  onInFractionChange: (value: string) => void;
  onInBorderChange: (value: string) => void;
  onInTypeChange: (value: string) => void;
  inUsualOnly: boolean;
  inRareOnly: boolean;
  inEpicOnly: boolean;
  inLegendaryOnly: boolean;
  inFactionOnly: string;
  inTypeOnly: string;
  inBorderOnly: string;
}
/* 
{    
    moc,
    onInUsualChange,
    onInRareChange,
    onInEpicChange,
    onInLegendaryChange,
    onInFractionChange,
    onInBorderChange,
    onInTypeChange,
    inUsualOnly,
    inRareOnly,
    inEpicOnly,
    inLegendaryOnly,
    inFactionOnly,
    inTypeOnly,
    inBorderOnly,
}
*/
const SearchBar = (props: SearchBarProps) => {
  const handleInUsualChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      props.onInUsualChange(e.target.checked);
    },
    [props]
  );

  const handleInRareChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      props.onInRareChange(e.target.checked);
    },
    [props]
  );

  const handleInEpicChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      props.onInEpicChange(e.target.checked);
    },
    [props]
  );

  const handleInLegendaryChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      props.onInLegendaryChange(e.target.checked);
    },
    [props]
  );

  const handleInFractionChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      props.onInFractionChange(e.target.value);
    },
    [props]
  );

  const handleInBorderChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      props.onInBorderChange(e.target.value);
    },
    [props]
  );

  const handleInTypeChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      props.onInTypeChange(e.target.value);
    },
    [props]
  );

  const handleTokensChange = useCallback(
    (tokens: NFT[]) => {
      //Validation here??

      props.onTokensChange(tokens);
    },
    [props]
  );

  return (
    <div className={`filterListWrap ${props.moc}`}>
      <div className="contentWrap">
        <OwnedTokensFilter onChange={handleTokensChange} />
        <TierFilterWrap
          checkedUsual={props.inUsualOnly}
          onChangeUsual={handleInUsualChange}
          checkedRare={props.inRareOnly}
          onChangeRare={handleInRareChange}
          checkedEpic={props.inEpicOnly}
          onChangeEpic={handleInEpicChange}
          checkedLegendary={props.inLegendaryOnly}
          onChangeLegendary={handleInLegendaryChange}
        />

        <DateFilterWrap
          checked={props.inFactionOnly}
          onChange={handleInFractionChange}
        />
        {/* <TypeFilterWrap
                    checked={props.inTypeOnly}
                    onChange={handleInTypeChange}
                />
                <BorderFilterWrap
                    checked={props.inBorderOnly}
                    onChange={handleInBorderChange}
                /> */}
      </div>
    </div>
  );
};

export default SearchBar;
