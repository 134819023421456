import React, { useState } from "react";

import "./ownedTokensFilter.scss";
import { requestAccount } from "Helpers/MetaMaskHelper";
import { MetaDataInfo, NFT } from "types/NFT/BaseContract";
import { SmartContractHelper } from "Helpers/BaseContractHelper";


enum View {
  All = "All",
  Mine = "Mine",
}

interface OwnedTokensFilterProps {
  onChange: (tokens: NFT[]) => void;
}
const OwnedTokensFilter = ({ onChange }: OwnedTokensFilterProps) => {
  const [selected, setSelected] = useState<View>(View.All);
  const [nftListData, setNFTListData] = useState<NFT[]>([]);

  const handleSwitch = async (value: View) => {
    //check metaMask connection
    // request account from metamask
    const [account] = await requestAccount();
    console.log("accounts:", account)
    //should only filter when we can make calls to BlockChain
    if (account) {
      //apply filter
      if (value === View.Mine) {
        //do something
        await HandleUserCards();
      }else{
        //reset all data
        setNFTListData([]);
        onChange([]);
      }
      setSelected(value);
    }
  };

  const HandleUserCards = async () => {
    const smartContract = new SmartContractHelper();

    const tokens: NFT[] = await smartContract.getMyTokens();
    console.log("Tokens:", tokens);

    setNFTListData(
      // tokens.map((token, index) => ({
      //   // id: Number(token.index),
      //   id: index,
      //   NFT: token.ipfs.image,
      //   description: token.ipfs.description,
      //   name: token.ipfs.name,
      //   author: token.ipfs.author,
      //   collection: token.ipfs.collectionType,
      //   collectionType: token.ipfs.collectionType,
      //   tier: Number(token.tier),
      //   knight: Number(token.ipfs.championships) ?? null,
      //   rook: Number(token.ipfs.consecutiveWins) ?? null,
      //   bishop: Number(token.ipfs.wins) ?? null,
      // }))
      tokens
    );

    // onChange(tokens.map(x => x.ipfs));
    onChange(tokens);
  };

  return (
    <div id="owned-tokens">
      <div className="container">
        <div className="switches-container">
          <input
            type="radio"
            id="switchMonthly"
            name="switchPlan"
            value="All"
            checked={selected === "All"}
            onChange={async () => await handleSwitch(View.All)}
          />
          <input
            type="radio"
            id="switchYearly"
            name="switchPlan"
            value="Mine"
            checked={selected === "Mine"}
            onChange={async () => await handleSwitch(View.Mine)}
          />
          <label htmlFor="switchMonthly">All</label>
          <label htmlFor="switchYearly">Mine</label>
          <div className="switch-wrapper">
            <div className="switch">
              <div>All</div>
              <div>Mine</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OwnedTokensFilter;
