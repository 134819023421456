import React from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";

import logo from "assets/P2PLogo.png";

const LogoText = styled.h1`
  font-family: "Akaya Telivigala", cursive;
  font-size: ${(props) => props.theme.fontxxxl};
  color: ${(props) => props.theme.text};
  transition: all 0.2s ease;

  &:hover {
    transform: scale(1.1);
  }

  @media (max-width: 64em) {
    font-size: ${(props) => props.theme.fontxxl};
  }
`;

const IMG = styled.img`
  width: 40px;
  position: relative;
  left: 10px;
  top: 7px;
  border-radius: 15px;
`;

const Logo = () => {
  const navigate = useNavigate();
  return (
    <LogoText>
      <Link to="/" onClick={() => navigate("/")}>
        P2P.art
        <IMG src={logo} alt="P2P.ART" />
      </Link>
    </LogoText>
  );
};

export default Logo;
