import React, { useEffect, useState } from "react";
import TimelineItem, { TimelineItemProps } from "./TimeLineItem";
import "./timeLine.scss";
import CountDown from "components/CountDown/CountDown";
import Opensea from "../../Icons/OpenSea";
import OpenseaSVG from "../../Icons/Logomark-Blue.svg";

const Timeline: React.FC<{ timelineItems: TimelineItemProps[] }> = ({
  timelineItems,
}) => {
  const [scrollY, setScrollY] = useState(window.scrollY);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [progressBarPosition, setProgressBarPosition] = useState(0);
  // State to track active items
  const [activeItems, setActiveItems] = useState<boolean[]>(
    new Array(timelineItems.length).fill(false)
  );

  const updateActiveItems = (progress: number) => {
    setActiveItems(
      activeItems.map((active, index) => {
        const itemPercentageStart = (100 / timelineItems.length) * index;
        return active || progress >= itemPercentageStart;
      })
    );
  };
  const handleScroll = () => {
    setScrollY(window.scrollY);
    updateProgressBar();
  };

  const handleResize = () => {
    setScrollY(window.scrollY);
    setWindowHeight(document.getElementById("timeline").clientHeight);
    updateProgressBar();
  };

  const updateProgressBar = () => {
    const timeline = document.querySelector("#timeline") as HTMLElement;
    const timelineLineProgress = document.querySelector(
      ".js-timeline_line-progress"
    ) as HTMLElement;

    if (timeline && timelineLineProgress) {
      const timelineHeight = timeline.clientHeight;
      const timelineTop = timeline.getBoundingClientRect().top + window.scrollY;
      const scrollCenter = scrollY + windowHeight / 2;
      const progress = ((scrollCenter - timelineTop) / timelineHeight) * 100;

      // Calculate the color based on scroll position
      const color = `hsl(${progress * 1.2}, 100%, 50%)`; // Hue changes from 0 to 120 (red to yellow)

      setProgressBarPosition(progress);
      updateActiveItems(progress);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    updateProgressBar();
  }, [scrollY, windowHeight]);

  useEffect(() => {
    const handleLoad = () => {
      // Set initial scrollY and windowHeight
      setScrollY(window.scrollY);
      setWindowHeight(document.getElementById("timeline").clientHeight);
      // Run updateProgressBar once on load
      updateProgressBar();
    };

    // Listen for the load event
    window.addEventListener("load", handleLoad);

    return () => {
      // Cleanup load event listener
      window.removeEventListener("load", handleLoad);
    };
  }, []);

  useEffect(() => {
    const timelineElements = document.querySelectorAll(
      ".ag-timeline-card_item"
    ) as NodeListOf<HTMLElement>;

    if (timelineElements.length > 0) {
      const percentagePerItem = 100 / timelineItems.length;

      timelineElements.forEach((timelineItem, index) => {
        const itemPercentageStart = index * percentagePerItem;
        const itemPercentageEnd = (index + 1) * percentagePerItem;

        if (progressBarPosition >= itemPercentageStart) {
          // Once an item becomes active, it remains visible
          timelineItem.classList.add("js-ag-active");
          timelineItem.style.opacity = `1`;
          timelineItem.style.transform = `translateX(0%)`;
        } else {
          if (!activeItems[index]) {
            // Only hide items that have not yet been reached
            // timelineItem.classList.remove("js-ag-active");
            // timelineItem.style.opacity = `0`;
            // timelineItem.style.transform = `translateX(200%)`;
          }
        }
      });
    }
  }, [scrollY, windowHeight, timelineItems, progressBarPosition]);

  useEffect(() => {
    const timelineElements = document.querySelectorAll(
      ".ag-timeline-card_item"
    ) as NodeListOf<HTMLElement>;

    timelineElements.forEach((timelineItem, index) => {
      if (activeItems[index]) {
        // Item is active, so it should be visible
        timelineItem.classList.add("js-ag-active");
        timelineItem.style.opacity = `1`;
        timelineItem.style.transform = `translateX(0%)`;
      }
    });
  }, [activeItems]);

  return (
    <div id="timeline">
      <div className="ag-timeline-block">
        <div className="ag-timeline_title-box">
          <a
            className="opensea-button"
            href="https://opensea.io/collection/lawrence-lustig-collection-by-p2p-art/overview"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="spotify"
          >
            <img
              src={OpenseaSVG}
              height={"100px"}
              width={"100px"}
              alt="Available on Opensea"
            />
            <div className="ag-timeline_tagline">Available on Opensea</div>
          </a>
          <br />
          {/* // Specific date format: 'Year, Month (0-indexed), Day, Hour, Minute, Second' */}
          {/* <CountDown endDate={new Date(Date.UTC(2023, 11, 23, 12, 0, 0))} /> */}
          {/* <div className="ag-timeline_title">Road Map</div> */}
        </div>
        <section className="ag-section">
          <div className="ag-format-container">
            <div className="js-timeline ag-timeline">
              <div className="js-timeline_line ag-timeline_line">
                <div
                  className="js-timeline_line-progress ag-timeline_line-progress"
                  style={{
                    height: `${progressBarPosition}%`,
                    // backgroundColor: `hsl(${
                    //   progressBarPosition * 1.2
                    // }, 100%, 50%)`,
                  }}
                ></div>
              </div>
              <div className="ag-timeline_list">
                {timelineItems.map((item, index) => (
                  <TimelineItem key={index} {...item} />
                ))}
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Timeline;
