import React, { lazy, Suspense } from "react";
import {
  Bottom,
  Container,
  IconList,
  Item,
  Left,
  MenuItems,
  Section,
} from "./styledTags";
// import Banner from './Banner'
import Logo from "../Logo";

import Spotify from "../../Icons/Spotify";
import Instagram from "../../Icons/Instagram";
import Twitter from "../../Icons/Twitter";
import raritySniper from "assets/RaritySniper.png";
import Discord from "../../Icons/Discord";
import Loading from "../Loading";
import { useNavigate } from "react-router";

const Banner = lazy(() => import("../Banner/Banner"));

const Footer = () => {
  const scrollTo = (id) => {
    let element = document.getElementById(id);

    element.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
  };

  const navigate = useNavigate();

  return (
    <Section>
      <Suspense fallback={<Loading />}>
        <Banner />{" "}
      </Suspense>

      <Container>
        <Left>
          <Logo />
          <IconList>
            <a
              href="https://open.spotify.com/episode/7h9QBi3Nhtc6I0lbiMceHi?si=ICzosDnsSmiT6RPyU4hE8w&nd=1&dlsi=f9f6c662e907441f"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="spotify"
            >
              <Spotify />
            </a>
            <a
              href="https://www.instagram.com/p2pdigitalart/"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="instagram"
            >
              <Instagram />
            </a>
            <a
              href="https://twitter.com/P2P_Art"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="twitter"
            >
              <Twitter />
            </a>
            <a
              href="https://raritysniper.com/nft-drops-calendar"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="twitter"
              style={{
                width: "30px",
              }}
            >
              <img
                src={raritySniper}
                alt="Rarity Sniper"
                width={"-webkit-fill-available"}
                style={{
                  width: "inherit",
                  top: "-2px",
                  position: "relative",
                  filter: "grayscale(100%)",
                }}
              />
            </a>
            {/* <a
              href="https://discord.gg/WefZSXJr"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="discord"
            >
              <Discord />
            </a> */}
          </IconList>
        </Left>
        <MenuItems>
          <Item onClick={() => scrollTo("home")}>Home</Item>
          <Item onClick={() => scrollTo("about")}>About</Item>
          <Item onClick={() => scrollTo("roadmap")}>Roadmap</Item>
          <Item onClick={() => navigate("explore")}>Explore</Item>
          <Item onClick={() => scrollTo("team")}>Team</Item>
          <Item onClick={() => scrollTo("faq")}>Faq</Item>
        </MenuItems>
      </Container>
      <Bottom>
        <span>&copy; {new Date().getFullYear()} P2P. All rights reserved.</span>
        {/* <span>
          Made with &#10084; by <a href="http://youtube.com/codebucks" target="_blank"  rel="noopener noreferrer">
            CodeBucks
          </a>
        </span> */}
      </Bottom>
    </Section>
  );
};

export default Footer;
