import toast, { Toaster } from "react-hot-toast";

// request access to the user's MetaMask account
export const requestAccount = async () => {
  if (window.ethereum?.request) {
    // toast.success(
    //   `Connected: ${await window.ethereum.request({
    //     method: "eth_requestAccounts",
    //   })}`
    // );

    return await window.ethereum.request({ method: "eth_requestAccounts" });
  }

  //   throw new Error(
  //     "Missing install Metamask. Please access https://metamask.io/ to install extension on your browser"
  //   );

  toast.error(
    "Missing install Metamask. Please install MeataMask extension"
  );
};
