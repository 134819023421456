const GetUnique = (array: []) => {
    return array.reduce(function (a, b) {
        if (a.indexOf(b) < 0) a.push(b);
        return a;
    }, []);
};

export const ImportAll = (r) => {
    return GetUnique(r.keys().map(r));
    // let images = {};
    // r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
    // return i;mages;
}

//  default ImportAll;