import { ImportAll } from "Helpers/ImportData";
import React from "react";
import CardMetaData from "./CardMetaData";
import { flattenAttributes, getAttrValue } from "Helpers/GetNFTS";
import { metaDataFilterProps } from "Views/ExploreGrid/components/Constructor/Constructor";
import { MetaDataInfo, NFT } from "types/NFT/BaseContract";

const CARD_INFO_ARRAY: NFT[] = [];

export const CardData = (metaData: MetaDataInfo |object) => {
  // const keys: (keyof typeof metaData)[] = Object.keys(
  //   metaData
  // ) as (keyof typeof metaData)[];
  const flattenedOBJ = flattenAttributes(metaData as MetaDataInfo);

  const filteredMetaData = Object.entries(flattenedOBJ)
    .filter(([key]) => key !== "image")
    .reduce((obj, [key, value]) => {
      obj[key] = value;
      return obj;
    }, {} as {});

  const keys: (keyof typeof filteredMetaData)[] = Object.keys(
    filteredMetaData
  ) as (keyof typeof metaData)[];

  return (
    <p>
      {keys.map((key) => (
        <React.Fragment key={key}>
          <span>{key}</span>:{flattenedOBJ[key]}
          <br />
        </React.Fragment>
      ))}
    </p>
  );
};

// Helper function to add images and metadata to CARD_INFO_ARRAY
const addCardsToInfoArray = (images, tierNumber) => {
  images.forEach((image, index) => {
    // const metadata = CardMetaData.find((data) => data.tier === tierNumber);
    const metadata = CardMetaData.find(
      (data) => getAttrValue(data, "tier") === tierNumber
    );

    // console.log("Card_Info_Array.metadata:", metadata);

    if (metadata) {
      CARD_INFO_ARRAY.push(
        {
          tokenId: CARD_INFO_ARRAY.length,
          ipfs:{
            ...metadata, // This will spread all properties from metadata into the new object
            image: image,
          },
          tier: Number(getAttrValue(metadata, "tier")),
          collection: 0,
          consecutiveWins: Number(getAttrValue(metadata, "consecutiveWins")),
          championships: Number(getAttrValue(metadata, "championships")),
          winLossRatio: Number(getAttrValue(metadata, "winLossRatio")),
        }
);
      // Remove the metadata from cardmetadata to ensure uniqueness
      const metaDataIndex = CardMetaData.indexOf(metadata);
      CardMetaData.splice(metaDataIndex, 1);
    }
  });
}
const tier1 = ImportAll(
  require.context("assets/Nfts/Tier1", false, /\.(png|jpe?g|svg|jpg|gif)$/)
);

const tier2 = ImportAll(
  require.context("assets/Nfts/Tier2", false, /\.(gif)$/)
);

const tier3 = ImportAll(
  require.context("assets/Nfts/Tier3", false, /\.(png|jpe?g|svg|jpg|gif)$/)
);

const tier4 = ImportAll(
  require.context(
    "assets/Nfts/Collactables",
    false,
    /\.(png|jpe?g|svg|jpg|gif)$/
  )
);


addCardsToInfoArray(tier1, 1);
addCardsToInfoArray(tier2, 2);
addCardsToInfoArray(tier3, 3);
addCardsToInfoArray(tier4, 4);

export default CARD_INFO_ARRAY;
